export const suggestionTableFields = [
    { title: 'Title', key: 'title', width: '10%', type: 'text' },
    { title: 'Status', key: 'status', width: '5%', type: 'text' },
    { title: 'Scheme Type', key: 'scheme_type_name', width: '10%', type: 'text' },
    
    { title: 'Present Status', key: 'present_status', width: '10%', type: 'text' },
    { title: 'Earlier Status', key: 'earlier_status', width: '5%', type: 'text' },
    { title: 'Proposed Changes', key: 'proposed_changes', width: '10%', type: 'text' },
    { title: 'Implemented Changes', key: 'changes_implemented', width: '10%', type: 'text' },

    // { title: 'Present Status/Earlier Status', key: '', type: 'special', 
    //     specialRowFunction: (rowdata) => { 
    //         const key = rowdata?.scheme_type_name;
    //         return key === "NORMAL" || key === "SPECIAL" ? 'present_status' : "earlier_status"  
    //     }
    // },

    // { title: 'Proposed Changes/Changes Implemented', key: '', width: '10%',  type: 'special', 
    //     specialRowFunction: (rowdata) => {
    //         const key = rowdata?.scheme_type_name;
    //         return key === "NORMAL" || key === "SPECIAL" ? 'proposed_changes' : 'changes_implemented'
    //     } 
    // },

    { title: 'Benefits', key: 'benefits', width: '10%', type: 'text' },
    { title: 'Place Where Suggestion Applicable', key: 'applicable_areas', width: '10%', type: 'text' },
    { title: 'Prev Comment.', key: 'comment', width: '10%', type: 'text' },
    { title: 'Clarification To Divisional Coordinator', key: 'clarification_to_divc', width: '10%', type: 'text' },
]
export const divisionalCoordinatorTableFields = [
    // { title: 'Title', key: 'title', width: '30%', type: 'text' },
    // { title: 'Award Type', key: 'award_type', width: '15%', type: 'text' },
    // { title: 'Award Amount', key: 'amount', width: '15%', type: 'text' },
    // { title: 'Award Category', key: 'award_category', width: '15%', type: 'text' },
    // { title: 'Previous Comment', key: 'comment', width: '20%', type: 'text' },

    { title: 'Title', key: 'title', width: '5%', type: 'text' },
    { title: 'Suggestion Type', key: 'scheme_type_name', width: '5%', type: 'text' },
    { title: 'Status', key: 'status', width: '5%', type: 'text' },
    { title: 'Award Type', key: 'award_type', width: '5%', type: 'text' },
    { title: 'Award Category', key: 'award_category', width: '5%', type: 'text' },
    { title: 'Award Amount ', key: 'amount', width: '5%', type: 'text' },
    // { title: 'Type of Saving', key: 'saving_type', width: '10%', type: 'text' },
    // { title: 'Nature of Saving', key: 'saving_nature', width: '10%', type: 'text' },
    // { title: 'Eval Amount', key: 'eval_amount', width: '10%', type: 'text' },
    // { title: 'Comment', key: 'comments', width: '10%', type: 'text' },

    // { title: 'Present Status/Earlier Status', key: '', type: 'special', 
    //     specialRowFunction: (rowdata) => { 
    //         const key = rowdata?.scheme_type_name;
    //         return key === "NORMAL" || key === "SPECIAL" ? 'present_status' : "earlier_status"  
    //     }
    // },

    // // { title: '', key: 'earlier_status', type: 'text' },
    // { title: 'Proposed Changes/Changes Implemented', key: '', width: '10%',  type: 'special', 
    //     specialRowFunction: (rowdata) => {
    //         const key = rowdata?.scheme_type_name;
    //         return key === "NORMAL" || key === "SPECIAL" ? 'proposed_changes' : 'changes_implemented'
    //     } 
    // },

    { title: 'Present Status', key: 'present_status',width: '5%', type: 'text' },
    { title: 'Earlier Status', key: 'earlier_status',width: '5%', type: 'text' },
    { title: 'Proposed Changes', key: 'proposed_changes', width: '5%', type: 'text' },
    { title: 'Changes Implemented', key: 'changes_implemented', width: '5%', type: 'text' },
    { title: 'Benefits', key: 'benefits', width: '5%', type: 'text' },
    { title: 'Applicable areas', key: 'applicable_areas', width: '5%', type: 'text' },
    { title: 'Prev Comment', key: 'comment', width: '10%', type: 'text' },
]
export const suggestionDataFields = [
    { name: 'Suggestion ID', key: 'id', type: 'text' },
    { name: 'Suggestion submission date', key: 'entered_on', type: 'date' },
    { name: 'Suggestion Type', key: 'scheme_type_name', type: 'text' },
    { name: 'Suggestion Title', key: 'title', type: 'text' },
    // { name: 'Earlier Status', key: 'earlier_status', type: 'text' },
    // { name: 'Implemented Changes', key: 'changes_implemented', type: 'text' },
    { name: 'Schema Name', key: 'scheme_name', type: 'text' },
    { name: 'Annual Saving in ₹', key: 'annual_saving', type: 'text' },
    { name: 'Present Status', key: 'present_status', type: 'text' },
    { name: 'Proposed Changes', key: 'proposed_changes', type: 'text' },
    { name: 'Benefits', key: 'benefits', type: 'text' },
    { name: 'Place where suggestion applicable', key: 'applicable_areas', type: 'text' },
    // { name: 'Implementation Date', key: 'implemented_date', type: 'date' },
]
export const suggestionOfficeTableFields = [
   [
        { title: 'Suggestion ID', key: 'id', width: '10%', type: 'text' },
        { title: 'Title', key: 'title', width: '10%', type: 'text' },
        { title: 'Suggestion Type', key: 'scheme_type_name', width: '10%', type: 'text' },
        { title: 'Status', key: 'status', width: '10%', type: 'text' },
        { title: 'Award Type', key: 'award_type', width: '10%', type: 'text' },
        { title: 'Award Category', key: 'award_category', width: '10%', type: 'text' },
        { title: 'Award Amount ', key: 'amount', width: '10%', type: 'text' },
        // { title: 'Type of Saving', key: 'saving_type', width: '10%', type: 'text' },
        // { title: 'Nature of Saving', key: 'saving_nature', width: '10%', type: 'text' },
        // { title: 'Eval Amount', key: 'eval_amount', width: '10%', type: 'text' },
        // { title: 'Comment', key: 'comments', width: '10%', type: 'text' },
        
        // { title: 'Present Status/Earlier Status', key: '', type: 'special', 
        //     specialRowFunction: (rowdata) => { 
        //         const key = rowdata?.scheme_type_name;
        //         return key === "NORMAL" || key === "SPECIAL" ? 'present_status' : "earlier_status"  
        //     }
        // },

        // { title: 'Proposed Changes/Changes Implemented', key: '', width: '10%',  type: 'special', 
        //     specialRowFunction: (rowdata) => {
        //         const key = rowdata?.scheme_type_name;
        //         return key === "NORMAL" || key === "SPECIAL" ? 'proposed_changes' : 'changes_implemented'
        //     } 
        // },
        { title: 'Present Status', key: 'present_status', type: 'text' },
        { title: 'Earlier Status', key: 'earlier_status', type: 'text' },
        { title: 'Proposed Changes', key: 'proposed_changes', width: '10%', type: 'text' },
        { title: 'Changes Implemented', key: 'changes_implemented', width: '10%', type: 'text' },

        { title: 'Benefits', key: 'benefits', width: '10%', type: 'text' },
        { title: 'Applicable areas', key: 'applicable_areas', type: 'text' },
        { title: 'Prev Comment', key: 'comment', width: '10%', type: 'text' },
    ],
    [
        { title: 'Sugg No.', key: 'suggestion_id', width: '10%', type: 'text' },
        { title: 'Title', key: 'suggestion_name', width: '10%', type: 'text' },
        { title: 'Sugg Type', key: 'suggestion_type', width: '10%', type: 'text' },
        { title: 'Award Type', key: 'award_type', width: '10%', type: 'text' },
        { title: 'LOT', key: 'lot', width: '10%', type: 'text' },
        { title: 'Award Amount', key: 'award_amount', width: '10%', type: 'text' },
        { title: 'Proposed Changes', key: 'proposed_changes', width: '10%', type: 'text' },
        { title: 'Status', key: 'status', width: '10%', type: 'text' },
        { title: 'Nature of Saving', key: 'saving_nature', width: '10%', type: 'text' },
        { title: 'Comment', key: 'comment', width: '10%', type: 'text' },
        { title: 'Present Status', key: 'present_status', type: 'text' },
        { title: 'Appl-area', key: 'area', type: 'text' },
        { title: 'Prev Comment', key: 'previous_comments', width: '10%', type: 'text' },
    ],
    [
        { title: 'Sugg No.', key: 'suggestion_id', width: '10%', type: 'text' },
        { title: 'Title', key: 'suggestion_name', width: '10%', type: 'text' },
        { title: 'Sugg Type', key: 'suggestion_type', width: '10%', type: 'text' },
        { title: 'Award Type', key: 'award_type', width: '10%', type: 'text' },
        { title: 'LOT', key: 'lot', width: '10%', type: 'text' },
        { title: 'Award Amount', key: 'award_amount', width: '10%', type: 'text' },
        { title: 'Proposed Changes', key: 'proposed_changes', width: '10%', type: 'text' },
        { title: 'Status', key: 'status', width: '10%', type: 'text' },
        { title: 'Nature of Saving', key: 'saving_nature', width: '10%', type: 'text' },
        { title: 'Saving Amount', key: 'saving_amount', width: '10%', type: 'text' },
        { title: 'Type of Saving', key: 'saving_type', width: '10%', type: 'text' },
        { title: 'Comment', key: 'comment', width: '10%', type: 'text' },
        { title: 'Present Status', key: 'present_status', type: 'text' },
        { title: 'Appl-area', key: 'area', type: 'text' },
        { title: 'Prev Comment', key: 'previous_comments', width: '10%', type: 'text' },
    ]
]
export const superAdminTableField =[
    // [
    //     // { name: 'Suggestion ID', key: 'id', type: 'text' },
    //     { title: 'Title', key: 'title', width: '10%', type: 'text' },
    //     { title: 'Earlier Status', key: 'earlier_status', width: '10%', type: 'text' },
    //     { title: 'Changes Implemented', key: 'changes_implemented', width: '10%', type: 'text' },
    //     { title: 'Benefits', key: 'benefits', width: '10%', type: 'text' },
    //     { title: 'Suggestion Type', key: 'suggestion_type', width: '10%', type: 'text' },
    //     { title: 'Saving Amount', key: 'annual_saving', width: '10%', type: 'text' },
    //     // { title: 'Type of Saving', key: 'saving_type', width: '10%', type: 'text' },
    //     // { title: 'Nature of Saving', key: 'saving_nature', width: '10%', type: 'text' },
    //     { title: 'Award Amount', key: 'amount', width: '10%', type: 'text' },
    // ],
    [
        // { title: 'Suggestion ID', key: 'id', width: '10%', type: 'text' },
        { title: 'Title', key: 'title', width: '10%', type: 'text' },
        { title: 'Suggestion Type', key: 'scheme_type_name', width: '10%', type: 'text' },
        { title: 'Status', key: 'status', width: '10%', type: 'text' },
        { title: 'Award Type', key: 'award_type', width: '10%', type: 'text' },
        { title: 'Award Category', key: 'award_category', width: '10%', type: 'text' },
        { title: 'Award Amount ', key: 'amount', width: '10%', type: 'text' },
        // { title: 'Type of Saving', key: 'saving_type', width: '10%', type: 'text' },
        // { title: 'Nature of Saving', key: 'saving_nature', width: '10%', type: 'text' },
        // { title: 'Eval Amount', key: 'eval_amount', width: '10%', type: 'text' },
        // { title: 'Comment', key: 'comments', width: '10%', type: 'text' },
        // { title: 'Present Status/Earlier Status', key: '', type: 'special', 
        //     specialRowFunction: (rowdata) => { 
        //         const key = rowdata?.scheme_type_name;
        //         return key === "NORMAL" || key === "SPECIAL" ? 'present_status' : "earlier_status"  
        //     }
        // },

        // { title: 'Proposed Changes/Changes Implemented', key: '', width: '10%',  type: 'special', 
        //     specialRowFunction: (rowdata) => {
        //         const key = rowdata?.scheme_type_name;
        //         return key === "NORMAL" || key === "SPECIAL" ? 'proposed_changes' : 'changes_implemented'
        //     } 
        // },
        
        { title: 'Present Status', key: 'present_status', type: 'text' },
        { title: 'Proposed Changes', key: 'proposed_changes', type: 'text' },
        { title: 'Earlier Status', key: 'earlier_status', type: 'text' },
        { title: 'Changes Implemented', key: 'changes_implemented', width: '10%', type: 'text' },
        { title: 'Benefits', key: 'benefits', width: '10%', type: 'text' },
        { title: 'Applicable areas', key: 'applicable_areas', type: 'text' },
        { title: 'Prev Comment', key: 'comment', width: '10%', type: 'text' },
    ],
    [
        { title: 'Sugg Date', key: 'entry_date', width: '10%', type: 'text' },
        { title: 'Before', key: 'before', width: '10%', type: 'text' },
        { title: 'After', key: 'after', width: '10%', type: 'text' },
        { title: 'Benifits', key: 'benifits', width: '10%', type: 'text' },
        { title: 'Support Info', key: 'support_info', width: '10%', type: 'text' },
        { title: 'Members', key: 'members', width: '10%', type: 'text' },
        { title: 'Memebers Name', key: 'member_name', width: '10%', type: 'text' },
        { title: 'Cost Center', key: 'cost_center', width: '10%', type: 'text' },
        { title: 'Saving Amount', key: 'annual_saving', width: '10%', type: 'text' },
        { title: 'Saving Type', key: 'saving_type', width: '10%', type: 'text' },
    ]
]

export const suggestionDataFieldsConditinal = [
    { name: 'Suggestion ID', key: 'id', type: 'text' },
    { name: 'Suggestion Submission date', key: 'entered_on', type: 'date' },
    { name: 'Suggestion Type', key: 'scheme_type_name', type: 'text' },
    { name: 'Suggestion Title', key: 'title', type: 'text' },
    // { name: 'Present Status', key: 'present_status', type: 'text' },
    // { name: 'Proposed Changes', key: 'proposed_changes', type: 'text' },
    { name: 'Annual Saving in ₹', key: 'annual_saving', type: 'text' },
    { name: 'Idea Bank Reference', key: 'idea_bank_reference', type: 'text' },
    { name: 'Schema Name', key: 'scheme_name', type: 'text',},
    { name: 'Earlier Status', key: 'earlier_status', type: 'text' },    
    { name: 'Implemented Changes', key: 'changes_implemented', type: 'text' },
    { name: 'Benefits', key: 'benefits', type: 'text' },
    { name: 'Place where suggestion applicable', key: 'applicable_areas', type: 'text' },
    { name: 'Implementation Date', key: 'implemented_date', type: 'date' },
]

export const historyDataFields = [
    { title: 'Date', key: 'created_datetime', type: 'date' },
    { title: 'From Agency', key: 'from_agency_name', type: 'text' },
    { title: 'From Status', key: 'from_status_name', type: 'text' },
    { title: 'To Agency', key: 'to_agency_name', type: 'text' },
    { title: 'To Status', key: 'to_status_name', type: 'text' },
    { title: 'To Role', key: 'to_role', type: 'text' },
    { title: 'Updated By', key: 'created_by', type: 'text' },
    { title: 'Name', key: 'updated_emp_name', type: 'text' },
    { title: 'Comments', key: 'comment', type: 'text' },
]
export const savingDetails = [
    { title: 'Saving Category', key: 'selectedSavingCategory', type: 'text' }, 
    { title: 'Saving Type', key: 'selectedSavingType', type: 'text' },
    { title: 'Evaluation Amount', key: 'enteredSavingAmount', type: 'text' },
]
export const suggestionOfficeSavingDetails = [ ///---------------->Change Key According to api response
    { title: 'Saving Category', key: 'category', type: 'date' }, 
    { title: 'Saving Type', key: 'saving_type', type: 'text' },
    { title: 'Saving Amount', key: 'saving_amount', type: 'text' },
    { title: 'Evaluation Date', key: 'evaluation_date', type: 'text' },
    { title: 'Action', key: 'to_status', type: 'text' },
]
export const memberDataFields = [
    { title: 'MemberID', key: 'emp_id', type: 'text' },
    { title: 'Name', key: 'name', type: 'text' },
    { title: 'Cost Centre', key: 'cost_center_name', type: 'text' },
    { title: 'Designation', key: 'designation', type: 'text' },
    { title: 'Grade', key: 'grade', type: 'text' }
]
export const evaluatorActions = [
    { name: 'Approve', value: 'approve' },

    { name: 'Reject', value: 'reject' },
    { name: 'Send Suggestion to Other Evaluator', value: 'suggestion to other evaluator' },
    { name: 'Send Back', value: 'send back' }
]
export const divisionalActions = [
    { name: 'Approve', value: 'approve' },
    { name: 'Reject', value: 'reject' },
    { name: 'Send Back', value: 'send back' }
]
export const SuggestionOfficeActions = [
    { name: 'Approve', value: 'approve' },
    { name: 'Reject', value: 'reject' },
    { name: 'Send Back', value: 'Send_back' }
]

export const superAdminDetailsOptions = [
    { name: 'Approve', value: 'approve' },
    { name: 'Send Back', value: 'Send_back' }
]

export const superAdminActions = [
    { name: 'Approve', value: 'approve' },
    { name: 'Reject', value: 'reject' },
    { name: 'Send Back', value: 'send back' }
]
export const awardType = [
    { name: 'Final Award', value: 'final award' },
    { name: 'Idea bank award', value: 'idea bank award' }
]
export const finalAwardType = [
    { name: 'LOT', value: 'lot' },
    { name: 'Award', value: 'award' }
]
export const awardTypeImplemented = [
    { name: 'Final Award', value: 'final award' }
]

export const suggestionDetails = [
    { width: "10%", title: "Suggestion ID", key: "id" },
    { width: "10%", title: "Title", key: "title"},
    { width: "10%", title: "Category", key: "category_name"},   
    { width: "10%", title: "Scheme Type", key: "scheme_type_name"},
    { width: "10%", title: "Division", key: "division_name"},
    { width: "8%", title: "Agency", key: "agency_name"},
    { width: "10%", title: "Award Amount", key: "award_amount"},
    { width: "10%", title: "Suggestion Date", key: "created_datetime"},
    { width: "10%", title: "Last Modified Date", key: "modified_datetime"},
    { width: "10%", title: "comment", key: "comment"},
    { width: "10%", title: "Status", key: "status"}
]

export const SuggestionDocument = [
    // { width: "5%", title: "Sr No", key: "sugg_no" },
    { width: "10%", title: "Document Name", key: "type"},
    { width: "10%", title: "Document Type", key: "attachment"},
    // { width: "10%", title: "Ac Date", key: "last_modified_date"},
    // { width: "10%", title: "Status", key: "status"}
]

export const SuggestionMemberDetails = [
    // { width: "5%", title: "Sr No", key: "sugg_no" },
    { width: "1%", title: "Member ID", key: "emp_id"},
    { width: "3%", title: "Cost Center Name", key: "cost_center_name"},
    { width: "4%", title: "Complete Name", key: "name"},
    { width: "3%", title: "Designation", key: "designation"},
    { width: "2%", title: "Grade Category", key: "grade"},
    { width: "2%", title: "Contact", key: "contact_no"},
    { width: "2%", title: "Final Amount", key: "award_amount"}

]

export const clarification = [
    { width: "10%", title: "Suggestion ID", key: "id" },
    { width: "10%", title: "Title", key: "title"},
    { width: "10%", title: "Suggestion Date", key: "created_datetime"},
    { width: "10%", title: "Auto-Closure Date", key: "auto_close_date"},
    { width: "15%", title: "Query for Clarification", key: "comment"},
    { width: "10%", title: "Status", key: "status"}
]


export const schemeCode = {
    Normal: 0,
    Special: 1,
    Implemented: 2,
    "Special Implemented": 3
};
export const exclusiveSchemeTypes = ['Implemented','Special implemented']



export const manualReferenceLink = {
    "Employee Suggestion": "https://di0zifa10d7yc.cloudfront.net/EDP/TML/UserManual/SuggestionScheme/suggestor_user_manual.pdf",
    "Suggestor Dashboard": "https://di0zifa10d7yc.cloudfront.net/EDP/TML/UserManual/SuggestionScheme/suggestor_user_manual.pdf",
    "Evaluator Dashboard": "https://di0zifa10d7yc.cloudfront.net/EDP/TML/UserManual/SuggestionScheme/evaluator_user_manual.pdf",
    "Divisional Co-ordinator Dashboard": "https://di0zifa10d7yc.cloudfront.net/EDP/TML/UserManual/SuggestionScheme/divisional_coordinator_user_manual.pdf",
    "Super Admin": "https://di0zifa10d7yc.cloudfront.net/EDP/TML/UserManual/SuggestionScheme/super_admin_user_manual.pdf",
    "Suggestion Office": "https://di0zifa10d7yc.cloudfront.net/EDP/TML/UserManual/SuggestionScheme/suggestion_office_user_manual.pdf",
    "Report": "https://di0zifa10d7yc.cloudfront.net/EDP/TML/UserManual/SuggestionScheme/suggestor_user_manual.pdf",
    "Reports": "https://di0zifa10d7yc.cloudfront.net/EDP/TML/UserManual/SuggestionScheme/suggestion_office_user_manual.pdf",
    "Master Screen": "https://di0zifa10d7yc.cloudfront.net/EDP/TML/UserManual/SuggestionScheme/suggestion_office_user_manual.pdf",
}


export const statusCodes = {
    "Approved with Award": 4,
    "Approved with LOT": 5,
    "Expired at Evaluator": 11,
    "Expired at Suggestor": 10,
    "Pending at Divisional Co-ordinator": 1,
    "Pending at Evaluator": 0,
    "Pending at Suggestion Office": 2,
    "Pending at Super Admin": 3,
    "Pending for Re-consideration ": 6,
    "Rejected by Divisional Co-ordinator": 8,
    "Rejected by Evaluator": 7,
    "Rejected by Suggestion Office": 9,
}