import React, { Component, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import overviewButton from '../../../../assets/drishti_images/overview_button.svg';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import moment from 'moment';
import Dropdown from '../../../shared/components/Dropdown';
import redicon from '../../../../assets/drishti_images/redicon.png';
import '../profile/ProfileScreen.css';
import '../documents/DocumentStyles.css';
import './Eseparation.css';
import infoicon from '../../../../assets/drishti_images/infoicon.svg';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import down_arrow from '../../../../assets/drishti_images/documents/down_arrow.svg';
import up_arrow from '../../../../assets/drishti_images/documents/up_arrow.svg';
import { NoEsepRequest, Title, AddEsepRequest, AddFormTitle, EsepEligibilityNote, FileUploadSizeNote, ActualDateWarning, PayRecoveryTitle, GuidelineHeader, GuidelineTitle, Point8, Point7, Point6, Point5, Point4, Point3, SubPoint3, Point2, Point1, eSepReason, elaborateReason, partnerCompetitor, joinThisOrganization, keyAchievementsAtCompany, highRegards, challengingWorkAndSupport, dissatisfactionDuringYourTenure, retainYourServices, laterDateAndInDifferentCapacity } from './EseparationContants';
import EseparationDashboard from './manager/EseparationDashboard';
import InitiateFormPopups from './InitiateFormPopups';
import ServiceUnavailable from '../../../shared/ServiceUnavailable';
import ClearanceRequests from './clearance/ClearanceRequests';
import radioOff from '../../../../assets/drishti_images/radioOff.png';
import radioOn from '../../../../assets/drishti_images/radioOn.png';
import { getClearanceIsApplicable } from '../../../../constants/api.service';

let emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,6}$/;
var phoneno = /^[1-9]{1}[0-9]{9}$/;
const chooseSelect = 'Select'
const TodaysDate = new Date();
const TodaysDates = TodaysDate.toISOString().slice(0, 10);
const NoticePeriodPolicy = 'https://tatamotors.sharepoint.com/:w:/r/sites/c-app/s-MTMDev/_layouts/15/WopiFrame.aspx?sourcedoc={5e48ff72-a975-4e75-85b6-6093a775c6ec}&action=default'
const EsepUserManual = 'https://tatamotors.sharepoint.com/:p:/r/sites/C-CorpHR/hrdocument/_layouts/15/WopiFrame.aspx?sourcedoc=%7B1C925568-74D5-4512-AB80-FC2E126E0115%7D&file=UserManual-E-Sep-Portal-V1.0.pptx&action=edit&mobileredirect=true&cid=c9c20919-af98-4cd1-ac91-b4a90cd15ba2 '

function ClearanceRequestsScreen(props) {
    return (
        <section>
            <ClearanceRequests />
        </section>
    )
}

class ESeparationHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isShow: false,
            isSubmit: false,
            isEligibleForEsep: true,

            isEmployeeDetailsActive: true,
            isRequestActive: false,
            isManagerApprovalActive: false,
            isBHRApprovalActive: false,
            isBUHRApprovalActive: false,

            isClearanceActive: false,
            isClearancePresent: false,

            isGroupFirstActive: true,
            isGroupSecondActive: true,
            isGroupThirdActive: true,
            isGroupFourthActive: true,

            openCard: false,
            openCard1: false,
            openCard2: false,
            openCard3: false,
            demyREquestsData: [],
            //----------Approval status-----------
            isApplicable: false,

            //----------Add Request Popup States-----------
            priorDate: new Date(),
            requestDate: '',
            resignationDate: '',
            lastWorkingDate: '',
            warnShow: false,
            documentName: '',
            isJustify: false,
            requestedLastWorkingDate: '',
            remarks: '',
            justification: '',
            contactNo: '',
            emailID: '',
            address: '',

            employeeReasonData: [],
            reasonValue: 'Select',
            reasonCode: '',

            payRecoveryPopup: false,
            esepIntimationDays: '',
            noticePeriodDays: '',
            //--------------Get employee all data---------
            isEsepPresent: false,
            employeeMasterData: [],
            leaveData: [],
            relationshipData: [],
            salaryData: [],
            //----Approvals Request Present or Not Keys-----
            isDEMApprovalPresent: false,
            isBHRpprovalPresent: false,
            isBUHRApprovalPresent: false,
            //----Get dashboard request/approvals data-----
            approvalsRequestData: [],
            requestsData: [],

            bhrEsepApprovalsData: [],
            buHREsepApprovalsData: [],

            page: 1,
            count: [],

            empID: '',
            checked: 0,
            isShowInfo: false,
            is_service_Unavailable: false,
            document_Service_reason: '',
            reason_for_leaving: '',
            reason_for_leaving_elaborate: '',
            other_organization_info: '',
            reason_of_joining: '',
            main_role: '',
            aspect_of_organization: '',
            relationship_with_superior: '',
            dissatisfaction_feature: '',
            action_of_organization_to_retain_sevice: '',
            like_to_work_later:  '',

            // noticePayRecoveryOptions: [],
            // listType: '',
            // radioOption: 0,
            // selectedId: 1,

        }
    }

    componentDidMount() {
        this.checkService();
        this.getEsepRequest();
        this.getDemEsepApprovals();
        this.getBhrEsepApprovals();
        this.getBuhrEsepApprovals();
        this.getResignationReasons();
        this.isClearanceApplicable()
    }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "eseparation_request" && item.is_active == false) {
                this.setState({
                    is_service_Unavailable: true,
                    document_Service_reason: item.reason
                })
            }
        })
    }

    gotoLanding = () => {
        window.location = (BASE_WEB_URL_DEV + 'home');
    }

    initiateEsepRequest = () => {
        this.setState({ isShow: !this.state.isShow })
    }

    isShowInfo = () => {
        this.setState({ isShowInfo: !this.state.isShowInfo })
    }

    onClose = () => {
        this.setState({
            isShow: false,
            payRecoveryPopup: false,
            isJustify: false,
            requestDate: '', resignationDate: '', lastWorkingDate: '', documentName: '', requestedLastWorkingDate: '', remarks: '', justification: '', contactNo: '', emailID: '', address: '', reasonValue: 'Select',
            reason_for_leaving: '',
            reason_for_leaving_elaborate: '',
            other_organization_info: '',
            reason_of_joining: '',
            main_role: '',
            aspect_of_organization: '',
            relationship_with_superior: '',
            dissatisfaction_feature: '',
            action_of_organization_to_retain_sevice: '',
            like_to_work_later:  '',
        })
    }

    onDismiss = () => {
        this.setState({
            isShow: false,
            isJustify: false, requestDate: '', resignationDate: '', lastWorkingDate: '', documentName: '', requestedLastWorkingDate: '', remarks: '', justification: '', contactNo: '', emailID: '', address: '', reasonValue: 'Select',
            reason_for_leaving: '',
            reason_for_leaving_elaborate: '',
            other_organization_info: '',
            reason_of_joining: '',
            main_role: '',
            aspect_of_organization: '',
            relationship_with_superior: '',
            dissatisfaction_feature: '',
            action_of_organization_to_retain_sevice: '',
            like_to_work_later:  '',
        })
    }

    onPayRecoveryPopupDismiss = () => {
        this.setState({ payRecoveryPopup: false, isShow: true, isJustify: false, requestDate: '', resignationDate: '', lastWorkingDate: '', documentName: '', requestedLastWorkingDate: '', remarks: '', justification: '', contactNo: '', emailID: '', address: '', reasonValue: 'Select' })
    }

    toggleEmployeeDetails = () => {
        this.setState({
            isEmployeeDetailsActive: true, isRequestActive: false, isBUHRApprovalActive: false, isManagerApprovalActive: false, isBHRApprovalActive: false, isClearanceActive: false
        }, () => this.getEsepRequest())
    }

    toggleRequestsDetails = () => {
        this.setState({
            isEmployeeDetailsActive: false, isRequestActive: true, isBUHRApprovalActive: false, isManagerApprovalActive: false, isBHRApprovalActive: false, isClearanceActive: false, requestsData: [], approvalsRequestData: [], buHREsepApprovalsData: [], bhrEsepApprovalsData: [],
        }, () => this.getEsepRequest('Pending'))
    }

    toggleManagerApprovalsDetails = () => {
        this.setState({
            isEmployeeDetailsActive: false, isRequestActive: false, isBUHRApprovalActive: false, isManagerApprovalActive: true, isBHRApprovalActive: false, isClearanceActive: false, requestsData: [], approvalsRequestData: [], buHREsepApprovalsData: [], bhrEsepApprovalsData: [],
        }, () => this.getDemEsepApprovals('Pending'))
    }

    toggleBHRApprovalsDetails = () => {
        this.setState({
            isEmployeeDetailsActive: false, isRequestActive: false, isBUHRApprovalActive: false, isManagerApprovalActive: false, isBHRApprovalActive: true, isClearanceActive: false, requestsData: [], approvalsRequestData: [], buHREsepApprovalsData: [], bhrEsepApprovalsData: [],
        }, () => this.getBhrEsepApprovals('Pending'))
    }

    toggleBUHRApprovalsDetails = () => {
        this.setState({
            isEmployeeDetailsActive: false, isRequestActive: false, isBUHRApprovalActive: true, isManagerApprovalActive: false, isBHRApprovalActive: false, isClearanceActive: false, requestsData: [], approvalsRequestData: [], buHREsepApprovalsData: [], bhrEsepApprovalsData: [],
        }, () => this.getBuhrEsepApprovals('Pending'))
    }

    toggleClearanceDetails = () => {
        this.setState({
            isEmployeeDetailsActive: false, isRequestActive: false, isBUHRApprovalActive: false, isManagerApprovalActive: false, isBHRApprovalActive: false, isClearanceActive: true, requestsData: [], approvalsRequestData: [], buHREsepApprovalsData: [], bhrEsepApprovalsData: [],
        })
    }

    toggleCard = (type) => {
        if (type === 'employee') {
            this.setState({ openCard: !this.state.openCard })
        }
        else if (type === 'relationship') {
            this.setState({ openCard1: !this.state.openCard1 })
        }
        else if (type === 'leave') {
            this.setState({ openCard2: !this.state.openCard2 })
        }
        else if (type === 'salary') {
            this.setState({ openCard3: !this.state.openCard3 })
        }
        else return null
    }

    //--------Get Employee SAP Data and Request/Approval data APIs-----------
    getEsepRequest = (type) => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            status: type
        }
        fetchApiCall(Config.getEsepRequest, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        isEsepPresent: res.data.data.is_request_present,
                        isApplicable: res.data.data.is_applicable,
                        employeeMasterData: res.data.data.basic_details,
                        leaveData: res.data.data.leave_quota,
                        relationshipData: res.data.data.relationship_details,
                        requestsData: res.data.data.requests,
                        salaryData: res.data.data.salary_details,
                        esepIntimationDays: res.data.data.days_as_per_policy.esep_intimation_days,
                        noticePeriodDays: res.data.data.days_as_per_policy.notice_period_days,
                    });
                } else if (res.data.status_code == 400) {
                    this.setState({
                        loading: false,
                        isEligibleForEsep: false,
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        employeeMasterData: [],
                        leaveData: [],
                        relationshipData: [],
                        requestsData: [],
                        salaryData: [],
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    employeeMasterData: [],
                    leaveData: [],
                    relationshipData: [],
                    requestsData: [],
                    salaryData: [],
                });
            })
    }
    //-------------------------Add Request APIs------------------------------
    addSeparationRequest = () => {
        this.setState({
            loading: true,
            isSubmit: true,
            isShow: false,
            payRecoveryPopup: false
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            resignation_request_date: moment(this.state.priorDate).format("YYYY-MM-DD") ? moment(this.state.priorDate).format("YYYY-MM-DD") : '',
            actual_resignation_date: this.state.resignationDate ? this.state.resignationDate : '',
            lwd_requested_by_employee: moment(this.state.requestedLastWorkingDate).format("YYYY-MM-DD") ? moment(this.state.requestedLastWorkingDate).format("YYYY-MM-DD") : '',
            lwd_as_per_policy: moment(this.state.lastWorkingDate).format("YYYY-MM-DD") ? moment(this.state.lastWorkingDate).format("YYYY-MM-DD") : '',
            employee_separation_reason: this.state.reasonValue ? this.state.reasonValue : '',
            attachment: this.state.documentLink ? this.state.documentLink : '',
            file_name: this.state.documentName ? this.state.documentName : '',
            employee_separation_code: this.state.reasonCode ? this.state.reasonCode : '',
            employee_separation_remark: this.state.remarks ? this.state.remarks : '',
            early_release_justification: this.state.justification ? this.state.justification : '',
            employee_personal_email: this.state.emailID ? this.state.emailID : '',
            employee_contact_no: this.state.contactNo ? this.state.contactNo : '',
            employee_address: this.state.address ? this.state.address : '',
            // notice_pay_recovery_option: this.state.selectedId ? this.state.selectedId : 1
            reason_for_leaving: this.state.reason_for_leaving ? this.state.reason_for_leaving : '',
            reason_for_leaving_elaborate: this.state.reason_for_leaving_elaborate ? this.state.reason_for_leaving_elaborate : '',
            other_organization_info: this.state.other_organization_info ? this.state.other_organization_info : '',
            reason_of_joining: this.state.reason_for_leaving ? this.state.reason_for_leaving : '',
            main_role: this.state.main_role ? this.state.main_role : '',
            aspect_of_organization: this.state.aspect_of_organization ? this.state.aspect_of_organization : '',
            relationship_with_superior: this.state.relationship_with_superior ? this.state.relationship_with_superior : '',
            dissatisfaction_feature: this.state.dissatisfaction_feature ? this.state.dissatisfaction_feature : '',
            action_of_organization_to_retain_sevice: this.state.action_of_organization_to_retain_sevice ? this.state.action_of_organization_to_retain_sevice : '',
            like_to_work_later:  this.state.like_to_work_later ? this.state.like_to_work_later : '',

        }
        fetchApiCall(Config.addSeparationRequest, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 201) {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.submitRequest();
                    this.toggleRequestsDetails();
                    this.onDismiss();
                } else {
                    this.setState({
                        loading: false,
                    });
                    this.onDismiss();
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                });
            })
    }

    fileSelectHandler = (event) => {
        this.setState({ documentName: event.target.files[0] ? event.target.files[0].name : '' })
        if (event.target.files && event.target.files[0]) {
            let maxAllowedSize = 2097152;           // 2MB size
            if (event.target.files[0].size <= maxAllowedSize) {
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e) => { this.setState({ documentLink: e.target.result }) }
            }
            else {
                toast.warn('Please upload image less than 2MB', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
                this.setState({ documentLink: '' })
            }
        }
        else { this.setState({ documentLink: '' }) }
    }

    changeResonsType = (data) => {
        this.setState({
            reasonCode: data.code,
            reasonValue: data.value,
        })
    }

    lengthCalculate = (value) => {
        let length = value ? value.length : 0
        return length
    }

    handleChangeFunction = (e) => {
        this.setState({
            resignationDate: e.target.value,
        }, () => this.handleChangeLastWorkingDate())
    }

    handleChangeLastWorkingDate = () => {
        var myDate = new Date(this.state.resignationDate);
        myDate.setDate(myDate.getDate() + this.state.noticePeriodDays);
        this.setState({
            lastWorkingDate: myDate,
            requestedLastWorkingDate: myDate
        })
    }

    submitRequest = () => {
        this.setState({
            isSubmit: true,
            payRecoveryPopup: false,
            isShow: false,
        })
    }

    payRecoveryFunctionCall = () => {
        this.setState({
            payRecoveryPopup: true,
        }
        // , this.getNoticePayRecoveryOption()
        )
    }

    handleChangeRequestedDate = (e) => {
        this.setState({
            requestedLastWorkingDate: e,
        },
            () => this.isJustifyCall())
    }

    validateRequest = () => {
        const lastWorkingDay = moment(this.state.lastWorkingDate).format("YYYY-MM-DD")
        if (lastWorkingDay > this.state.requestedLastWorkingDate) {
            this.payRecoveryFunctionCall()
        }
        else {
            this.addSeparationRequest();
        }
    }

    isJustifyCall = () => {
        const lastWorkingDay = moment(this.state.lastWorkingDate).format("YYYY-MM-DD")
        if (lastWorkingDay > this.state.requestedLastWorkingDate) {
            this.setState({
                isJustify: true
            })
        }
        else if (lastWorkingDay <= this.state.requestedLastWorkingDate) {
            this.setState({
                isJustify: false
            })
        }
    }

    validateAddRequest = () => {
        if (this.state.resignationDate === '') {
            toast.error('Please select actual resignation date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.lastWorkingDate === '') {
            toast.error('Please select last working date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.requestedLastWorkingDate === '') {
            toast.error('Please select requested last working date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.contactNo === '' || (!phoneno.test(this.state.contactNo) || this.state.contactNo.length < 10)) {
            if (this.state.contactNo === '') {
                toast.error('Please enter contact no', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else if ((!phoneno.test(this.state.contactNo) || this.state.contactNo.length < 10)) {
                toast.error('Please enter valid contact no', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
        }
        else if (this.state.emailID === '' || !emailReg.test(this.state.emailID)) {
            if (this.state.emailID === '') {
                toast.error('Please enter email id', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else if (!emailReg.test(this.state.emailID)) {
                toast.error('Please enter valid email id', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
        }
        else if (this.state.address === '') {
            toast.error('Please enter address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.documentName === '') {
            toast.error('Please upload the attachment', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.reasonValue === chooseSelect) {
            toast.error('Please select reason', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.remarks === '') {
            toast.error('Please enter remarks', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.isJustify === true && this.state.justification === '') {
            toast.error('Please enter justification', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.isJustify === true && this.state.justification.length < 100) {
            toast.error('Please enter 100 min characters in justification', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.validateRequest();
        }
    }

    handleChange = (e) => { this.setState({ empID: e }) }

    //--------------Employee Id Search on the Dashboard-----------------------
    searchEmpIdFunction = (type) => {
        if (this.state.isManagerApprovalActive === true) {
            this.getDemEsepApprovals(type);
        } else if (this.state.isBHRApprovalActive === true) {
            this.getBhrEsepApprovals(type);
        } else if (this.state.isBUHRApprovalActive === true) {
            this.getBuhrEsepApprovals(type);
        } 
        // else if (this.state.isClearanceActive === true) {
        //     this.getClearnceData();
        // }
    }

    //--------------Pagination Function Handled------------------------------
    handlePageCount = (type, list) => {
        let pageCountInc = this.state.page + 1
        let pageCountDec = this.state.page - 1
        if (this.state.isManagerApprovalActive === true) {
            console.log('isManagerApprovalActive');
            if (type === "increment") {
                if (pageCountInc <= this.state.count[this.state.count.length - 1]) {
                    this.setState({ page: pageCountInc }, () => this.getDemEsepApprovals(list))
                }
            }
            else if (type === "decrement") {
                if (this.state.page > 1) { this.setState({ page: pageCountDec }, () => this.getDemEsepApprovals(list)) }
            }
        } else if (this.state.isBHRApprovalActive === true) {
            console.log('isBHRApprovalActive');
            if (type === "increment") {
                if (pageCountInc <= this.state.count[this.state.count.length - 1]) {
                    this.setState({ page: pageCountInc }, () => this.getBhrEsepApprovals(list))
                }
            }
            else if (type === "decrement") {
                if (this.state.page > 1) { this.setState({ page: pageCountDec }, () => this.getBhrEsepApprovals(list)) }
            }
        } else if (this.state.isBUHRApprovalActive === true) {
            console.log('isBUHRApprovalActive');
            if (type === "increment") {
                if (pageCountInc <= this.state.count[this.state.count.length - 1]) {
                    this.setState({ page: pageCountInc }, () => this.getBuhrEsepApprovals(list))
                }
            }
            else if (type === "decrement") {
                if (this.state.page > 1) { this.setState({ page: pageCountDec }, () => this.getBuhrEsepApprovals(list)) }
            }
        } 
        // else if (this.state.isClearanceActive === true) {
        //     if (type === "increment") {
        //         if (pageCountInc <= this.state.count[this.state.count.length - 1]) {
        //             this.setState({ page: pageCountInc },
        //                 () => this.getClearnceData(list)
        //             )
        //         }
        //     }
        //     else if (type === "decrement") {
        //         if (this.state.page > 1) {
        //             this.setState({ page: pageCountDec },
        //                 () => this.getClearnceData(list)
        //             )
        //         }
        //     }
        // }
    }

    selectedPage = (pageCount, list) => {
        if (this.state.isManagerApprovalActive === true) {
            this.setState({ page: pageCount }, () => this.getDemEsepApprovals(list))
        } else if (this.state.isBHRApprovalActive === true) {
            this.setState({ page: pageCount }, () => this.getBhrEsepApprovals(list))
        } else if (this.state.isBUHRApprovalActive === true) {
            this.setState({ page: pageCount }, () => this.getBuhrEsepApprovals(list))
        } 
        // else if (this.state.isClearanceActive === true) {
        //     this.setState({ page: pageCount },
        //         () => this.getClearnceData(list)
        //     )
        // }
    }

    //-----------------Get Employee Resignation Reason API--------------
    getResignationReasons = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {}
        fetchApiCall(Config.getResignationReasons, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        employeeResignationReasons: res.data.data.resignation_reasons
                    })
                    const tempReasonList = this.state.employeeResignationReasons.map(tempValue => ({ value: tempValue.reason_for_action_description, code: tempValue.reason_for_action_code }))
                    this.setState({
                        employeeReasonData: tempReasonList,
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false,
                        employeeReasonData: [],
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    employeeReasonData: [],
                });
            })
    }
    //-----------------Get Dem Approvals Data API-----------------------
    getDemEsepApprovals = (type) => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            search_text: this.state.empID ? this.state.empID : '',
            status: type,
            page_number: this.state.page
        }
        fetchApiCall(Config.getDemEsepApprovals, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    //demData to collect total number for pagination records
                    let demData = Array.from({ length: res.data.data.total_pages }, (_, i) => i + 1)
                    this.setState({
                        loading: false,
                        approvalsRequestData: res.data.data.paginated_data ? res.data.data.paginated_data : [],
                        isDEMApprovalPresent: res.data.data.is_approval_present ? res.data.data.is_approval_present : false,
                        count: demData ? demData : []
                    });
                } else {
                    this.setState({
                        loading: false,
                        approvalsRequestData: [],
                        isDEMApprovalPresent: false,
                        count: [],
                        page: 1
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    approvalsRequestData: [],
                    isDEMApprovalPresent: false,
                    count: [],
                    page: 1
                });
            })
    }
    //-----------------Get BHR Approvals Data API-----------------------
    getBhrEsepApprovals = (type) => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            search_text: this.state.empID ? this.state.empID : '',
            status: type,
            page_number: this.state.page
        }
        fetchApiCall(Config.getBhrEsepApprovals, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    //demData to collect total number for pagination records
                    let bhrData = Array.from({ length: res.data.data.total_pages }, (_, i) => i + 1)
                    this.setState({
                        loading: false,
                        bhrEsepApprovalsData: res.data.data.paginated_data ? res.data.data.paginated_data : [],
                        isBHRpprovalPresent: res.data.data.is_approval_present ? res.data.data.is_approval_present : false,
                        count: bhrData ? bhrData : [],
                    });
                } else {
                    this.setState({
                        loading: false,
                        bhrEsepApprovalsData: [],
                        isBHRpprovalPresent: false,
                        page: 1
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    bhrEsepApprovalsData: [],
                    isBHRpprovalPresent: false,
                    page: 1
                });
            })
    }
    //-----------------Get BUHR Approvals Data API----------------------
    getBuhrEsepApprovals = (type) => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            search_text: this.state.empID ? this.state.empID : '',
            status: type,
            page_number: this.state.page
        }
        fetchApiCall(Config.getBuhrEsepApprovals, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    //buhrData to collect total number for pagination records
                    let buhrData = Array.from({ length: res.data.data.total_pages }, (_, i) => i + 1)
                    this.setState({
                        loading: false,
                        buHREsepApprovalsData: res.data.data.paginated_data ? res.data.data.paginated_data : [],
                        isBUHRApprovalPresent: res.data.data.is_approval_present ? res.data.data.is_approval_present : false,
                        count: buhrData ? buhrData : []
                    });
                } else {
                    this.setState({
                        loading: false,
                        buHREsepApprovalsData: [],
                        isBUHRApprovalPresent: false,
                        count: [],
                        page: 1
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    buHREsepApprovalsData: [],
                    isBUHRApprovalPresent: false,
                    count: [],
                    page: 1
                });
            })
    }

    // onPresentType = (key, type, id) => {
    //     this.setState({
    //         radioOption: key,
    //         listType: type,
    //         selectedId: id
    //     })
    // }

    //-----------------Get Notice Pay Recovery Options Data API----------------------
    // getNoticePayRecoveryOption = (type) => {
    //     this.setState({
    //         loading: true,
    //     });
    //     const headers = {
    //         'Content-Type': 'application/json',
    //     }
    //     let body = {}
    //     fetchApiCall(Config.getNoticePayRecoveryOption, body, headers, true, '')
    //         .then(res => {
    //             sessionOut(this.props, res);
    //             if (res.data.status_code == 200) {
    //                 this.setState({
    //                     loading: false,
    //                     noticePayRecoveryOptions: res.data.data ? res.data.data : {}
    //                 });
    //             } else {
    //                 this.setState({
    //                     loading: false,
    //                     noticePayRecoveryOptions: {}
    //                 });
    //             }
    //         }).catch(err => {
    //             console.log('err-->', err);
    //             this.setState({
    //                 loading: false,
    //                 noticePayRecoveryOptions: {}
    //             });
    //         })
    // }

    isClearanceApplicable = () => {
        this.setState({
            loading: true,
        });
        getClearanceIsApplicable(this.props).then(res => {
            if (res.status == 200 && res?.data?.data?.is_applicable_for_clearance === true) {
                this.setState({
                    loading: false,
                    isClearancePresent: true
                });
            } else {
                this.setState({
                    loading: false,
                    isClearancePresent: false
                });
            }

        }).catch(err => {
            console.log('err-->', err);
            this.setState({
                loading: false,
                isClearancePresent: false
            });
        })
    }
    render() {
        const { isShow, warnShow, isJustify, isDEMApprovalPresent, isBHRpprovalPresent, isBUHRApprovalPresent, isShowInfo, isClearanceActive,isClearancePresent } = this.state
        //---------------Roles wise dates and notice period handeling-------------
        const today = new Date();
        const priorDate = new Date(new Date().setDate(today.getDate() - this.state.esepIntimationDays));
        const toDay = priorDate.toISOString().substring(0, 10); // for hiding dates
        //---------------Early release case pay calculations----------------------
        var start = moment(this.state.lastWorkingDate, "YYYY-MM-DD");
        var end = moment(this.state.requestedLastWorkingDate, "YYYY-MM-DD");
        const toatalDaysCount = start.diff(end, 'day')
        const finalCount = Math.abs((toatalDaysCount / 30) * this.state.salaryData.MonthlyTfp).toFixed(2)
        return (
            <div>
                {this.state.is_service_Unavailable ?
                    <div className="col-md-12 h-100vh justify-content-center align-item-center">
                        <ServiceUnavailable reason={this.state.document_Service_reason} width={true} />
                    </div>
                    :
                    <div>
                        <LoaderIndicator loading={this.state.loading} />
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="row justify-content-between align-item-center">
                                    <div className='row pointer'
                                        onClick={this.gotoLanding}>
                                        <img src={overviewButton} />
                                    </div>
                                </div>
                                <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                                    <div className='d-flex justify-content-between'>
                                        <div className='row d-flex align-items-center'>
                                            <label className='document-common-heading common-text-color ml-2 mr-2'>{Title}</label>
                                            <img alt="info" src={infoicon} onClick={() => this.isShowInfo()} className="infosize"></img>
                                        </div>
                                        <div className='row'>
                                            <div className='row'>
                                                <a href={EsepUserManual} target="_blank"><label className='tata-policy-link mr-4' type='button'>{'e-Sep User Manual'}</label></a>
                                            </div>
                                            <div className='horizontal-lines mr-4'></div>
                                            <div className='row'>
                                                <a href={NoticePeriodPolicy} target="_blank"><label className='tata-policy-link mr-4' type='button'>{'Notice Pay Policy'}</label></a>
                                            </div>
                                        </div>
                                    </div>
                                    {/*-----------------------Eseparation Main Screen Header Tabs-----------------------------*/}
                                    <div className='d-flex justify-content-start align-item-center mt-3'>
                                        <div className={this.state.isEmployeeDetailsActive ? "tab-active" : "tab-inactive"}
                                            onClick={this.toggleEmployeeDetails}>{'Employee Details'}
                                        </div>
                                        {this.state.isEligibleForEsep === false ? null :
                                            <>
                                                {this.state.isSubmit ?
                                                    <div className={this.state.isRequestActive ? "tab-active" : "tab-inactive"}
                                                        onClick={this.toggleRequestsDetails}>{'Employee Requests'}
                                                    </div>
                                                    :
                                                    <div className={this.state.isRequestActive ? "tab-active" : "tab-inactive"}
                                                        onClick={this.toggleRequestsDetails}>{'Employee Requests'}
                                                    </div>
                                                }
                                            </>}
                                        {isDEMApprovalPresent &&
                                            <div className={this.state.isManagerApprovalActive ? "tab-active" : "tab-inactive"}
                                                onClick={this.toggleManagerApprovalsDetails}>{'Manager Approvals'}
                                            </div>}
                                        {isBHRpprovalPresent &&
                                            <div className={this.state.isBHRApprovalActive ? "tab-active" : "tab-inactive"}
                                                onClick={this.toggleBHRApprovalsDetails}>{'BHR Approvals'}
                                            </div>}
                                        {isBUHRApprovalPresent &&
                                            <div className={this.state.isBUHRApprovalActive ? "tab-active" : "tab-inactive"}
                                                onClick={this.toggleBUHRApprovalsDetails}>{'BU HR Approvals'}
                                            </div>}
                                        {isClearancePresent &&
                                            <div className={this.state.isClearanceActive ? "tab-active" : "tab-inactive"}
                                                onClick={this.toggleClearanceDetails}>{'Clearance Status'}
                                            </div>}
                                    </div>
                                    <div className='mt-3px'><hr /></div>
                                    {/*-----------------------Employee Basic Details-----------------------------*/}
                                    {this.state.isEmployeeDetailsActive &&
                                        <>
                                            {this.state.isEligibleForEsep === false ?
                                                <div>
                                                    <div className='esep-content mx-2 mt-2'>{EsepEligibilityNote}</div>
                                                </div>
                                                :
                                                <div>
                                                    {this.state.isGroupFirstActive &&
                                                        <div className='document_card mt-3 p-3 mb-24px col-md-12 pointer'
                                                            onClick={() => this.toggleCard('employee')}>
                                                            {!this.state.openCard ?
                                                                <div className='d-flex justify-content-between align-item-center'>
                                                                    <div className='toggle-card-heading ml-2'>
                                                                        {'Basic Details'}
                                                                    </div>
                                                                    <div
                                                                        className='justify-content-end pointer'
                                                                        onClick={() => this.toggleCard('employee')}>
                                                                        <img
                                                                            alt="hide"
                                                                            src={down_arrow}
                                                                            className="up_arrow"
                                                                        ></img>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='d-flex justify-content-between align-item-center'>
                                                                    <div className='toggle-card-heading ml-2'>
                                                                        {'Basic Details'}
                                                                    </div>
                                                                    <div
                                                                        className='justify-content-end pointer'
                                                                        onClick={() => this.toggleCard('employee')}>
                                                                        <img
                                                                            alt="hide"
                                                                            src={up_arrow}
                                                                            className="up_arrow"
                                                                        ></img>
                                                                    </div>
                                                                </div>}
                                                            {this.state.openCard &&
                                                                <>
                                                                    <div className='h-37vh'>
                                                                        <div className='esep-grid'>
                                                                            <div className='esep-first mt-2'>
                                                                                <div className='column'>
                                                                                    <div className='p-sub-heading ml-3'>{'Employee ID'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.employeeMasterData.employee_id}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='column mt-2'>
                                                                                    <div className='p-sub-heading ml-3'>{'Company Name'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.employeeMasterData.company_name}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='column mt-2'>
                                                                                    <div className='p-sub-heading ml-3'>{'TML Group Date of Joining (ZV)'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={moment(this.state.employeeMasterData.tml_group_doj).format("DD-MM-YYYY")}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='esep-second mt-2'>
                                                                                <div className='column '>
                                                                                    <div className='p-sub-heading ml-3'>{'Employee Name'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.employeeMasterData.full_name}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='column mt-2'>
                                                                                    <div className='p-sub-heading ml-3'>{'Organization Unit'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.employeeMasterData.org_unit}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='column mt-2'>
                                                                                    <div className='p-sub-heading ml-3'>{'TML Group Experiance'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.employeeMasterData.tml_group_experiance}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='esep-third mt-2'>
                                                                                <div className='column '>
                                                                                    <div className='p-sub-heading ml-3'>{'Designation'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.employeeMasterData.designation}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='column mt-2'>
                                                                                    <div className='p-sub-heading ml-3'>{'Tata Group Date of Joining (ZG)'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={moment(this.state.employeeMasterData.tata_group_doj).format("DD-MM-YYYY")}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='column mt-2'>
                                                                                    <div className='p-sub-heading ml-3'>{'Legal Entity Date of Joining (Z1)'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={moment(this.state.employeeMasterData.legal_entity_doj).format("DD-MM-YYYY")}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='esep-fourth mt-2'>
                                                                                <div className='column '>
                                                                                    <div className='p-sub-heading ml-3'>{'Level'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.employeeMasterData.level}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='column mt-2'>
                                                                                    <div className='p-sub-heading ml-3'>{'Tata Group Experience'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.employeeMasterData.tata_group_experiance}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='column mt-2'>
                                                                                    <div className='p-sub-heading ml-3'>{'Legal Entity Experience'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.employeeMasterData.legal_entity_experiance}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    }
                                                    {this.state.isGroupSecondActive &&
                                                        <div className='document_card mt-3 p-3 mb-24px col-md-12 pointer'
                                                            onClick={() => this.toggleCard('relationship')}>
                                                            {!this.state.openCard1 ?
                                                                <div className='d-flex justify-content-between align-item-center'>
                                                                    <div className='toggle-card-heading ml-2'>
                                                                        {'Business Relationship Details'}
                                                                    </div>
                                                                    <div
                                                                        className='justify-content-end pointer'
                                                                        onClick={() => this.toggleCard('relationship')}>
                                                                        <img
                                                                            alt="hide"
                                                                            src={down_arrow}
                                                                            className="up_arrow"
                                                                        ></img>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='d-flex justify-content-between align-item-center'>
                                                                    <div className='toggle-card-heading ml-2'>
                                                                        {'Business Relationship Details'}
                                                                    </div>
                                                                    <div
                                                                        className='justify-content-end pointer'
                                                                        onClick={() => this.toggleCard('relationship')}>
                                                                        <img
                                                                            alt="hide"
                                                                            src={up_arrow}
                                                                            className="up_arrow"
                                                                        ></img>
                                                                    </div>
                                                                </div>}
                                                            {this.state.openCard1 &&
                                                                <>
                                                                    <div className=' h-25vh'>
                                                                        <div className='esep-grid'>
                                                                            <div className='esep-first mt-2'>
                                                                                <div className='column'>
                                                                                    <div className='p-sub-heading ml-3'>{'DEM Manager'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.relationshipData.DemEname}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='column mt-2'>
                                                                                    <div className='p-sub-heading ml-3'>{'BU HR Head'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.relationshipData.BuhEname}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='esep-second mt-2'>
                                                                                <div className='column '>
                                                                                    <div className='p-sub-heading ml-3'>{'BHR Name'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.relationshipData.BhrEname}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='esep-third mt-2'>
                                                                                <div className='column '>
                                                                                    <div className='p-sub-heading ml-3'>{'FEM Manager'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.relationshipData.FemEname}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='esep-fourth mt-2'>
                                                                                <div className='column '>
                                                                                    <div className='p-sub-heading ml-3'>{'Unit HR Head'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.relationshipData.UuhEname}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    }
                                                    {this.state.isGroupThirdActive &&
                                                        <div className='document_card mt-3 p-3 mb-24px col-md-12 pointer'
                                                            onClick={() => this.toggleCard('leave')}>
                                                            {!this.state.openCard2 ?
                                                                <div className='d-flex justify-content-between align-item-center'>
                                                                    <div className='toggle-card-heading ml-2'>
                                                                        {'Leave Quota Overview'}
                                                                    </div>
                                                                    <div
                                                                        className='justify-content-end pointer'
                                                                        onClick={() => this.toggleCard('leave')}>
                                                                        <img
                                                                            alt="hide"
                                                                            src={down_arrow}
                                                                            className="up_arrow"
                                                                        ></img>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='d-flex justify-content-between align-item-center'>
                                                                    <div className='toggle-card-heading ml-2'>
                                                                        {'Leave Quota Overview'}
                                                                    </div>
                                                                    <div
                                                                        className='justify-content-end pointer'
                                                                        onClick={() => this.toggleCard('leave')}>
                                                                        <img
                                                                            alt="hide"
                                                                            src={up_arrow}
                                                                            className="up_arrow"
                                                                        ></img>
                                                                    </div>
                                                                </div>}
                                                            {this.state.openCard2 &&
                                                                <>
                                                                    <div className='h-37vh'>
                                                                        {this.state.leaveData.length !== 0 ?
                                                                            <div className='esep-grid'>
                                                                                {this.state.leaveData.map((listValue, index) => {
                                                                                    return (
                                                                                        <div>
                                                                                            <div className='p-heading mx-2 mt-2 mb-2'>{listValue.leave_category_text}</div>
                                                                                            <div className='esep-second mt-2'>
                                                                                                <div className='column mt-2'>
                                                                                                    <div className='p-sub-heading ml-3'>{'Total Leaves'}</div>
                                                                                                    <div className='p-placeholder-text'>
                                                                                                        <input
                                                                                                            className="form-control p-placeholder px-3"
                                                                                                            type="text"
                                                                                                            value={listValue.total_leaves}
                                                                                                            readOnly={true}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='esep-third mt-2'>
                                                                                                <div className='column mt-2'>
                                                                                                    <div className='p-sub-heading ml-3'>{'Available Leaves'}</div>
                                                                                                    <div className='p-placeholder-text'>
                                                                                                        <input
                                                                                                            className="form-control p-placeholder px-3"
                                                                                                            type="text"
                                                                                                            value={listValue.available_leaves}
                                                                                                            readOnly={true}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                            :
                                                                            <div className='col-md-12 justify-content-center align-item-center'>
                                                                                {'No Record Found'}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    }
                                                    {this.state.isGroupFourthActive &&
                                                        <div className='document_card mt-3 p-3 mb-24px col-md-12 pointer'
                                                            onClick={() => this.toggleCard('salary')}>
                                                            {!this.state.openCard3 ?
                                                                <div className='d-flex justify-content-between align-item-center'>
                                                                    <div className='toggle-card-heading ml-2'>
                                                                        {'Salary Details'}
                                                                    </div>
                                                                    <div
                                                                        className='justify-content-end pointer'
                                                                        onClick={() => this.toggleCard('salary')}>
                                                                        <img
                                                                            alt="hide"
                                                                            src={down_arrow}
                                                                            className="up_arrow"
                                                                        ></img>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='d-flex justify-content-between align-item-center'>
                                                                    <div className='toggle-card-heading ml-2'>
                                                                        {'Salary Details'}
                                                                    </div>
                                                                    <div
                                                                        className='justify-content-end pointer'
                                                                        onClick={() => this.toggleCard('salary')}>
                                                                        <img
                                                                            alt="hide"
                                                                            src={up_arrow}
                                                                            className="up_arrow"
                                                                        ></img>
                                                                    </div>
                                                                </div>}
                                                            {this.state.openCard3 &&
                                                                <>
                                                                    <div className='h-12vh'>
                                                                        <div className='esep-grid'>
                                                                            <div className='esep-first mt-2'>
                                                                                <div className='column '>
                                                                                    <div className='p-sub-heading ml-3'>{'TFP Monthly (INR)'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.salaryData.MonthlyTfp}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='esep-second mt-2'>
                                                                                <div className='column '>
                                                                                    <div className='p-sub-heading ml-3'>{'Basic Monthly (INR)'}</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={this.state.salaryData.MonthlyBasic}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </>
                                    }
                                    {/* --------------------Is Eseparation Request Present or Not key handeling------------------------- */}
                                    {this.state.isRequestActive &&
                                        <>
                                            {this.state.isEsepPresent ?
                                                <div>
                                                    <EseparationDashboard
                                                        isRequestActive={this.state.isRequestActive}
                                                        requestsData={this.state.requestsData}
                                                        getEsepRequestCall={this.getEsepRequest}
                                                        relationshipData={this.state.relationshipData}
                                                        toggleRequestsDetails={this.toggleRequestsDetails}
                                                        payRecoveryPopup={this.state.payRecoveryPopup}
                                                        toatalDaysCount={toatalDaysCount}
                                                        checked={this.state.checked}
                                                        radioFilters={this.state.radioFilters}
                                                        initiateEsepRequest={this.initiateEsepRequest}
                                                        isApplicable={this.state.isApplicable}
                                                    />
                                                </div>
                                                :
                                                <div>
                                                    <div className='esep-content mx-2 mt-2'>{NoEsepRequest}</div>
                                                    <div>
                                                        <div className='d-flex flex-row-reverse'>
                                                            <div
                                                                className='pointer row add-btn-text flex-row-reverse p-11px'
                                                                onClick={() => this.initiateEsepRequest()}>
                                                                {AddEsepRequest}
                                                            </div>
                                                        </div>
                                                        <div className='mt-3px'><hr /></div>
                                                    </div>

                                                    {/* ------------------Information Tables---------------- */}
                                                    <div className='scroll-y height-44vh mt-2'>
                                                        <div>
                                                            <h1 className='coi-text mx-4'>{GuidelineTitle}</h1>
                                                            <div className='ml-1 mt-3'>
                                                                <h2 className='coi-sub-text mx-4'>{GuidelineHeader}</h2>
                                                                <h2 className='coi-option-text mr-4'>{'Here are some quick guidelines for your reference –'}</h2>
                                                                <h2 className='coi-option-text mr-4'>{Point1}</h2>
                                                                <h2 className='coi-option-text mr-4'>{Point2}</h2>
                                                                <div className='coi-option-text mr-4'><span className='coi-option-text'>{'To: '}</span><span ><u className='coi-text'>{'DEM MANAGER,  FEM MANAGER'}</u></span></div>
                                                                <div className='coi-option-text mr-4'><span className='coi-option-text'>{'Cc: '}</span><span ><u className='coi-text'>{'BHR'}</u></span></div>
                                                                <div><span className='coi-option-text'>{Point3}</span><span className='coi-text'>{' (Kindly note, only intimation of separation is required on mail, acknowledgement/approval is optional).'}</span></div>
                                                                <div className='ml-2'><h2 className='coi-option-text italic-Font mr-4'>{SubPoint3}</h2></div>
                                                                <h2 className='coi-option-text mr-4'>{Point4}</h2>
                                                                <h2 className='mt-15'><span className='coi-option-text mr-1'>{'Refer table below for quick reference and'}</span><span><a href={NoticePeriodPolicy} target="_blank"><label className='tata-policy-link mr-4' type='button'>{'Click here to read Notice Period policy'}</label></a></span></h2>
                                                                <div className='d-flex justify-content-center align-item-center mt-3'>
                                                                    <div className='w-50rem'>
                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col"><div className='thead w-10rem'>{'Level'}</div></th>
                                                                                    <th scope="col"><div className='thead'>{'Employee Category'}</div></th>
                                                                                    <th scope="col"><div className='thead'>{'Notice Period Days'}</div></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td><div className="td d-flex">{'Level C'}</div></td>
                                                                                    <td> <div className="td d-flex">{'Permanent'}</div> </td>
                                                                                    <td> <div className="td d-flex">{'180'}</div> </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><div className="td d-flex">{'Level 1,2,3'}</div></td>
                                                                                    <td> <div className="td d-flex">{'Permanent'}</div> </td>
                                                                                    <td> <div className="td d-flex">{'90'}</div> </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><div className="td d-flex">{'Level 4,5,6'}</div></td>
                                                                                    <td> <div className="td d-flex">{'Permanent'}</div> </td>
                                                                                    <td> <div className="td d-flex">{'60'}</div> </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><div className="td d-flex">{'Level 5, 6'}</div></td>
                                                                                    <td> <div className="td d-flex">{'Probationers'}</div> </td>
                                                                                    <td> <div className="td d-flex">{'30'}</div> </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="td flex-direction-column">
                                                                                            <span className="td d-flex">{'Graduate Apprentice Trainee (GAT),'}</span>
                                                                                            <span className="td d-flex">{'Professional Fixed Term (PFT),'}</span>
                                                                                            <span className="td d-flex"> {'Technical Fixed Term (TFT),'}</span>
                                                                                            <span className="td d-flex"> {'SCIP Associate,'}</span>
                                                                                            <span className="td d-flex">{'FSE, All Other Fixed Term'}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td> <div className="td d-flex">{'Fixed term Contract'}</div> </td>
                                                                                    <td> <div className="td d-flex">{'30'}</div> </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <h2 className='coi-option-text mr-4 mt-2'>{Point5}</h2>
                                                                <h2 className='coi-option-text mr-4'>{Point6}</h2>
                                                                <h2 className='coi-option-text mr-4'>{Point7}</h2>
                                                                <h2 className='coi-option-text mr-4'>{Point8}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                    {this.state.isManagerApprovalActive &&
                                        <>
                                            <EseparationDashboard
                                                isManagerApprovalActive={this.state.isManagerApprovalActive}
                                                approvalsRequestData={this.state.approvalsRequestData}
                                                relationshipData={this.state.relationshipData}
                                                toggleManagerApprovalsDetails={this.toggleManagerApprovalsDetails}
                                                managerPopup={this.initiateEsepRequest}
                                                handlePageCount={this.handlePageCount}
                                                selectedPage={this.selectedPage}
                                                getDemEsepApprovals={this.getDemEsepApprovals}
                                                count={this.state.count}
                                                page={this.state.page}
                                                handleChange={this.handleChange}
                                                searchEmpIdFunction={this.searchEmpIdFunction}
                                            />
                                        </>
                                    }
                                    {this.state.isBHRApprovalActive &&
                                        <>
                                            <EseparationDashboard
                                                isBHRApprovalActive={this.state.isBHRApprovalActive}
                                                bhrEsepApprovalsData={this.state.bhrEsepApprovalsData}
                                                relationshipData={this.state.relationshipData}
                                                toggleBHRApprovalsDetails={this.toggleBHRApprovalsDetails}
                                                managerPopup={this.initiateEsepRequest}
                                                handlePageCount={this.handlePageCount}
                                                selectedPage={this.selectedPage}
                                                getBhrEsepApprovals={this.getBhrEsepApprovals}
                                                count={this.state.count}
                                                page={this.state.page}
                                                handleChange={this.handleChange}
                                                searchEmpIdFunction={this.searchEmpIdFunction}
                                            />
                                        </>
                                    }
                                    {this.state.isBUHRApprovalActive &&
                                        <>
                                            <EseparationDashboard
                                                isBUHRApprovalActive={this.state.isBUHRApprovalActive}
                                                buHREsepApprovalsData={this.state.buHREsepApprovalsData}
                                                relationshipData={this.state.relationshipData}
                                                toggleBUHRApprovalsDetails={this.toggleBUHRApprovalsDetails}
                                                managerPopup={this.initiateEsepRequest}
                                                handlePageCount={this.handlePageCount}
                                                selectedPage={this.selectedPage}
                                                getBuhrEsepApprovals={this.getBuhrEsepApprovals}
                                                count={this.state.count}
                                                page={this.state.page}
                                                handleChange={this.handleChange}
                                                searchEmpIdFunction={this.searchEmpIdFunction}
                                            />
                                        </>
                                    }
                                    {this.state.isClearanceActive &&
                                        <>
                                            <ClearanceRequestsScreen />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        {/*--------------------Add request and Notice pay recovery popups---------------------*/}
                        {isShow &&
                            <>
                                <div className="modal popup-box">
                                    <LoaderIndicator loading={this.state.loading} />
                                    <div className={this.state.payRecoveryPopup ? 'popupCard-box p-0 w-33' : 'popupCard-box p-0 w-58'}>
                                        <div className="modal-header">
                                            <h4 className="modal_title black">{this.state.payRecoveryPopup ? PayRecoveryTitle : AddFormTitle}</h4>
                                            <button type="button" className="close" data-dismiss="modal" onClick={this.state.payRecoveryPopup ? this.onClose : this.onDismiss}>&times;</button>
                                        </div>
                                        {this.state.payRecoveryPopup ?
                                            // <div className="modal-body h-50vh scroll-y">
                                            <div className="modal-body h-30vh scroll-y">
                                                <div className='d-flex profile-heading ml-4 mt-2'>{'Note: Notice Recovery Amount = ( Gap to notice period / 30 )* Monthly TFP'}</div>
                                                <div className='p-3'>
                                                    <div>
                                                        <div className='p-sub-heading ml-3 mt-3px'>{'Gap to notice period'}</div>
                                                        <div className='esep-placeholder-editable'>
                                                            <textarea
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={toatalDaysCount}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='mt-4'>
                                                        <div className='p-sub-heading ml-3'>{'Indicative notice period recovery (INR)'}</div>
                                                        <div className='esep-placeholder-editable'>
                                                            <textarea
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={finalCount}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* <div className='d-flex row justify-content-start mt-4'>
                                                        <div className='profile-heading ml-1'>{'How would you pay notice pay  the above indicative recovery amount?'}</div>
                                                        <div className='profile-heading ml-1 required mt-1'>{'Please select one of the below options'}</div>
                                                        {this.state.noticePayRecoveryOptions.length != 0 && this.state.noticePayRecoveryOptions.map((list, key) => {
                                                            return (
                                                                <div key={key} className='column ml-3 mt-1 mb-1'>
                                                                    {this.state.radioOption === key ?
                                                                        <div onClick={() => this.onPresentType(key, list.notice_pay_option, list.id)}>
                                                                            <img alt="radioOn" className='radio' src={radioOn} />
                                                                            <label className="radioTxt" >{list.notice_pay_option}</label>
                                                                        </div>
                                                                        :
                                                                        <div onClick={() => this.onPresentType(key, list.notice_pay_option, list.id)}>
                                                                            <img alt="radioOff" className='radio' src={radioOff} />
                                                                            <label className="radioTxt">{list.notice_pay_option}</label>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </div> */}

                                                </div>
                                            </div>
                                            :
                                            <div className="modal-body-padd h-52vh scroll-y">
                                            {/* // <div className="modal-body modal-dialog-centered modal-dialog-scrollable w-60rem"> */}
                                                <div className='addForm-grid-popup p-3'>
                                                    <div className='addFormFirst'>
                                                        <div>
                                                            <div className='p-sub-heading ml-3'>{'Resignation Request Date'}</div>
                                                            <div className='esep-placeholder-editable'>
                                                                <input
                                                                    className="form-control esep-placeholder-editable px-3"
                                                                    type="text"
                                                                    value={moment(this.state.priorDate).format("DD-MM-YYYY")}
                                                                    readOnly={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <div className='p-sub-heading ml-3'>{'Last Working Date As Per Policy'}</div>
                                                            <div className='esep-placeholder-editable'>
                                                                <input
                                                                    className="form-control esep-placeholder-editable px-3"
                                                                    type="text"
                                                                    value={moment(this.state.lastWorkingDate).format("DD-MM-YYYY")}
                                                                    readOnly={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <div className='p-sub-heading ml-3 required'>{'Personal Contact No'}</div>
                                                            <div className='esep-placeholder-editable'>
                                                                <input
                                                                    className="form-control esep-placeholder-editable px-3"
                                                                    type="text"
                                                                    maxLength="10"
                                                                    onChange={(e) => this.setState({ contactNo: e.target.value })}
                                                                    value={this.state.contactNo}
                                                                    placeholder={'Enter the contact no'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='addFormSecond ml-2'>
                                                        <div>
                                                            <div className='p-sub-heading ml-3 required'>{'Actual Date Of Resignation'}</div>
                                                            <div className='esep-placeholder-editable'>
                                                                <input
                                                                    className="form-control esep-placeholder-editable px-3"
                                                                    type="date"
                                                                    max={TodaysDates} //max props using for hiding the date current to future dates
                                                                    min={toDay}  //min props using for hiding the date current to prior dates
                                                                    onChange={(e) => this.handleChangeFunction(e)}
                                                                    onMouseOver={() => this.setState({ warnShow: true })}
                                                                    onMouseLeave={() => this.setState({ warnShow: false })}
                                                                />
                                                            </div>
                                                            {warnShow &&
                                                                <div className='d-flex row'>
                                                                    <div><img alt="redicon" src={redicon} className="iconStyle ml-1"></img></div>
                                                                    <div className="warningText mr-2">{ActualDateWarning}</div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className='mt-2'>
                                                            <div className='p-sub-heading ml-3 required'>{'Requested Last Working Day'}</div>
                                                            <div className='esep-placeholder-editable'>
                                                                <input
                                                                    className="form-control esep-placeholder-editable px-3"
                                                                    type="date"
                                                                    min={moment(this.state.resignationDate).format("YYYY-MM-DD")} //From date 
                                                                    max={moment(this.state.lastWorkingDate).format("YYYY-MM-DD")} //To date
                                                                    value={moment(this.state.requestedLastWorkingDate).format("YYYY-MM-DD")}
                                                                    onChange={(e) => this.handleChangeRequestedDate(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <div className='p-sub-heading ml-3 required'>{'Personal Email Id'}</div>
                                                            <div className='esep-placeholder-editable'>
                                                                <input
                                                                    className="form-control esep-placeholder-editable px-3"
                                                                    type="text"
                                                                    onChange={(e) => this.setState({ emailID: e.target.value })}
                                                                    value={this.state.emailID}
                                                                    placeholder={'Enter the email id.'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='addFormThird'>
                                                        <div className='mt-2'>
                                                            <div className='p-sub-heading ml-3 required'>{'Address'}</div>
                                                            <textarea
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                maxLength={200}
                                                                onChange={(e) => this.setState({ address: e.target.value })}
                                                                value={this.state.address}
                                                                placeholder={"Enter your address"}
                                                            >
                                                            </textarea>
                                                            <div className="justify-content-start time-text ml-3 mt-1">
                                                                {this.lengthCalculate(this.state.address)}/200
                                                            </div>
                                                        </div>
                                                        <div className='mt-1'>
                                                            <div className='p-sub-heading ml-3 required'>{'Reason For Resignation'}</div>
                                                            <div className='' >
                                                                <Dropdown
                                                                    data={this.state.employeeReasonData}
                                                                    selectedValue={(data) => this.changeResonsType(data)}
                                                                    value={this.state.reasonValue}
                                                                    render={'value'}
                                                                    height={'NotificationHeight'}
                                                                />
                                                            </div>
                                                        </div>
                                                        {isJustify &&
                                                            <div className='mt-2'>
                                                                <div className='p-sub-heading ml-3 required'>{'Justification For Early Release?'}</div>
                                                                <textarea
                                                                    className="form-control esep-placeholder-editable px-3"
                                                                    type="text"
                                                                    maxLength={500}
                                                                    onChange={(e) => this.setState({ justification: e.target.value })}
                                                                    value={this.state.justification}
                                                                    placeholder={"Enter the justification min 100 characters"}
                                                                >
                                                                </textarea>
                                                                <div className="justify-content-start time-text ml-3 mt-1">
                                                                    {this.lengthCalculate(this.state.justification)}/500
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='addFormFourth ml-2'>
                                                        <div className='mt-2'>
                                                            <div className='p-sub-heading ml-3 required'>{'Upload e-Separation Intimation'}</div>
                                                            <div className='esep-placeholder-editable'>
                                                                <input
                                                                    className="form-control esep-placeholder-editable px-3"
                                                                    type="file"
                                                                    style={{ padding: '7.4px' }}
                                                                    accept=".jpg, .jpeg, .png, .pdf, .msg"
                                                                    onChange={this.fileSelectHandler}
                                                                />
                                                                <div className="p-sub-note ml-10">{FileUploadSizeNote}</div>
                                                            </div>
                                                        </div>
                                                        <div className='mt-4'>
                                                            <div className='p-sub-heading ml-3 required'>{'Resignation Remarks'}</div>
                                                            <textarea
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                maxLength={500}
                                                                onChange={(e) => this.setState({ remarks: e.target.value })}
                                                                value={this.state.remarks}
                                                                placeholder={"Enter the remarks"}
                                                            >
                                                            </textarea>
                                                            <div className="justify-content-start time-text ml-3 mt-2">
                                                                {this.lengthCalculate(this.state.remarks)}/500
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-100 mb-10px">
                                                    <hr />
                                                </div>

                                                <div className="toggle-card-heading ml-10 mb-10">
                                                    {"Exit Interview Form"}
                                                </div>

                                                <div className='mt-4'>
                                                    <div className='p-sub-heading margin-left-30 required'>{eSepReason}</div>
                                                    <div className='row p-placeholder-text mt-1 ml-5'>
                                                        <textarea
                                                            className="form-control esep-placeholder-editable px-3 ml-3 w-95"
                                                            type="text"
                                                            maxLength={500}
                                                            onChange={(e) => this.setState({ reason_for_leaving: e.target.value })}
                                                            value={this.state.reason_for_leaving}
                                                            placeholder={"Enter your response"}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className="justify-content-start time-text ml-3 mt-2 pl-15px">
                                                        {this.lengthCalculate(this.state.reason_for_leaving)}/500
                                                    </div>
                                                </div>

                                                <div className='mt-4'>
                                                    <div className='p-sub-heading margin-left-30 required'>{elaborateReason}</div>
                                                    <div className='row p-placeholder-text mt-1 ml-5'>
                                                        <textarea
                                                            className="form-control esep-placeholder-editable px-3 ml-3 w-95"
                                                            type="text"
                                                            maxLength={500}
                                                            onChange={(e) => this.setState({ reason_for_leaving_elaborate: e.target.value })}
                                                            value={this.state.reason_for_leaving_elaborate}
                                                            placeholder={"Enter your response"}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className="justify-content-start time-text ml-3 mt-2 pl-15px">
                                                        {this.lengthCalculate(this.state.reason_for_leaving_elaborate)}/500
                                                    </div>
                                                </div>


                                                <div className='mt-4'>
                                                    <div className='p-sub-heading margin-left-30 required'>{partnerCompetitor}</div>
                                                    <div className='row p-placeholder-text mt-1 ml-5'>
                                                        <textarea
                                                            className="form-control esep-placeholder-editable px-3 ml-3 w-95"
                                                            type="text"
                                                            maxLength={500}
                                                            onChange={(e) => this.setState({ other_organization_info: e.target.value })}
                                                            value={this.state.other_organization_info}
                                                            placeholder={"Enter your response"}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className="justify-content-start time-text ml-3 mt-2 pl-15px">
                                                        {this.lengthCalculate(this.state.other_organization_info)}/500
                                                    </div>
                                                </div>


                                                <div className='mt-4'>
                                                    <div className='p-sub-heading margin-left-30 required'>{joinThisOrganization}</div>
                                                    <div className='row p-placeholder-text mt-1 ml-5'>
                                                        <textarea
                                                            className="form-control esep-placeholder-editable px-3 ml-3 w-95"
                                                            type="text"
                                                            maxLength={500}
                                                            onChange={(e) => this.setState({ reason_of_joining: e.target.value })}
                                                            value={this.state.reason_of_joining}
                                                            placeholder={"Enter your response"}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className="justify-content-start time-text ml-3 mt-2 pl-15px">
                                                        {this.lengthCalculate(this.state.reason_of_joining)}/500
                                                    </div>
                                                </div>


                                                <div className='mt-4'>
                                                    <div className='p-sub-heading margin-left-30 required'>{keyAchievementsAtCompany}</div>
                                                    <div className='row p-placeholder-text mt-1 ml-5'>
                                                        <textarea
                                                            className="form-control esep-placeholder-editable px-3 ml-3 w-95"
                                                            type="text"
                                                            maxLength={500}
                                                            onChange={(e) => this.setState({ main_role: e.target.value })}
                                                            value={this.state.main_role}
                                                            placeholder={"Enter your response"}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className="justify-content-start time-text ml-3 mt-2 pl-15px">
                                                        {this.lengthCalculate(this.state.main_role)}/500
                                                    </div>
                                                </div>


                                                <div className='mt-4'>
                                                    <div className='p-sub-heading  margin-left-30 required'>{highRegards}</div>
                                                    <div className='row p-placeholder-text mt-1 ml-5'>
                                                        <textarea
                                                            className="form-control esep-placeholder-editable px-3 ml-3 w-95"
                                                            type="text"
                                                            maxLength={500}
                                                            onChange={(e) => this.setState({ aspect_of_organization: e.target.value })}
                                                            value={this.state.aspect_of_organization}
                                                            placeholder={"Enter your response"}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className="justify-content-start time-text ml-3 mt-2 pl-15px">
                                                        {this.lengthCalculate(this.state.aspect_of_organization)}/500
                                                    </div>
                                                </div>


                                                <div className='mt-4'>
                                                    <div className='p-sub-heading  margin-left-30 required'>{challengingWorkAndSupport}</div>
                                                    <div className='row p-placeholder-text mt-1 ml-5'>
                                                        <textarea
                                                            className="form-control esep-placeholder-editable px-3 ml-3 w-95"
                                                            type="text"
                                                            maxLength={500}
                                                            onChange={(e) => this.setState({ relationship_with_superior: e.target.value })}
                                                            value={this.state.relationship_with_superior}
                                                            placeholder={"Enter your response"}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className="justify-content-start time-text ml-3 mt-2 pl-15px">
                                                        {this.lengthCalculate(this.state.relationship_with_superior)}/500
                                                    </div>
                                                </div>


                                                <div className='mt-4'>
                                                    <div className='p-sub-heading  margin-left-30 required'>{dissatisfactionDuringYourTenure}</div>
                                                    <div className='row p-placeholder-text mt-1 ml-5'>
                                                        <textarea
                                                            className="form-control esep-placeholder-editable px-3 ml-3 w-95"
                                                            type="text"
                                                            maxLength={500}
                                                            onChange={(e) => this.setState({ dissatisfaction_feature: e.target.value })}
                                                            value={this.state.dissatisfaction_feature}
                                                            placeholder={"Enter your response"}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className="justify-content-start time-text ml-3 mt-2 pl-15px">
                                                        {this.lengthCalculate(this.state.dissatisfaction_feature)}/500
                                                    </div>
                                                </div>


                                                <div className='mt-4'>
                                                    <div className='p-sub-heading  margin-left-30 required'>{retainYourServices}</div>
                                                    <div className='row p-placeholder-text mt-1 ml-5'>
                                                        <textarea
                                                            className="form-control esep-placeholder-editable px-3 ml-3 w-95"
                                                            type="text"
                                                            maxLength={500}
                                                            onChange={(e) => this.setState({ action_of_organization_to_retain_sevice: e.target.value })}
                                                            value={this.state.action_of_organization_to_retain_sevice}
                                                            placeholder={"Enter your response"}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className="justify-content-start time-text ml-3 mt-2 pl-15px">
                                                        {this.lengthCalculate(this.state.action_of_organization_to_retain_sevice)}/500
                                                    </div>
                                                </div>

                                                <div className='mt-4'>
                                                    <div className='p-sub-heading  margin-left-30 required'>{laterDateAndInDifferentCapacity}</div>
                                                    <div className='row p-placeholder-text mt-1 ml-5'>
                                                        <textarea
                                                            className="form-control esep-placeholder-editable px-3 ml-3 w-95"
                                                            type="text"
                                                            maxLength={500}
                                                            onChange={(e) => this.setState({ like_to_work_later: e.target.value })}
                                                            value={this.state.like_to_work_later}
                                                            placeholder={"Enter your response"}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className="justify-content-start time-text ml-3 mt-2 pl-15px">
                                                        {this.lengthCalculate(this.state.like_to_work_later)}/500
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className='modal-footer btnBox1'>
                                            {this.state.payRecoveryPopup ?
                                                <input type='button' onClick={() => this.onPayRecoveryPopupDismiss()} value='CANCEL' className='cancelBtn' /> :
                                                <input type='button' onClick={() => this.onDismiss()} value='CANCEL' className='cancelBtn' />}
                                            {this.state.payRecoveryPopup ?
                                                <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => this.addSeparationRequest()} /> :
                                                <input type='button' value='SUBMIT' className='ml-2 validateBtn' onClick={() => this.validateAddRequest()} />}
                                        </div>
                                    </div>
                                </div >
                            </>
                        }
                        {isShowInfo &&
                            <InitiateFormPopups
                                onDismiss={() => this.isShowInfo()}
                            />
                        }
                    </div>
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}

export default connect(mapStatesToProps)(ESeparationHome);