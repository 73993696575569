import React, { Component } from 'react';
import plus from '../../../../assets/drishti_images/back.svg';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import moment from 'moment';
import approve from '../../../../assets/drishti_images/manager/landing/approve.png';
import reject from '../../../../assets/drishti_images/manager/landing/reject.svg';
import ConfirmPopup from '../ConfirmPopup'
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import { ApprovalSkeleton } from '../../../shared/skeleton/Skeletons';
import NodataApproval from '../../../../assets/drishti_images/nodatafound/todolist-nodata.svg';
import ServiceUnavailable from '../../../shared/ServiceUnavailable';
import { Link } from 'react-router-dom';

const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
class Approval extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            loading: false,
            leaveApproval: [],
            leaveApprovalList: [],
            reporteeList:[],
            actionCode: '',
            actionValue: '',
            isApprove: false,
            isReject: false,
            isReporteeReportActive: false,
            isLeaveApproveActive: true,
            ispreviousYearLeaveActive: false,

            is_service_Unavailable: false,  // service unavailble from backend
            team_approval_Service_reason: '',
        }
    }

    componentDidMount() {
        this.checkService();
        this.getApprovalLeaveListingData(this.props.loginData?.userData?.Perno)
    }

    toggleLeaveApprove = () => {
        this.setState({ isLeaveApproveActive: true, isReporteeReportActive: false, isPreviousYearLeaveActive: false, leaveApprovalList: [],reporteeList:[] }, () => this.getApprovalLeaveListingData(this.props.loginData?.userData?.Perno))
    }

    togglePreviousLeaveApprove = () => {
        this.setState({ isLeaveApproveActive: false, isReporteeReportActive: false, isPreviousYearLeaveActive: true, leaveApprovalList: [],reporteeList:[] }, () => this.getApprovalLeaveData(this.props.loginData?.userData?.Perno))
    }

    toggleReporteeReport = () => {
        this.setState({ isLeaveApproveActive: false, isReporteeReportActive: true, isPreviousYearLeaveActive: false, leaveApprovalList: [],reporteeList:[] }, () => this.getReporteeAvailedLeavesData())
    }

    getReporteeAvailedLeavesData() {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData?.userData?.Perno,
            // emp_id: '00527503',
            comp_code: this.props.loginData?.userData?.CompCode,
        }
        fetchApiCall(Config.getReporteeAvailedLeavesData, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        // leaveApprovalList: res?.data?.data,
                        reporteeList: res?.data?.data
                    });
                } else {
                    this.setState({
                        loading: false,
                        leaveApprovalList: [],
                        reporteeList:[],
                    });
                }
            }).catch(err => {
                console.log('err--->', err);
                this.setState({
                    loading: false,
                    leaveApprovalList: [],
                    reporteeList:[],
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    getApprovalLeaveListingData(employeeId) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: employeeId,
            approval_type: 'Leave Request',
            leave_category_code: 'All',
            leave_status_code: '0',
            emp_id_value: 'All',
            filter_type: 'emp_id',
            approver_id: this.props.loginData.managerData.Perno,
            pa: this.props.loginData.userData.PersArea,
            psa: this.props.loginData.userData.PSubarea
        }
        fetchApiCall(Config.getLeaveApproval, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);

                if (res.data.status_code == 200) {
                    this.setState({
                        leaveApproval: res.data.data.leave_requests_approval ? res.data.data.leave_requests_approval : '',
                        loading: false
                    });
                    const tempApprovalList = this.state.leaveApproval.map(tempValue => ({ ...tempValue, isSelected: false, tempSelect: false }))
                    this.setState({
                        leaveApprovalList: tempApprovalList,
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    //--------Get previous year leave approval------------
    getApprovalLeaveData(employeeId) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: employeeId,
            approval_type: 'Leave Request',
            leave_category_code: 'All',
            leave_status_code: '0',
            emp_id_value: 'All',
            filter_type: 'emp_id',
            approver_id: this.props.loginData.managerData.Perno,
            pa: this.props.loginData.userData.PersArea,
            psa: this.props.loginData.userData.PSubarea,
            approver_emp_id: this.props.loginData.userData.Perno
        }
        fetchApiCall(Config.getPreviousYearLeaveRequest, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);

                if (res.data.status_code == 200) {
                    this.setState({
                        leaveApproval: res.data.data.leave_requests_approval ? res.data.data.leave_requests_approval : '',
                        loading: false
                    });
                    const tempApprovalList = this.state.leaveApproval.map(tempValue => ({ ...tempValue, isSelected: false, tempSelect: false }))
                    this.setState({
                        leaveApprovalList: tempApprovalList,
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    approveRequest = (action, list) => {
        this.setState({
            loading: true,
            isApprove: false,
            isReject: false
        });
        let approvingData = []
        let temp = {
            leave_request_id: list.leave_request_id,
            requestor_emp_id: list.requestor_emp_id
        }
        approvingData.push(temp)
        const body = {
            emp_id: this.props.loginData.userData.Perno,
            data: approvingData,
            action: action,
            approval_type: action === '0' ? 'Leave Request' : 'Delete Leave Request'
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        let apiType = '';
        if (action === '0') {
            apiType = Config.approveLeaveRequest
        } else {
            apiType = Config.deleteLeaveApproval
        }
        fetchApiCall(apiType, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({
                        loading: false,
                    });
                    this.getApprovalLeaveListingData(this.props.loginData.userData.Perno)
                }
                else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({
                        popupVisibility: false,
                        loading: false,
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
                this.setState({
                    loading: false
                });
            })
    }

    //--------Approve previous year leave approval------------
    approvePreviousRequest = (action, list) => {
        this.setState({
            loading: true,
            isApprove: false,
            isReject: false
        });
        let approvingData = []
        let temp = {
            leave_request_id: list.leave_request_id,
            requestor_emp_id: list.requestor_emp_id
        }
        approvingData.push(temp)
        const body = {
            emp_id: this.props.loginData.userData.Perno,
            data: approvingData,
            action: action,
            approval_type: action === '0' ? 'Leave Request' : 'Delete Leave Request',
            pa: this.props.loginData.userData.PersArea,
            psa: this.props.loginData.userData.PSubarea,
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        let apiType = '';
        if (action === '0') {
            apiType = Config.prevYearLeaveApprovalRequest
        } else {
            apiType = Config.prevYearLeaveApprovalRequest
        }
        fetchApiCall(apiType, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({
                        loading: false,
                    });
                    this.getApprovalLeaveData(this.props.loginData.userData.Perno)
                }
                else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({
                        popupVisibility: false,
                        loading: false,
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
                this.setState({
                    loading: false
                });
            })
    }

    toggleAction = (type, data) => {
        this.setState({ selectedData: data, actionValue: type })
        if (type === 'approve') {
            this.setState({ actionCode: '0', isApprove: true })
        }
        else {
            this.setState({ actionCode: '1', isReject: true })
        }
    }

    goToApproval = () => {
        window.location = (BASE_WEB_URL_DEV + 'approval');
    }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "manager_leave_approval" && item.is_active == false) {
                this.setState({
                    is_service_Unavailable: true,
                    team_approval_Service_reason: item.reason
                })
            }
        })
    }

    render() {
        const { isApprove, isReject, isPreviousYearLeaveActive, isReporteeReportActive, isLeaveApproveActive, leaveApprovalList, is_service_Unavailable,reporteeList } = this.state
                return (
            <div className={window.innerWidth <= 600 ? "card-new" : "card-new"}>
                <div className="row">
                    <div className="d-flex justify-content-between w-100 mr-4">
                        <div className='flex-direction-row mt-2'>
                            <div className={isLeaveApproveActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleLeaveApprove()}>{'Leave Approval'}</div>
                            {!this.props.is_previous_year_leave &&
                                <div className={isPreviousYearLeaveActive ? "tab-active" : "tab-inactive"} onClick={() => this.togglePreviousLeaveApprove()}>{'Previous Year Attd. Reg. Approval'}</div>
                            }
                            <div className={isReporteeReportActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleReporteeReport()}>{'Reportee Leave Quota'}</div>
                        </div>
                        <Link
                            to={isLeaveApproveActive ? { pathname: '/approval', state: { isLeaveApproveActive: true } } : isPreviousYearLeaveActive ? { pathname: '/approval', state: { isPreviousYearLeaveActive: true } } : { pathname: '/approval', state: { isReporteeReportActive: true, leaveApprovalList: reporteeList } }}>
                            <img alt="gotoAnnouncement" src={plus} className="righticon"></img>
                        </Link>
                    </div>
                </div>
                <div className='mt-3px ml-1'><hr /></div>
                {!isReporteeReportActive && this.state.is_service_Unavailable ?
                    <div className="col-md-12 main-scroll-table justify-content-center align-item-center">
                        <ServiceUnavailable reason={this.state.team_approval_Service_reason} />
                    </div>
                    :
                    <>
                        {this.state.loading ?
                            <>
                                <ApprovalSkeleton />
                            </>
                            :
                            <>
                                <div className='main-scroll-table p-2'>
                                    {/* {leaveApprovalList.length != 0 ? */}
                                    {(isReporteeReportActive ? reporteeList : leaveApprovalList).length != 0 ?
                                        <table className="table">
                                            <thead className=''>
                                                <tr>
                                                    <th className='table-header' style={{ width: '5.7%' }} scope="col"><div className='thead pr-0'>{'Sr.No'}</div></th>
                                                    <th className='table-header' style={{ width: '12%' }} scope="col"><div className='thead'>{'Employee Name'}</div></th>
                                                    <th className='table-header' style={{ width: '11%' }} scope="col"><div className='thead'>{(isLeaveApproveActive || isPreviousYearLeaveActive) ? 'Leave Category' : 'Employee Id'}</div></th>
                                                    <th className='table-header' style={{ width: '8%' }} scope="col"><div className='thead' >{(isLeaveApproveActive || isPreviousYearLeaveActive) ? 'Leave Type' : 'DOJ'}</div></th>
                                                    <th className='table-header' style={{ width: '9%' }} scope="col"><div className='thead' >{(isLeaveApproveActive || isPreviousYearLeaveActive) ? 'From' : 'SL+CL Quota'}</div></th>
                                                    <th className='table-header' style={{ width: '9%' }} scope="col"><div className='thead'>{(isLeaveApproveActive || isPreviousYearLeaveActive) ? 'To' : 'SC+CL Availed'} </div></th>
                                                    <th className='table-header' style={{ width: '8%' }} scope="col"><div className='thead'>{(isLeaveApproveActive || isPreviousYearLeaveActive) ? 'Status' : 'CH Quota'}</div></th>
                                                    <th className='table-header' style={{ width: '10%' }} scope="col"><div className='thead'>{(isLeaveApproveActive || isPreviousYearLeaveActive) ? 'Reason' : 'CH Availed'}</div></th>
                                                    <th className='table-header' style={{ width: '0%' }} scope="col"><div className='thead'>{(isLeaveApproveActive || isPreviousYearLeaveActive) ? 'Action' : 'PL Quota'}</div></th>
                                                    {isReporteeReportActive && <th className='table-header' style={{ width: '0%' }} scope="col"><div className='thead'>{'PL Availed'}</div></th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {leaveApprovalList.map((listValue, index) => { */}
                                                {(isReporteeReportActive ? reporteeList : leaveApprovalList).map((listValue, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td><div className="td table-data-font" style={{ width: 20 }}>{index + 1}</div></td>
                                                            <td><div className="td table-data-font" >{(isLeaveApproveActive || isPreviousYearLeaveActive) ? (listValue.requestor_name ? listValue.requestor_name : '-') : (listValue.employee_name ? listValue.employee_name : '-')}</div></td>
                                                            <td><div className="td table-data-font">{(isLeaveApproveActive || isPreviousYearLeaveActive) ? (listValue.leave_category ? listValue.leave_category : '-') : (listValue.employee_id ? listValue.employee_id : '-')}</div></td>
                                                            <td> <div className="td table-data-font">{(isLeaveApproveActive || isPreviousYearLeaveActive) ? (listValue.leave_type ? listValue.leave_type : '-') : (listValue.doj ? moment(listValue.doj).format("DD-MM-YYYY") : '-')}</div> </td>
                                                            <td><div className="td table-data-font" >{(isLeaveApproveActive || isPreviousYearLeaveActive) ? (moment(listValue.from_date ? listValue.from_date : null).format("DD-MM-YYYY")) : (listValue.sl_and_cl_quota ? listValue.sl_and_cl_quota : '-')}</div></td>
                                                            <td><div className="td table-data-font" >{(isLeaveApproveActive || isPreviousYearLeaveActive) ? (moment(listValue.to_date ? listValue.to_date : null).format("DD-MM-YYYY")) : (listValue.sl_and_cl_quota_availed ? listValue.sl_and_cl_quota_availed : '-')}</div></td>
                                                            {!isReporteeReportActive && <td><div className="td table-data-font" style={{ color: listValue.leave_status_color }}>{listValue.leave_status ? listValue.leave_status : '-'}</div></td>}
                                                            <td><div className="td table-data-font">{(isLeaveApproveActive || isPreviousYearLeaveActive) ? (listValue.reason ? listValue.reason : '-') : (listValue.ch_quota ? listValue.ch_quota : '-')}</div></td>
                                                            {isReporteeReportActive && <td><div className="td table-data-font">{(listValue.ch_quota_availed ? listValue.ch_quota_availed : '-')}</div></td>}
                                                            {isReporteeReportActive && <td><div className="td  -data-font">{(listValue.pl_quota ? listValue.pl_quota : '-')}</div></td>}
                                                            {isReporteeReportActive && <td><div className="td table-data-font">{(listValue.pl_quota_availed ? listValue.pl_quota_availed : '-')}</div></td>}
                                                            {!isReporteeReportActive && <td className='d-flex'>
                                                                <div onClick={() => this.toggleAction('approve', listValue)}>
                                                                    <img alt='approve' src={approve} style={{ width: '25px' }} />
                                                                </div>
                                                                <div onClick={() => this.toggleAction('reject', listValue)}>
                                                                    <img alt="reject" src={reject} style={{ width: '25px', marginLeft: '5px' }} />
                                                                </div>
                                                            </td>}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        :
                                        <div className='row'>
                                            <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                                <div>
                                                    <img src={NodataApproval} alt="Nodata" style={{ "height": "6.25rem" }} />
                                                </div>
                                                <div>
                                                    <label className='card-content-title grey-color'>You have taken care of all the approvals!</label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                {isReject &&
                                    <ConfirmPopup
                                        title={'Confirmation'}
                                        action={this.state.actionValue}
                                        content={'Kindly confirm to proceed with the leave rejection'}
                                        rejectData={isPreviousYearLeaveActive ? () => this.approvePreviousRequest(this.state.actionCode, this.state.selectedData) : () => this.approveRequest(this.state.actionCode, this.state.selectedData)}
                                        onDismiss={() => this.setState({ isReject: false })}
                                    />
                                }
                                {isApprove &&
                                    <ConfirmPopup
                                        title={'Confirmation'}
                                        action={this.state.actionValue}
                                        content={'Kindly confirm to proceed with the leave approval'}
                                        approveData={isPreviousYearLeaveActive ? () => this.approvePreviousRequest(this.state.actionCode, this.state.selectedData) : () => this.approveRequest(this.state.actionCode, this.state.selectedData)}
                                        onDismiss={() => this.setState({ isApprove: false })}
                                    />
                                }
                            </>
                        }
                    </>
                }
            </div>
        );
    }
}


const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}

export default connect(mapStatesToProps)(Approval);