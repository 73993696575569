import React, { Component } from 'react';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import moment from 'moment';
import '../Eseparation.css';
import '../../../adminScreens/screenEnableDisable/enabledisable.css';
import { connect } from 'react-redux';
import smallcircle from '../../../../../assets/drishti_images/transfer/smallcircle.svg';
import greencircle from '../../../../../assets/drishti_images/transfer/greencircle.svg';
import orangecircle from '../../../../../assets/drishti_images/transfer/orangecircle.svg';
import '../../../../ScreenType/employeeScreens/transfer/Transfer.css';
import infoicon from '../../../../../assets/drishti_images/infoicon.svg';
import {eSepReason, elaborateReason, partnerCompetitor, joinThisOrganization, keyAchievementsAtCompany, highRegards, challengingWorkAndSupport, dissatisfactionDuringYourTenure, retainYourServices, laterDateAndInDifferentCapacity } from '../EseparationContants';
export class EsepTrackerDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isDashboardActive: true,
            isEmployeeStatus: true,
            reason_for_leaving:'',
            reason_for_leaving_elaborate:'',
            other_organization_info:'',
            reason_of_joining:'',
            main_role:'',
            aspect_of_organization:'',
            relationship_with_superior:'',
            dissatisfaction_feature:'',
            action_of_organization_to_retain_sevice:'',
            like_to_work_later:''
        }
    }

    onDismiss = () => {
        this.setState({
            isDashboardActive: false
        })
    }

    lengthCalculate = (value) => {
        let length = value ? value.length : 0
        return length
    }

    render() {
        const { isErrorPostingActive, action, title, onDismiss, isManagerApprovalActive, isNoticePayWaveOffBhr, lwdJustificationDem, waveOffJustificationBhr, lwdRecommendedByBhr, waveOffJustificationDem, noticePayRecoveryAmount, isEsepApprovalActive, noticeSeriodShortfallDays, lwdJustificationBhr, lwdRecommendedByDem, isNoticePayWaveOffDem, lwdJustificationEmployee, separationStatus, noticePeriodDays, lwdAsPerPolicy, createdBy, employeeSeparationReason, actualResignationDate, employeeName, employeeSeparationRemark, lwdRequestedByEmployee, resignationRequestDate, separationRequestId, demApprovalRemark, buhrApprovalRemark, bhrApprovalRemark, demRejectionReason, demRejectionRemark, bhrRejectionReason, bhrRejectionRemark,approvalRequestData,exitFormDetails } = this.props
        return (
            <div>
                {this.state.isDashboardActive &&
                    <div className="modal popup-box">
                        <LoaderIndicator loading={this.state.loading} />
                        <div className='popupCard-box p-0 w-79'>
                            {/* -------------------------Model header---------------------------- */}
                            <div className="modal-header">
                                <h4 className="modal_title black">{title}</h4>
                                {action === 'viewEsepRequest' ?
                                    <button type="button" className="close" data-dismiss="modal" onClick={onDismiss}>&times;</button> :
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.onDismiss}>&times;</button>}
                            </div>
                            {/* -------------------------Model body---------------------------- */}
                            <>
                                <div className="modal-body occasion-bg-color scroll-auto h-68vh">
                                    <div className='row mt-3 px-3 ss col-md-12'>
                                        {/* ---------------------Tracking header timeline------------------------ */}
                                        <div className='row ml-m10px p-1'>
                                            <div className='d-flex justify-content-center align-item-start mt-1'>
                                                <div className='flex-direction-column'>
                                                    <div className='ml-2'>
                                                        {this.state.isEmployeeStatus ?
                                                            <img alt="smallcircle" src={greencircle} className='mr-3' /> :
                                                            <img alt="greencircle" src={smallcircle} className='mr-3' />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='ml-m8px'>
                                                    <label type='button' className='mt-2px'>{'Employee Request'}</label>
                                                </div>
                                            </div>

                                            <div data-tooltip2={employeeName ? employeeName : 'Data not maintained'} className="infoIconLabel infoIconStyle ml-1" >
                                                <img alt="info"
                                                    src={infoicon}
                                                    className="infosize"
                                                ></img>
                                            </div>

                                            <div className='trackerTimeline'><hr /></div>

                                            <div className='d-flex justify-content-center align-item-start mt-1'>
                                                <div className='flex-direction-column'>
                                                    <div className='ml-2'>
                                                        {separationStatus !== 'Pending' && separationStatus !== 'Rejected by DEM' ?
                                                            <img alt="greencircle" src={greencircle} className='mr-3' />
                                                            :
                                                            separationStatus === 'Rejected by DEM' ?
                                                                <img alt="orangecircle" src={orangecircle} className='mr-3' />
                                                                :
                                                                <img alt="greencircle" src={smallcircle} className='mr-3' />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='ml-m8px'>
                                                    <label type='button' className='mt-2px'>{'Manager Approval'}</label>
                                                </div>
                                            </div>

                                            <div data-tooltip2={this.props.demName ? this.props.demName : 'Data not maintained'} className="infoIconLabel infoIconStyle ml-1" >
                                                <img alt="info"
                                                    src={infoicon}
                                                    className="infosize"
                                                ></img>
                                            </div>

                                            <div className='trackerTimeline'><hr /></div>

                                            <div className='d-flex justify-content-center align-item-start mt-1'>
                                                <div className='flex-direction-column'>
                                                    <div className='ml-2'>
                                                        {(separationStatus === 'Approved by BHR and Updated') || separationStatus === 'Approved by BUHR' || separationStatus === 'Rejected by BUHR' || separationStatus === 'Revoked by Admin' ?
                                                            <img alt="smallcircle" src={greencircle} className='mr-3' />
                                                            :
                                                            separationStatus === 'Rejected by BHR' ?
                                                                <img alt="orangecircle" src={orangecircle} className='mr-3' />
                                                                :
                                                                <img alt="greencircle" src={smallcircle} className='mr-3' />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='ml-m8px'>
                                                    <label type='button' className='mt-2px'>{'BHR Approval'}</label>
                                                </div>
                                            </div>

                                            <div data-tooltip2={this.props.bhrName ? this.props.bhrName : 'Data not maintained'} className="infoIconLabel infoIconStyle ml-1" >
                                                <img alt="info"
                                                    src={infoicon}
                                                    className="infosize"
                                                ></img>
                                            </div>

                                            {(isNoticePayWaveOffBhr && separationStatus === 'Approved by BHR and Updated') || isNoticePayWaveOffBhr && separationStatus === 'Approved by BUHR' || isNoticePayWaveOffBhr && separationStatus === 'Rejected by BUHR' ?
                                                <>
                                                    <div className='trackerTimeline'><hr /></div>

                                                    <div className='d-flex justify-content-center align-item-start mt-1'>
                                                        <div className='flex-direction-column'>
                                                            <div className='ml-2'>
                                                                {separationStatus === 'Approved by BUHR' ?
                                                                    <img alt="smallcircle" src={greencircle} className='mr-3' />
                                                                    :
                                                                    separationStatus === 'Rejected by BUHR' ?
                                                                        <img alt="orangecircle" src={orangecircle} className='mr-3' />
                                                                        :
                                                                        <img alt="greencircle" src={smallcircle} className='mr-3' />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='ml-m8px'>
                                                            <label type='button' className='mt-2px'>{'BU HR Approval'}</label>
                                                        </div>
                                                    </div>

                                                    <div data-tooltip2={this.props.buhrName ? this.props.buhrName : 'Data not maintained'} className="infoIconLabel infoIconStyle ml-1" >
                                                        <img alt="info"
                                                            src={infoicon}
                                                            className="infosize"
                                                        ></img>
                                                    </div>
                                                </> : null}
                                        </div>

                                        {/* ---------------------Tracking approval card details------------------------ */}
                                        <div>
                                            <div className='document_card dashboardTrackingCard'>
                                                <div className='p-3 scroll-y h-53vh m-5px'>
                                                    <>
                                                        <div className='document-innercard-content-title mx-2 mt-2 ml-3'>{'Employee Details'}</div>
                                                        <div className='row mb-2 mt-1'>
                                                            <div className='col-md-4 col-sm-6 col-12'>
                                                                <div className='p-sub-heading ml-3'>{'Esep Request ID'}</div>
                                                                <div className='esep-placeholder-editable'>
                                                                    <input
                                                                        className="form-control esep-placeholder-editable px-3"
                                                                        type="text"
                                                                        value={isErrorPostingActive ? this.props.employeeRequestData.separation_request_id ? this.props.employeeRequestData.separation_request_id : '-' : separationRequestId ? separationRequestId : '-'}
                                                                        readOnly={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4 col-sm-6 col-12'>
                                                                <div className='p-sub-heading ml-3'>{'Employee ID'}</div>
                                                                <div className='esep-placeholder-editable'>
                                                                    <input
                                                                        className="form-control esep-placeholder-editable px-3"
                                                                        type="text"
                                                                        value={isErrorPostingActive ? this.props.employeeRequestData.employee_id ? this.props.employeeRequestData.employee_id : '-' : createdBy ? createdBy : '-'}
                                                                        readOnly={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4 col-sm-6 col-12'>
                                                                <div className='p-sub-heading ml-3'>{'Employee Name'}</div>
                                                                <div className='esep-placeholder-editable'>
                                                                    <input
                                                                        className="form-control esep-placeholder-editable px-3"
                                                                        type="text"
                                                                        value={isErrorPostingActive ? this.props.employeeRequestData.employee_name ? this.props.employeeRequestData.employee_name : '-' : employeeName ? employeeName : '-'}
                                                                        readOnly={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                <div className='p-sub-heading ml-3'>{'Esep Request Date'}</div>
                                                                <div className='esep-placeholder-editable'>
                                                                    <input
                                                                        className="form-control esep-placeholder-editable px-3"
                                                                        type="text"
                                                                        value={isErrorPostingActive ? moment(this.props.employeeRequestData.resignation_request_date).format("DD-MM-YYYY") ? moment(this.props.employeeRequestData.resignation_request_date).format("DD-MM-YYYY") : '-' : moment(resignationRequestDate ? resignationRequestDate : '-').format("DD-MM-YYYY")}
                                                                        readOnly={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                <div className='p-sub-heading ml-3'>{'Actual Date of Resignation'}</div>
                                                                <div className='esep-placeholder-editable'>
                                                                    <input
                                                                        className="form-control esep-placeholder-editable px-3"
                                                                        type="text"
                                                                        value={isErrorPostingActive ? moment(this.props.employeeRequestData.actual_resignation_date).format("DD-MM-YYYY") ? moment(this.props.employeeRequestData.actual_resignation_date).format("DD-MM-YYYY") : '-' : moment(actualResignationDate ? actualResignationDate : '-').format("DD-MM-YYYY")}
                                                                        readOnly={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                <div className='p-sub-heading ml-3'>{'Last Working Date as Per Policy'}</div>
                                                                <div className='esep-placeholder-editable'>
                                                                    <input
                                                                        className="form-control esep-placeholder-editable px-3"
                                                                        type="text" value={isErrorPostingActive ? moment(this.props.employeeRequestData.lwd_as_per_policy).format("DD-MM-YYYY") ? moment(this.props.employeeRequestData.lwd_as_per_policy).format("DD-MM-YYYY") : '-' : moment(lwdAsPerPolicy ? lwdAsPerPolicy : '-').format("DD-MM-YYYY")}
                                                                        readOnly={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                <div className='p-sub-heading ml-3'>{'LWD Requested by Employee'}</div>
                                                                <div className='esep-placeholder-editable'>
                                                                    <input
                                                                        className="form-control esep-placeholder-editable px-3"
                                                                        type="text"
                                                                        value={isErrorPostingActive ? moment(this.props.employeeRequestData.lwd_requested_by_employee).format("DD-MM-YYYY") ? moment(this.props.employeeRequestData.lwd_requested_by_employee).format("DD-MM-YYYY") : '-' : moment(lwdRequestedByEmployee ? lwdRequestedByEmployee : '-').format("DD-MM-YYYY")}
                                                                        readOnly={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                <div className='p-sub-heading ml-3 purple-color font-weight-bold'>{'Notice Period Days'}</div>
                                                                <div className='esep-placeholder-editable'>
                                                                    <input
                                                                        className="form-control esep-placeholder-editable px-3"
                                                                        type="text"
                                                                        value={isErrorPostingActive ? this.props.employeeRequestData.notice_period_days ? this.props.employeeRequestData.notice_period_days : '-' : noticePeriodDays ? noticePeriodDays : '-'}
                                                                        readOnly={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {(!isEsepApprovalActive || !isManagerApprovalActive) &&
                                                                <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                    <div className='p-sub-heading ml-3'>{'Employee Resignation Reason'}</div>
                                                                    <div className='esep-placeholder-editable'>
                                                                        <input
                                                                            className="form-control esep-placeholder-editable px-3"
                                                                            type="text"
                                                                            value={isErrorPostingActive ? this.props.employeeRequestData.employee_separation_reason ? this.props.employeeRequestData.employee_separation_reason : '-' : employeeSeparationReason ? employeeSeparationReason : '-'}
                                                                            readOnly={true}
                                                                        />
                                                                    </div>
                                                                </div>}
                                                            {employeeSeparationRemark === null ? null :
                                                                <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                                    <div className='p-sub-heading ml-3'>{'Employee Resignation Remarks'}</div>
                                                                    <div className='esep-placeholder-editable'>
                                                                        <textarea
                                                                            className="form-control esep-placeholder-editable px-3"
                                                                            type="text"
                                                                            value={isErrorPostingActive ? this.props.employeeRequestData.employee_separation_remark ? this.props.employeeRequestData.employee_separation_remark : '-' : employeeSeparationRemark ? employeeSeparationRemark : '-'}
                                                                            readOnly={true}
                                                                        />
                                                                    </div>
                                                                </div>}
                                                            {lwdJustificationEmployee === null ?
                                                                null :
                                                                <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                                    <div className='p-sub-heading ml-3'>{'Employee Early Release Justification'}</div>
                                                                    <div className='esep-placeholder-editable'>
                                                                        <textarea
                                                                            className="form-control esep-placeholder-editable px-3"
                                                                            type="text"
                                                                            value={isErrorPostingActive ? this.props.employeeRequestData.lwd_justification_employee ? this.props.employeeRequestData.lwd_justification_employee : '-' : lwdJustificationEmployee ? lwdJustificationEmployee : '-'}
                                                                            readOnly={true}
                                                                        />
                                                                    </div>
                                                                </div>}
                                                            {noticeSeriodShortfallDays === 0 ?
                                                                null :
                                                                <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                    <div className='p-sub-heading ml-3 purple-color font-weight-bold'>{'Notice Period Shortfall Days'}</div>
                                                                    <div className='esep-placeholder-editable'>
                                                                        <input
                                                                            className="form-control esep-placeholder-editable px-3"
                                                                            type="text"
                                                                            value={isErrorPostingActive ? this.props.employeeRequestData.notice_period_shortfall_days ? this.props.employeeRequestData.notice_period_shortfall_days : '-' : noticeSeriodShortfallDays ? noticeSeriodShortfallDays : '-'}
                                                                            readOnly={true}
                                                                        />
                                                                    </div>
                                                                </div>}
                                                            {noticePayRecoveryAmount === 0.0 ? null :
                                                                <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                    <div className='row d-flex align-items-end'>
                                                                        <div className='p-sub-heading ml-3 purple-color font-weight-bold'>{'Notice Pay Recovery Amount (INR)'}</div>

                                                                        <div data-tooltip2={'Note: Notice Recovery Amount =  ( Gap to notice period / 30 )* Monthly TFP'} className="infoSalary infoIconStyle ml-1" >
                                                                            <img alt="info"
                                                                                src={infoicon}
                                                                                className="infosize"
                                                                            ></img>
                                                                        </div>

                                                                    </div>
                                                                    <div className='esep-placeholder-editable'>
                                                                        <input
                                                                            className="form-control esep-placeholder-editable px-3"
                                                                            type="text"
                                                                            value={
                                                                                isErrorPostingActive 
                                                                                    ? (this.props.employeeRequestData?.notice_pay_recovery_amount ?? '-') 
                                                                                    : (noticePayRecoveryAmount ?? '-')
                                                                            }
                                                                            readOnly={true}
                                                                        />
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    
                                                    {
                                                        Object.keys(exitFormDetails).length !== 0  ? 
                                                        <>
                                                            <hr />
                                                            <div className='document-innercard-content-title mx-2 mt-4 ml-3'>{'Exit Interview Form'}</div>
                                                            <div className='mt-2'>
                                                                <div className='p-sub-heading margin-left-30 required'>{eSepReason}</div>
                                                                <div className='row p-placeholder-text mt-1 ml-5'>
                                                                    <textarea
                                                                        className="form-control px-3 ml-3 w-95"
                                                                        type="text"
                                                                        rows="3"
                                                                        id="comment"
                                                                        maxLength={1500}
                                                                        value={exitFormDetails?.reason_for_leaving ?? '-'}
                                                                        readOnly={true}                                                                        
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </div>

                                                            <div className='mt-4'>
                                                                <div className='p-sub-heading margin-left-30 required'>{elaborateReason}</div>
                                                                <div className='row p-placeholder-text mt-1 ml-5'>
                                                                    <textarea
                                                                        className="form-control px-3 ml-3 w-95"
                                                                        rows="3"
                                                                        id="comment"
                                                                        value={exitFormDetails?.reason_for_leaving_elaborate ?? '-'}
                                                                        maxLength={1500}
                                                                        readOnly={true} >
                                                                    </textarea>
                                                                </div>
                                                            </div>

                                                            <div className='mt-4'>
                                                                <div className='p-sub-heading margin-left-30 required'>{partnerCompetitor}</div>
                                                                <div className='row p-placeholder-text mt-1 ml-5'>
                                                                    <textarea
                                                                        className="form-control px-3 ml-3 w-95"
                                                                        type="text"
                                                                        rows="3"
                                                                        id="comment"
                                                                        maxLength={1500}
                                                                        value={exitFormDetails?.other_organization_info ?? '-'}
                                                                        readOnly={true} 
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </div>

                                                            <div className='mt-4'>
                                                                <div className='p-sub-heading margin-left-30 required'>{joinThisOrganization}</div>
                                                                <div className='row p-placeholder-text mt-1 ml-5'>
                                                                    <textarea
                                                                        className="form-control px-3 ml-3 w-95"
                                                                        type="text"
                                                                        rows="3"
                                                                        id="comment"
                                                                        maxLength={1500}
                                                                        value={exitFormDetails?.reason_of_joining ?? '-'}
                                                                        readOnly={true} 
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </div>

                                                            <div className='mt-4'>
                                                                <div className='p-sub-heading margin-left-30 required'>{keyAchievementsAtCompany}</div>
                                                                <div className='row p-placeholder-text mt-1 ml-5'>
                                                                    <textarea
                                                                        className="form-control px-3 ml-3 w-95"
                                                                        type="text"
                                                                        rows="3"
                                                                        id="comment"
                                                                        maxLength={1500}
                                                                        value={exitFormDetails?.main_role ?? '-'}
                                                                        readOnly={true} 
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </div>

                                                            <div className='mt-4'>
                                                                <div className='p-sub-heading  margin-left-30 required'>{highRegards}</div>
                                                                <div className='row p-placeholder-text mt-1 ml-5'>
                                                                    <textarea
                                                                        className="form-control px-3 ml-3 w-95"
                                                                        type="text"
                                                                        rows="3"
                                                                        id="comment"
                                                                        maxLength={1500}
                                                                        value={exitFormDetails?.aspect_of_organization ?? '-'}
                                                                        readOnly={true} 
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </div>

                                                            <div className='mt-4'>
                                                                <div className='p-sub-heading  margin-left-30 required'>{challengingWorkAndSupport}</div>
                                                                <div className='row p-placeholder-text mt-1 ml-5'>
                                                                    <textarea
                                                                        className="form-control px-3 ml-3 w-95"
                                                                        type="text"
                                                                        rows="3"
                                                                        id="comment"
                                                                        maxLength={1500}
                                                                        value={exitFormDetails?.relationship_with_superior ?? '-'}
                                                                        readOnly={true}
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </div>

                                                            <div className='mt-4'>
                                                                <div className='p-sub-heading  margin-left-30 required'>{dissatisfactionDuringYourTenure}</div>
                                                                <div className='row p-placeholder-text mt-1 ml-5'>
                                                                    <textarea
                                                                        className="form-control px-3 ml-3 w-95"
                                                                        type="text"
                                                                        rows="3"
                                                                        id="comment"
                                                                        maxLength={1500}
                                                                        value={exitFormDetails?.dissatisfaction_feature ?? '-'}
                                                                        readOnly={true}
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </div>

                                                            <div className='mt-4'>
                                                                <div className='p-sub-heading  margin-left-30 required'>{retainYourServices}</div>
                                                                <div className='row p-placeholder-text mt-1 ml-5'>
                                                                    <textarea
                                                                       className="form-control px-3 ml-3 w-95"
                                                                       type="text"
                                                                       rows="3"
                                                                       id="comment"
                                                                       maxLength={1500}
                                                                        value={exitFormDetails?.action_of_organization_to_retain_sevice ?? '-'}
                                                                        readOnly={true}
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </div>

                                                            <div className='mt-4'>
                                                                <div className='p-sub-heading  margin-left-30 required'>{laterDateAndInDifferentCapacity}</div>
                                                                <div className='row p-placeholder-text mt-1 ml-5'>
                                                                    <textarea
                                                                        className="form-control px-3 ml-3 w-95"
                                                                        type="text"
                                                                        rows="3"
                                                                        id="comment"
                                                                        maxLength={1500}
                                                                        value={exitFormDetails?.like_to_work_later ?? '-'}
                                                                        readOnly={true}
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </div>
                                                        </> : null
                                                    }

                                                   
                                                    </>
                                                    {separationStatus === 'Approved by DEM' || (separationStatus === 'Approved by BHR and Updated' || separationStatus === 'Approved by BHR and Error in Posting' || separationStatus === 'Approved by BHR and Update in Progress') || separationStatus === 'Approved by BUHR' || separationStatus === 'Rejected by DEM' || separationStatus === 'Rejected by BHR' || separationStatus === 'Rejected by BUHR' ?
                                                        <>
                                                            <div className='document-innercard-content-title mx-2 mt-2 ml-3'>{'Manager Approvals Details'}</div>
                                                            <div className='row mb-2'>
                                                                {lwdRecommendedByDem === null ? null :
                                                                    <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                        <div className='p-sub-heading ml-3'>{'LWD Recommended By Manager'}</div>
                                                                        <div className='esep-placeholder-editable'>
                                                                            <input
                                                                                className="form-control esep-placeholder-editable px-3"
                                                                                type="text"
                                                                                value={isErrorPostingActive ? moment(this.props.employeeRequestData.lwd_recommended_by_dem).format("DD-MM-YYYY") ? moment(this.props.employeeRequestData.lwd_recommended_by_dem).format("DD-MM-YYYY") : '-' : moment(lwdRecommendedByDem ? lwdRecommendedByDem : '-').format("DD-MM-YYYY")}
                                                                                readOnly={true}
                                                                            />
                                                                        </div>
                                                                    </div>}
                                                                {lwdJustificationDem === null ? null :
                                                                    <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                                        <div className='p-sub-heading ml-3'>{'LWD Justification By Manager'}</div>
                                                                        <div className='esep-placeholder-editable'>
                                                                            <textarea
                                                                                className="form-control esep-placeholder-editable px-3"
                                                                                type="text"
                                                                                value={isErrorPostingActive ? this.props.employeeRequestData.lwd_justification_dem ? this.props.employeeRequestData.lwd_justification_dem : '-' : lwdJustificationDem ? lwdJustificationDem : '-'}
                                                                                readOnly={true}
                                                                            />
                                                                        </div>
                                                                    </div>}
                                                                {demApprovalRemark === null ? null :
                                                                    <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                                        <div className='p-sub-heading ml-3'>{'Manager Approval Remarks'}</div>
                                                                        <div className='esep-placeholder-editable'>
                                                                            <textarea
                                                                                className="form-control esep-placeholder-editable px-3"
                                                                                type="text"
                                                                                value={isErrorPostingActive ? this.props.employeeRequestData.dem_approval_remark ? this.props.employeeRequestData.dem_approval_remark : '-' : demApprovalRemark ? demApprovalRemark : '-'}
                                                                                readOnly={true}
                                                                            />
                                                                        </div>
                                                                    </div>}
                                                                    {demRejectionReason === null ? null :
                                                                    <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                                        <div className='p-sub-heading ml-3'>{'Manager Rejection Reason'}</div>
                                                                        <div className='esep-placeholder-editable'>
                                                                            <textarea
                                                                                className="form-control esep-placeholder-editable px-3"
                                                                                type="text"
                                                                                value={isErrorPostingActive ? this.props.employeeRequestData.dem_rejection_reason ? this.props.employeeRequestData.dem_rejection_reason : '-' : demRejectionReason ? demRejectionReason : '-'}
                                                                                readOnly={true}
                                                                            />
                                                                        </div>
                                                                    </div>}
                                                                    {demRejectionRemark === null ? null :
                                                                    <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                                        <div className='p-sub-heading ml-3'>{'Manager Rejection Remarks'}</div>
                                                                        <div className='esep-placeholder-editable'>
                                                                            <textarea
                                                                                className="form-control esep-placeholder-editable px-3"
                                                                                type="text"
                                                                                value={isErrorPostingActive ? this.props.employeeRequestData.dem_rejection_remark ? this.props.employeeRequestData.dem_rejection_remark : '-' : demRejectionRemark ? demRejectionRemark : '-'}
                                                                                readOnly={true}
                                                                            />
                                                                        </div>
                                                                    </div>}
                                                                {lwdJustificationDem === null ?
                                                                    null :
                                                                    <>
                                                                        {lwdJustificationDem !== null && isNoticePayWaveOffDem === false ?
                                                                            <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                                <div className='p-sub-heading ml-3 purple-color font-weight-bold'>{'Notice Pay Recovery waive off by Manager'}</div>
                                                                                <div className='esep-placeholder-editable'>
                                                                                    <input
                                                                                        className="form-control esep-placeholder-editable px-3"
                                                                                        type="text"
                                                                                        value={'No'}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                                <div className='p-sub-heading ml-3 purple-color font-weight-bold'>{'Notice Pay Recovery waive off by Manager'}</div>
                                                                                <div className='esep-placeholder-editable'>
                                                                                    <input
                                                                                        className="form-control esep-placeholder-editable px-3"
                                                                                        type="text"
                                                                                        value={'Yes'}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                                {isNoticePayWaveOffDem === true ?
                                                                    <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                                        <div className='p-sub-heading ml-3'>{'Notice Pay waive off Justification By Manager'}</div>
                                                                        <div className='esep-placeholder-editable'>
                                                                            <textarea
                                                                                className="form-control esep-placeholder-editable px-3"
                                                                                type="text"
                                                                                value={isErrorPostingActive ? this.props.employeeRequestData.wave_off_justification_dem ? this.props.employeeRequestData.wave_off_justification_dem : '-' : waveOffJustificationDem ? waveOffJustificationDem : '-'}
                                                                                readOnly={true}
                                                                            />
                                                                        </div>
                                                                    </div> : null}
                                                            </div>
                                                            <hr />
                                                        </> : null}
                                                    {(separationStatus === 'Approved by BHR and Updated' || separationStatus === 'Approved by BHR and Error in Posting' || separationStatus === 'Approved by BHR and Update in Progress') || separationStatus === 'Approved by BUHR' || separationStatus === 'Rejected by BHR' || separationStatus === 'Rejected by BUHR' ?
                                                        <>
                                                            <div className='document-innercard-content-title mx-2 mt-2 ml-3'>{'BHR Approvals Details'}</div>
                                                            <div className='row mb-2'>
                                                                {lwdRecommendedByBhr === null ? null :
                                                                    <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                        <div className='p-sub-heading ml-3'>{'LWD Recommended By BHR'}</div>
                                                                        <div className='esep-placeholder-editable'>
                                                                            <input
                                                                                className="form-control esep-placeholder-editable px-3"
                                                                                type="text"
                                                                                value={isErrorPostingActive ? moment(this.props.employeeRequestData.lwd_recommended_by_bhr).format("DD-MM-YYYY") ? moment(this.props.employeeRequestData.lwd_recommended_by_bhr).format("DD-MM-YYYY") : '-' : moment(lwdRecommendedByBhr ? lwdRecommendedByBhr : '-').format("DD-MM-YYYY")}
                                                                                readOnly={true}
                                                                            />
                                                                        </div>
                                                                    </div>}
                                                                {lwdJustificationBhr === null ? null :
                                                                    <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                                        <div className='p-sub-heading ml-3'>{'LWD Justification By BHR'}</div>
                                                                        <div className='esep-placeholder-editable'>
                                                                            <textarea
                                                                                className="form-control esep-placeholder-editable px-3"
                                                                                type="text"
                                                                                value={isErrorPostingActive ? this.props.employeeRequestData.lwd_justification_bhr ? this.props.employeeRequestData.lwd_justification_bhr : '-' : lwdJustificationBhr ? lwdJustificationBhr : '-'}
                                                                                readOnly={true}
                                                                            />
                                                                        </div>
                                                                    </div>}
                                                                {bhrApprovalRemark === null ? null :
                                                                    <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                                        <div className='p-sub-heading ml-3'>{'BHR Approval Remarks'}</div>
                                                                        <div className='esep-placeholder-editable'>
                                                                            <textarea
                                                                                className="form-control esep-placeholder-editable px-3"
                                                                                type="text"
                                                                                value={isErrorPostingActive ? this.props.employeeRequestData.bhr_approval_remark ? this.props.employeeRequestData.bhr_approval_remark : '-' : bhrApprovalRemark ? bhrApprovalRemark : '-'}
                                                                                readOnly={true}
                                                                            />
                                                                        </div>
                                                                    </div>}
                                                                    {bhrRejectionReason === null ? null :
                                                                    <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                                        <div className='p-sub-heading ml-3'>{'BHR Rejection Reason'}</div>
                                                                        <div className='esep-placeholder-editable'>
                                                                            <textarea
                                                                                className="form-control esep-placeholder-editable px-3"
                                                                                type="text"
                                                                                value={isErrorPostingActive ? this.props.employeeRequestData.bhr_rejection_reason ? this.props.employeeRequestData.bhr_rejection_reason : '-' : bhrRejectionReason ? bhrRejectionReason : '-'}
                                                                                readOnly={true}
                                                                            />
                                                                        </div>
                                                                    </div>}
                                                                    {bhrRejectionRemark === null ? null :
                                                                    <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                                        <div className='p-sub-heading ml-3'>{'BHR Rejection Remarks'}</div>
                                                                        <div className='esep-placeholder-editable'>
                                                                            <textarea
                                                                                className="form-control esep-placeholder-editable px-3"
                                                                                type="text"
                                                                                value={isErrorPostingActive ? this.props.employeeRequestData.bhr_rejection_remark ? this.props.employeeRequestData.bhr_rejection_remark : '-' : bhrRejectionRemark ? bhrRejectionRemark : '-'}
                                                                                readOnly={true}
                                                                            />
                                                                        </div>
                                                                    </div>}
                                                                {lwdJustificationBhr === null ?
                                                                    null :
                                                                    <>
                                                                        {lwdJustificationBhr !== null && isNoticePayWaveOffBhr === false ?
                                                                            <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                                <div className='p-sub-heading ml-3 purple-color font-weight-bold'>{'Notice Pay Recovery waive off by BHR'}</div>
                                                                                <div className='esep-placeholder-editable'>
                                                                                    <input
                                                                                        className="form-control esep-placeholder-editable px-3"
                                                                                        type="text"
                                                                                        value={'No'}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                                <div className='p-sub-heading ml-3 purple-color font-weight-bold'>{'Notice Pay Recovery waive off by BHR'}</div>
                                                                                <div className='esep-placeholder-editable'>
                                                                                    <input
                                                                                        className="form-control esep-placeholder-editable px-3"
                                                                                        type="text"
                                                                                        value={'Yes'}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                                {isNoticePayWaveOffBhr === true ?
                                                                    <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                                        <div className='p-sub-heading ml-3'>{'Notice Pay waive off Justification By BHR'}</div>
                                                                        <div className='esep-placeholder-editable'>
                                                                            <textarea
                                                                                className="form-control esep-placeholder-editable px-3"
                                                                                type="text"
                                                                                value={isErrorPostingActive ? this.props.employeeRequestData.wave_off_justification_bhr ? this.props.employeeRequestData.wave_off_justification_bhr : '-' : waveOffJustificationBhr ? waveOffJustificationBhr : '-'}
                                                                                readOnly={true}
                                                                            />
                                                                        </div>
                                                                    </div> : null}
                                                            </div>
                                                            <hr />
                                                        </> : null}
                                                    {(separationStatus === 'Approved by BUHR' && isNoticePayWaveOffBhr === true) || (separationStatus === 'Rejected by BUHR' && isNoticePayWaveOffBhr === true) ?
                                                        <>
                                                            <div className='document-innercard-content-title mx-2 mt-2 ml-3'>{'BUHR Approvals Details'}</div>
                                                            <div className='row'>
                                                                {buhrApprovalRemark === null ? null :
                                                                    <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                                        <div className='p-sub-heading ml-3'>{'BUHR Approval Remarks'}</div>
                                                                        <div className='esep-placeholder-editable'>
                                                                            <textarea
                                                                                className="form-control esep-placeholder-editable px-3"
                                                                                type="text"
                                                                                value={isErrorPostingActive ? this.props.employeeRequestData.buhr_approval_remark ? this.props.employeeRequestData.buhr_approval_remark : '-' : buhrApprovalRemark ? buhrApprovalRemark : '-'}
                                                                                readOnly={true}
                                                                            />
                                                                        </div>
                                                                    </div>}
                                                            </div>
                                                        </> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            {/* -------------------------Model buttons---------------------------- */}
                            <div className='modal-footer btnBox1'>
                                <>
                                    {action === 'viewEsepRequest' ?
                                        <input type='button' value='CLOSE' className='ml-2 validateBtn' onClick={onDismiss} /> :
                                        <input type='button' value='CLOSE' className='ml-2 validateBtn' onClick={() => this.onDismiss()} />}
                                </>
                            </div>
                        </div>
                    </div >
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(EsepTrackerDashboard);