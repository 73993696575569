import React, { Fragment, useEffect, useState } from "react";
import DynamicDropdown from "../../../../shared/components/DynamicDropdown";
import LargeDropdown from "../../../../shared/components/LargeDropdown";
import moment from "moment";
import Dropdown from "../../../../shared/components/Dropdown";
import { toast } from "react-toastify";
import plus from "../../../../../assets/drishti_images/manager/landing/plus.svg";
import { masterScreenList, suggestionMasterScreen } from "./constant";
import MasterScreenDashboard from "./MasterScreenDashboard";
import { configStore } from "../../../../../redux/ConfigStore";
import {
  getSuggestionSpecialScheme,
  deleteSuggestionSchemeName
} from "../../../../../constants/api.service";
import UpdatePopUpScreen from "./UpdatePopUpScreen";
import AddPopUpScreen from "./AddPopUpScreen";
import { toaster } from "../../../../../utils/Utils";
import LoaderIndicator from "../../../../shared/loader/LoaderIndicator";
import DataNotFound from "../../../../shared/notFound/DataNotFound";

function SuggestionMasterScreen() {
  const [selectedScreen, setSelectedScreen] = useState(null);
  const [updateFormData, setUpdateFormData] = useState(null);
  const [isFormUpdatePopup, setIsFormUpdatePopup] = useState(false);
  const [AddFormData, setAddFormData] = useState(false);
  const [isFormViewPopup, setIsFormViewPopup] = useState(false);
  const [masterScreenListData, setMasterScreenListData] = useState(null);
  const [loading, setloading] = useState(false);

  const handleSlected = (data) => {
    if (data.key == suggestionMasterScreen.SchemeList) {
      getSpecialSchemeListDetails();
    }
    setSelectedScreen(data);
  };

  const getSpecialSchemeListDetails = async () => {
    setloading(true);
    const param = {
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await getSuggestionSpecialScheme(param)
      .then((res) => {
        if (res.status === 200) {
          setMasterScreenListData(res?.data?.data);
        } else {
          setMasterScreenListData([]);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setloading(false);
      });
  };

  const onAction = (listData, type) => {
    if (type === "update") {
      setIsFormUpdatePopup(true);
      setUpdateFormData(listData);
    } else if (type === "delete") {
      deleteForm(listData);
    } else if (type === "view") {
      setIsFormViewPopup(true);
      setUpdateFormData(listData);
    }
  };

  const addForm = () => {
    setAddFormData(true);
  };

  const deleteForm = async (data) => {
    setloading(true);
    let body = {
      scheme_name: data?.scheme_name,
      start_date: data?.start_date,
      end_date: data?.end_date,
      // image_path: documentData.path,
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await deleteSuggestionSchemeName(body, data.id)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toaster("success", res?.data?.message ? res?.data?.message : "");
          getSpecialSchemeListDetails();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
        }
      })
      .catch((error) => {
        console.log("error-->", error);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error?.data?.message : "");
      })
      .finally(() => {
        setloading(false);
      });
  };

  const dismisUpdateForm = () => {
    getSpecialSchemeListDetails();
    setIsFormUpdatePopup(false);
  };

  const closeUpdatePopUp = () => {
    getSpecialSchemeListDetails();
    setIsFormUpdatePopup(false);
  };

  const dismisAddForm = () => {
    getSpecialSchemeListDetails();
    setAddFormData(false);
  };

  const closeAddPopUp = () => {
    getSpecialSchemeListDetails();
    setAddFormData(false);
  };

  return (
    <div>
      <LoaderIndicator loading={loading} />
      <div className="px-0 pt-0">
        <div className="justify-content-between align-item-center mt-1">
          <div className="route-common-heading ml-3">MASTER SCREEN</div>
          <div className="justify-content-end align-item-center">
            <div className="ml-2 dropdown-Text">
              <Dropdown
                data={masterScreenList}
                selectedValue={(data) => {
                  handleSlected(data);
                }}
                value={selectedScreen?.value ?? "Select Master Screen Type"}
                render={"value"}
                arrow={"true"}
                height={"dependOnContent"}
              />
            </div>
            <div>
              <input
                type="button"
                value="ADD FORM"
                className={`${
                  selectedScreen ? "validateBtn" : "validateBtnDisabled"
                }`}
                onClick={() => addForm()}
                disabled={!selectedScreen}
              />
            </div>
          </div>
        </div>

        <div className="row mt-2">
          {selectedScreen ? (
            <>
              <div className="profile-inner-card w-100">
                <MasterScreenDashboard
                  tabName={selectedScreen}
                  masterScreenListData={masterScreenListData}
                  onAction={onAction}
                />
              </div>
            </>
          ) : (
            <div style={{ margin: "10% 20% 30% 40%" }}>
              <DataNotFound />
            </div>
          )}
        </div>

        {isFormUpdatePopup && (
          <UpdatePopUpScreen
            updateFormData={updateFormData}
            filteredValue={selectedScreen}
            dismisUpdateForm={dismisUpdateForm}
            closeUpdatePopUp={closeUpdatePopUp}
          />
        )}

        {AddFormData && (
          <AddPopUpScreen
            dismisUpdateForm={dismisAddForm}
            closeUpdatePopUp={closeAddPopUp}
          />
        )}

        {isFormViewPopup && (
          <div className="modal popup-box">
            <div className={"popupCard-box p-0 w-50"}>
              <div className="modal-header">
                <h4 className="modal_title black">
                  {"Notice Board Attachment"}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={(e) => {
                    setIsFormViewPopup(false);
                  }}
                >
                  &times;
                </button>
              </div>
              {
                updateFormData.image_path ? 
                <div className="modal-body m-15px scroll-auto">
                    <span className="flex-direction-column">
                      <span className="d-flex flex-direction-column px-10px pb-10px">
                        <>
                          <img
                            src={
                              updateFormData?.image_path
                                ? updateFormData?.image_path
                                : ""
                            }
                            alt=""
                            className="upload_bills_modal_body"
                          />
                        </>
                      </span>
                    </span>
                </div> 
                  :
                <div>
                  <DataNotFound />
                </div>
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SuggestionMasterScreen;
