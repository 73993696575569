import React, { useState, useEffect } from 'react'
import CommonCard from '../../../employeeScreens/suggestionScheme/commonComponent/CommonCard'
import DataNotFound from '../../../../shared/notFound/DataNotFound'
import leftArrow from '../../../../../assets/drishti_images/left-arrow.svg';
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';
import Table from './Table';
import CommonButton from '../../../../shared/button/GradientButton';
import DivisionalApprovalPopup from './divisionalApprovalPopup';
import { divisionalCoordinatorTableFields } from '../../../employeeScreens/suggestionScheme/constants';
import './divisionalcoordinator.css'
import DivisionalCommonPopup from './DivisionalCommonPopup';
import { useSelector } from 'react-redux';
import { toaster } from '../../../../../utils/Utils';
import { getDivisionalAgencyDashboard, getDivCordinatorSuggestionList, getMasterLists } from '../../../../../constants/api.service';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import searchIcon from '../../../../../assets/drishti_images/admin/searchIcon.svg';
import { Pagination } from 'react-bootstrap';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';

function DivisionalApproval({isDivisionalScreen,iDivisionalScreenReason}) {
  const [agency, setAgency] = useState('')
  const [agencyList, setAgencyList] = useState([])
  const [filteredAgencyList, setFilteredAgencyList] = useState(agencyList)
  const [selectedAgency, setSelectedAgency] = useState('')
  const [selectedStatus, setSelectedStatus] = useState({
    "id": 2,
    "status_code": 1,
    "status_name": "Pending at Divisional Co-ordinator"
})
  const [selectedSchemeType, setSelectedSchemeType] = useState({ title: 'Normal', value: 'Normal' })
  const [selectedSuggestion, setSelectedSuggestion] = useState([])
  const [selectedRecord, setSelectedRecord] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [approveRemark, setApproveRemark] = useState('')

  const searchFilterFunction = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setAgency(e.target.value);
    if (searchValue === '') {
      setFilteredAgencyList(agencyList);
    } else {
      setFilteredAgencyList(
        agencyList.filter((val) =>
          val.division_name.toLowerCase().includes(searchValue)
        )
      );
    }
  }
  const [selectedPopupAction, setSelectedPopupAction] = useState('')
  const [rejectionRemark, setRejectionRemark] = useState('')
  const [sendBackRemark, setSendBackRemark] = useState('')
  const [openCommonPopup, setOpenCommonPopup] = useState(false)
  const [schemeTypeList, setschemeTypeList] = useState([])
  const [inputValue, setInputValue] = useState('');

  const handleInputSubmit = (event) => {
    event.preventDefault();
    const pageNum = parseInt(inputValue, 10);

    // Validate the input value
    if (pageNum > 0 && pageNum <= totalPages) {
      setCurrentPage(pageNum);
      paginationCall(pageNum);
      setInputValue(''); // Clear input after submission
    } else {
      toaster("warning", `Enter Valid Number 1 to ${totalPages}`)
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const backAction = () => {
    setSelectedAgency('');
    setFilteredAgencyList(agencyList);
    setAgency('')
    setSelectedSuggestion([])
    setTotalPages(1)
    setCurrentPage(1)
  }
  const handlePageCount = (action) => {
      setSelectedSuggestion([])
    if (action === "decrement" && currentPage > 1) {
        setCurrentPage(currentPage - 1);
        paginationCall(currentPage - 1)
    } else if (action === "increment" && currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
        paginationCall(currentPage + 1)
    }
};
  const callSelectedPopupAction = (actionType) => {
    setSelectedPopupAction(actionType)
    setOpenCommonPopup(!openCommonPopup)
  }
  
  // const handleAgencyFilter = (data) => { 
  //   setSelectedAgency(data.division_name);   
  //   openSelectedAgency(data.division_code, selectedStatus?.status_code, selectedSchemeType?.scheme_type_code, searchSuggText, currentPage) 
  // }

  // const handleStatusFilter = (data) => { setSelectedStatus(data) }
  // const handleSchemeTypeFilter = (data) => { setSelectedSchemeType(data) }

  const [loading, setLoading] = useState(false)
  const loginData = useSelector(state => state.loginState);
  const [divisionalPaginatedList, setDivisionalPaginatedList] = useState([])
  const [selectedAgencyId, setSelectedAgencyId] = useState(null)
  const [searchSuggText, setSearchSuggText] = useState('');
  const [statusList, setStatusList] = useState([])

  useEffect(() => {
    getDivisionalAgencyDashboardCall();
    getSchemeTypeList();
    getStatusList();
  }, [])
  
  const getDivisionalAgencyDashboardCall = () => {
    setLoading(true)
    let params = {
      comp_code: loginData.userData.CompCode,
      pa: loginData.userData.PersArea
    }
    getDivisionalAgencyDashboard(params)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setFilteredAgencyList(data?.data ? data?.data : [])
          setAgencyList(data?.data ? data.data : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const openSelectedAgency = (data, sugStateCode, SchemeType, searchText, currentPageNo = currentPage) => {
    setLoading(true)
    let params = {
      sug_status_code: sugStateCode !== null ? sugStateCode : null,
      scheme_type_code: SchemeType !== null ? SchemeType : null,
      search: searchText !== null ? searchText : null,
      page_number: currentPageNo !== null ? currentPageNo : currentPage
    }
    getDivCordinatorSuggestionList(params, data)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setDivisionalPaginatedList(data?.data ? data.data?.paginated_data : [])
          setTotalPages(data?.data ? data.data?.total_pages : null)
        }
        else {
           toaster("warning", res?.data?.message ?? "Something Went Wrong!") 
           setDivisionalPaginatedList([])
          }
      })
      .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const paginationCall = (currentPageNo) => {
    openSelectedAgency(selectedAgencyId, selectedStatus?.status_code, selectedSchemeType?.scheme_type_code, searchSuggText, currentPageNo)
    setCurrentPage(currentPageNo)
  }

  const openSuggListScreen = (value) => {
    setSelectedAgency(value.division_name);
    setSelectedAgencyId(value.division_code)
    openSelectedAgency(value.division_code, selectedStatus?.status_code)
  }

  const closePopup = () => {
    setSelectedRecord('')
    openSelectedAgency(selectedAgencyId, selectedStatus?.status_code, selectedSchemeType?.scheme_type_code)
    setSelectedSuggestion([])
  }

  const commonSerachFilters = (data, type) => {
    if (type === "Division") {
      setSelectedAgency(data.division_name)
      setSelectedAgencyId(data.division_code)
      openSelectedAgency(data.division_code, selectedStatus?.status_code, selectedSchemeType?.scheme_type_code, searchSuggText)
      setSelectedSuggestion([])

    } else if (type === "Status") {
      setSelectedStatus(data)
      openSelectedAgency(selectedAgencyId, data?.status_code, selectedSchemeType?.scheme_type_code, searchSuggText)
      setSelectedSuggestion([])

    } else if (type === "Scheme") {
      setSelectedSchemeType(data)
      openSelectedAgency(selectedAgencyId, selectedStatus?.status_code, data?.scheme_type_code, searchSuggText)
      setSelectedSuggestion([])

    } else if (type === "Search") {
      const CurrentPage=1;
      setCurrentPage(1)
      openSelectedAgency(selectedAgencyId, selectedStatus?.status_code, selectedSchemeType?.scheme_type_code, data,CurrentPage)
      setSelectedSuggestion([])
    }
  }

  const getSchemeTypeList = () => {
    setLoading(true)

    getMasterLists("scheme_type")
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setschemeTypeList(data?.data ? data?.data?.scheme_type_list : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const getStatusList = () => {
    setLoading(true)

    getMasterLists("status")
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setStatusList(data?.data ? data?.data?.status_list : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const setSearchSuggTextCall = (e) => {
    const value = e.target.value;
    if (value === '') {
      setSearchSuggText(null);
    } else {
      setSearchSuggText(value);
    }
  }

  return (
    
    <div className='ml-10 h-90'>
    {isDivisionalScreen ? 
      <>
        <div className="col-md-12 h-100vh justify-content-center align-item-center">
          <ServiceUnavailable reason={iDivisionalScreenReason} />
        </div>
      </>
      :
      <>
      {/* ------------------------------------------------------------------- Landing Screen -------------------------------------------------------- */}
      {
        loading? <LoaderIndicator loading={loading} /> :
        selectedAgency === '' ?
          <div className='row justify-content-between mt-10px'>
            <div className='popup-title'>Select Division</div>
            <div className='row'>
              <div className='row ml-15px'>
                <div className='p-placeholder-text mr-3'>
                  <input
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    type="text"
                    placeholder='Search Division'
                    onChange={(e) => { searchFilterFunction(e) }}
                    value={agency}
                  />
                </div>
              </div>
            </div>
          </div>    
          :
          <div className='row justify-content-between mt-10px'>
            {/* ------------------------------------------------------------------- Listing Screen -------------------------------------------------------- */}
            <span className='pointer align-item-center add-btn-text' onClick={backAction}>
              <img
                alt="left arrow"
                src={leftArrow}
                className="left-arrow"
              />
              Back
            </span>
          </div>
      }
      {
        selectedAgency === '' ?
          <div className='row mt-10px gap-20 h-90 scroll-y'>
            {
              filteredAgencyList.length > 0 ?
              filteredAgencyList.map((value) =>
                  <div className='w-32 h-28vh pointer'>
                    {/* ------------------------------------------------------------------- Landing Screen -------------------------------------------------------- */}
                    <CommonCard onClick={() => {
                      openSuggListScreen(value)
                      }} >
                      <div className='flex-direction-column h-100 justify-content-center w-97 align-items-center gap-10'>
                        <div className='suggestion-card-content'>{value.count}</div>
                        <div className='document-innercard-content-title'>{value.division_name}</div>
                      </div>
                    </CommonCard>
                  </div>
                )
                : <div className='justify-content-center align-items-center w-100'>
                  <DataNotFound />
                </div>
            }
          </div>
          :
          <div className=''>
            {/* ------------------------------------------------------------------- Listing Screen -------------------------------------------------------- */}
            <div className='row align-item-center background-filter gap-10'>
              <div className='flex-direction-row gap-10px align-item-center filter w-100'>
                <div className='p-sub-heading ml-2'>{"Division"}</div>
                <div className='d-flex align-item-center'></div>
                <div className='w-13rem'>
                  <DynamicDropdown
                    data={agencyList}
                    render="division_name"
                    value={selectedAgency}
                    selectedValue={(data) =>commonSerachFilters(data, "Division")}
                    arrow={'true'}
                    height={'dependOnContent'}
                    width={"w-16rem"}
                    boxWidth="14rem"
                  />
                </div>
                <div className='p-sub-heading ml-2'>{"Suggestion status"}</div>
                <div className='w-12'>
                <DynamicDropdown
                  data={statusList}
                  render="status_name"
                  value={selectedStatus.status_name}
                  selectedValue={(data) => commonSerachFilters(data, "Status")}
                  arrow={'true'}
                  height={'dependOnContent'}
                  width={"w-16rem"}
                  boxWidth="12rem"
                />
                </div>
                <div className='p-sub-heading ml-2'>{"Type of suggestion"}</div>
                <div className='w-13rem'>
                  <DynamicDropdown
                    data={schemeTypeList}
                    render="scheme_type"
                    value={selectedSchemeType.scheme_type}
                    selectedValue={(data) => commonSerachFilters(data, "Scheme")}
                    arrow={'true'}
                    height={'dependOnContent'}
                    width={"w-13rem"}
                    boxWidth="12rem"
                  />
                </div>
                <div className='p-sub-heading ml-2'>{"Search"}</div>
                <div className='d-flex align-item-center'>
                  <div className='p-placeholder-text'>
                    <input
                      className="form-control p-placeholder px-2 p-placeholder-admin"
                      type="text"
                      placeholder='Search'
                      onChange={(e) => setSearchSuggTextCall(e)}
                      value={searchSuggText}
                    />
                  </div>
                  <div type='button' className='serchIconSize-evaluator' onClick={() => commonSerachFilters(searchSuggText, "Search")} >
                    <img alt="radioOn" className='filterIcon' src={searchIcon} />
                  </div>
                </div>
              </div>
            </div>
            {
              divisionalPaginatedList?.length > 0 ?
                <div>
                  <Table
                    hasAction
                    columns={divisionalCoordinatorTableFields}
                    data={divisionalPaginatedList}
                    onSelectSuggestion={(data) => { setSelectedSuggestion(data) }}
                    selectedSuggestion={selectedSuggestion}
                    paginationCall={(data) => paginationCall(data)}
                    setCurrentPage={setCurrentPage}
                    statusCode={selectedStatus?.status_code}
                    totalPages={totalPages}
                    selectedStatus={selectedStatus}
                    currentPage={currentPage}
                    pageSize={6}
                    onAction={(data) => setSelectedRecord(data)}
                    suggestionStatus = {selectedStatus.status_name}
                    minHeight="50vh"
                  />
                </div>
                :
                <div className='justify-content-center align-items-center w-100 h-54vh'>
                  <DataNotFound />
                </div>
            }
            {openCommonPopup &&
              <>
                <DivisionalCommonPopup
                  title={selectedPopupAction}
                  action={'finalAction'}
                  submitSendBack={(remarks) => {setSendBackRemark(remarks); setOpenCommonPopup(false)}}
                  submitRejection={(remarks) => {setRejectionRemark(remarks); setOpenCommonPopup(false)}}
                  submitApprove={(remarks) => { setApproveRemark(remarks); setOpenCommonPopup(false) }}
                  onDismiss={() => { setOpenCommonPopup(false);  openSelectedAgency(selectedAgencyId, selectedStatus?.status_code, selectedSchemeType?.scheme_type_code);setSelectedSuggestion([])}}
                  loginData={loginData}
                  data = {selectedSuggestion}
                  selectedRecord={selectedSuggestion}
                />
              </>
            }
            {
              divisionalPaginatedList?.length > 0 &&
              <div className='row align-item-center justify-content-between Evaluator-background-highlighter gap-10'>
                <div className='ptb-0_375 plr-0_75'>{selectedSuggestion.length + '/' + divisionalPaginatedList.length + ' records selected.'}</div>
                {currentPage &&
                // <div className="col-md-12 w-100 d-flex justify-content-center">
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center'}}>
                    <Pagination>
                        {currentPage != 1 &&
                            <>
                                <Pagination.Prev
                                    onClick={() => { handlePageCount("decrement") }} />
                                <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
                                <Pagination.Ellipsis disabled />
                            </>
                        }
                        <Pagination.Item active>{currentPage}</Pagination.Item>
                        {currentPage != totalPages &&
                            <>
                                <Pagination.Ellipsis disabled />
                                <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
                                <Pagination.Next
                                    onClick={() => { handlePageCount("increment") }} />
                            </>
                        }
                    </Pagination>
                 </div>
            }
            {totalPages >= 2 && (
                  <div className='d-flex align-items-center'>
                       <input
                         className="inpt input-date w-100 w-md-auto"
                         type="text"
                         placeholder='Page No...'
                         onChange={handleInputChange}
                         value={inputValue}
                       />
                       <div type='button' className='serchIconSize-evaluator' onClick={(event) => handleInputSubmit(event)}>
                         <img alt="radioOn" className='filterIcon' src={searchIcon} />
                       </div>
                </div>)}
              { selectedSuggestion.length > 0 && (<div style={{ display: 'flex', gap: '7px', minWidth: '350px', justifyContent: 'flex-end' }}>

                {
                  <div className='row gap-7px'>
                    { !selectedSuggestion.some(ele => ele.is_divisional_coordinator_send_back) &&     
                      <CommonButton
                        label="SEND BACK"
                        onClick={() => { callSelectedPopupAction("Send_back") }}
                        isGradientBtn={true}
                        className={'px-20px'}
                      />
                    }
                    <CommonButton
                      label="REJECT"
                      onClick={() => { callSelectedPopupAction("Reject") }}
                      isGradientBtn={true}
                      className={'px-20px'}
                    />
                    <CommonButton
                      label="APPROVE"
                      onClick={() => { callSelectedPopupAction("Approved") }}
                      isGradientBtn={true}
                      className={'px-20px'}
                    />
                  </div>
                }
                </div>)}
              </div>
        }
          </div>
      }
      {selectedRecord != '' && <DivisionalApprovalPopup title={'Suggestion Details'} backAction={closePopup} data={selectedRecord} selectedRecordId = {selectedRecord.id} suggestionStatus= {selectedStatus.status_name} selectedStatus={selectedStatus} />}
      </>}
    </div>
  )
}

export default DivisionalApproval