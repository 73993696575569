import React, { useEffect, useState } from "react";
import DynamicDropdown from "../../../../shared/components/DynamicDropdown";
import { exportToExcel, toaster } from "../../../../../utils/Utils";
import { employeeTypeList, monthData, bdcreportfield } from "./constant";
import { toast } from "react-toastify";
import { suggestionReportFieldVisiblity ,suggestionBdcReport } from "../report/constant";
import { useSelector } from "react-redux";
import moment from "moment";
import * as XLSX from 'xlsx';
import LoaderIndicator from "../../../../shared/loader/LoaderIndicator";
import Select, { components } from 'react-select';
import {
  getSuggestonOfficeAgencyDashboard,
  getReportList,
  getSchemeListForReports,
  getFinancialYears,
  get_Suggestion_generation_report,
  suggestion_bdc_history_report,
  get_suggestion_bdc_report_pdf,
  get_agewise_pending_report_admin,
  get_suggestion_bdc_report_history_pdf,
  get_bdc_report_pdf_from_S3,
  get_Suggestion_generation_and_acceptance_report,
  get_final_circular_pdf_report,
  get_Non_participant_report,
  get_Suggestion_scheme_division_count,
  division_wise_suggestion_performance_report,
  suggestion_bdc_calculation_report,
  getReportAwardType
} from "../../../../../constants/api.service";
import LargeDropdown from "../../../../shared/components/LargeDropdown";
import DateFilter from "../../clearance/DateFilter";
import Table from "../suggestionOffice/Table";


function SuggestionReport() {
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showDivision,setShowDivision] = useState(false);
  const [reportType, setReportType] = useState([]);
  const [schemeList, setschemeList] = useState([]);
  const [ListScheme, setListScheme] = useState("");
  const [selectedEmployeeType, setSelectedEmployeeType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [divisionData, setDivisionData] = useState([]);
  const [division, setDivision] = useState("");
  const [years, setYears] = useState(null);
  const adminLoginState = useSelector((state) => state.adminLoginState);
  const [suggestionGenerationReport, setSuggestionGenerationReport] = useState(
    []
  );
  const [bdcGenerationReport, setBdcGenerationReport] = useState([]);
  const [bdcHistoryReport, setBdcHistoryReport] = useState([]);
  const [bdcPdfS3Path, setBdcPdfS3Path] = useState([])
  const [bdcPdfCreatedDate,setBdcPdfCreatedDate] = useState([])
  const [sugOfficeCode,setSugOfficeCode] = useState([])
  const [sugReportType,setSugReportType] = useState([])
  const [
    suggestionGenerationAndAcceptanceReport,
    setSuggestionGenerationAndAcceptanceReport,
  ] = useState([]);
  const [suggestionSchemeDivisionCount, setSuggestionSchemeDivisionCount] =
    useState([]);
  const [isExportBtn, setExportBtn] = useState(false);
  const [showBdcTable, setShowBdcTable] = useState(false);
  const [showBdcHistoryTable, setShowBdcHistoryTable] = useState(false);
  const [awardList,setAwardList] = useState([]);
  const [viewFilter,setViewFilter] = useState('');
  const [currentReportStatusCodes,setCurrentReportStatusCodes] = useState(null)

  const exportPDFData = async(reportFilePath,reportCreatedDate,sugOffice) =>{
    try {
      setLoader(true);
        const param = {
          comp_code: adminLoginState?.userData?.CompCode,
          bdc_pdf_s3_path:reportFilePath,
          bdc_pdf_created_date:reportCreatedDate,
          pa: adminLoginState?.userData?.PersArea,
          sug_office_code:sugOffice,
          financial_year: selectedYear?.value,
      };
      const res = await get_bdc_report_pdf_from_S3(param);
      if (res?.status == 200 || res?.status == 201) {
        const url = URL.createObjectURL(res?.data);
        const link = document.createElement('a');
        link.href = url;
        link.download = reportFilePath;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        toast.success("Your document has been downloaded.", {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastSuccess",
        });
      } else {
        toast.warn(res?.data?.message, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      }
  } catch (errors) {
      toast.warn(errors.message || "An error occurred.", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
      });
  } finally {
      setLoader(false);
  }
  }
  const exportAllData = (exportData, name) => {
    if (exportData) {
      let BdcRecords = exportData;
      let colNames = [
        ...new Set(BdcRecords.flatMap((obj) => Object.keys(obj))),
      ];
      colNames = [colNames];
      if (BdcRecords.length == 0) {
        toast.warn("No Data found", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      } else {
        exportToExcel(name, ".xlsx", BdcRecords, colNames);
        toaster("success", "Report downloaded successfully");
      }
    }
  };

  const reportTypeSelected = (data) => {
    const filterData = suggestionReportFieldVisiblity[data.id]
    if(filterData){
      setShowDivision(filterData.showDivision);
    }
    else{
      setShowDivision(false)
    }
    setExportBtn(false);
    setShowBdcTable(false);
    setSelectedYear(null);
    setToDate('');
    setFromDate('');
    setSelectedMonth(null);
    setDivision('');
    setSelectedEmployeeType('');
    setListScheme('');
    setViewFilter('');

    if (data.id === "S007" || data.id === "S009" || data.id === "S011") {
      setShowBdcHistoryTable(true);
      
      setBdcHistoryReport([]);
      if(data.id === "S009"){
        setSugReportType("pdf")
      }
      if(data.id !== "S009"  ){
        setSugReportType("")
      }

    } else {
      setShowBdcHistoryTable(false);
      setBdcHistoryReport([]);
    }
    setSelectedReportType(data);
  };

  const yearSelected = (data) => {
    setSelectedYear(data);
  };

  const monthSelected = (data) => {
    setSelectedMonth(data);
  };

  const handleListScheme = (data) => {
    setListScheme(data);
    // validate('ListScheme', data);
  };

  const handleEmployeType = (data) => {
    setSelectedEmployeeType(data);
    // validate('ListScheme', data);
  };

  const handleDivision = (data) => {
    setDivision(data);
  };

  // ---------------------Get Schemes List Details------------ //
  const getSchemeListDetail = () => {
    setLoader(true);

    getSchemeListForReports()
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const schemeList = res?.data?.data?.scheme_list.map((schemeList) => ({
            id: schemeList?.id,
            label: schemeList?.scheme_name,
          }));
          setschemeList(schemeList);
        } else {
          setschemeList([]);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // ---------------------Get Financial Years Details------------ //
  const getFinancialYearsDetail = () => {
    setLoader(true);
    getFinancialYears()
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const yearList = res?.data?.data.map((item) => ({
            name: item?.value,
            value: item?.value,
          }));
          setYears(yearList);
        } else {
          res?.status !== 404 && toaster("warning", res?.data?.message ?? "someThingWentWrong")
          setYears(null);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // ---------------------Get Report List Details------------ //
  const getReportListDetail = () => {
    setLoader(true);
    const param = {
      sug_role: "Suggestion_officer",
    };

    getReportList(param)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const reportList = res?.data?.data?.map((item) => ({
            name: item.report_name,
            id: item.report_code,
          }));
          setReportType(reportList);
        } else {
          setReportType([]);
          res?.status !== 404 && toaster("warning", res?.data?.message ?? "someThingWentWrong")
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // ---------------------Get Division Details------------ //
  const getDivision = () => {
    setLoader(true);
    const param = {
      comp_code: adminLoginState?.userData?.CompCode,
      pa: adminLoginState?.userData?.PersArea,
    };
    getSuggestonOfficeAgencyDashboard(param)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const division = res?.data?.data?.map((division) => ({
            id: division?.division_code,
            label: division?.division_name,
          }));
          setDivisionData(division);
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
          setDivisionData([]);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // ---------------------Get Suggestion Generation Report------------ //
  const getSuggestiongenerationreport = () => {
    setLoader(true);
    const param = {
      from_date: fromDate,
      to_date: toDate,
    };
    get_Suggestion_generation_report(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          // const resultSet = res?.data?.data;
          // setSuggestionGenerationReport(resultSet);
          // exportAllData(resultSet, "Suggestion_Generation_Report");
          setLoader(false);
          toast.success('The report will be sent to your email shortly', {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastSuccess'
          });
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
      setLoader(false);
      toast.success('The report will be sent to your email shortly', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastSuccess'
      });
  };

   // ---------------------Get Report List------------ //
   const getReportAwardList = () => {
    setLoader(true);
    getReportAwardType()
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const reportList = res?.data?.data?.award_list;
          const options = reportList.map(status => ({ value: status.id, label: status.award_name, scheme_type : status.suggestion_scheme_type,award_code:status.award_code}));
          setAwardList(options);
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
          setAwardList([]);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // Common function to map and transform data
const mapData = (dataset, mapping) => {
  return dataset.map(item => {
    let transformedItem = {};
    
    Object.keys(mapping).forEach(originalKey => {
      // If the key exists in the dataset, map it to the new key
      if (item[originalKey] !== undefined) {
        transformedItem[mapping[originalKey]] = item[originalKey];
      }
    });
    
    return transformedItem;
  });
};

const columnMapping = {
  suggestion_bdc_id:"Remark",
  emp_id:"Pers. No",
  wage_type:"Wage Type",
  amount:"Amount",
  created_date:"Date",
  "":"",
  suggestion_year:"Assignment"
};


  // ---------------------Get Suggestion Generation BDC Report------------ //
  const getSuggestionBdcReport = () => {
    setLoader(true);
    const param = {
      comp_code: adminLoginState?.userData?.CompCode,
      financial_year: selectedYear?.value,
      month: parseInt(selectedMonth?.value),
      ...(currentReportStatusCodes ? { award_code: currentReportStatusCodes } : {}),
      pa: adminLoginState?.userData?.PersArea,
      generate_bdc: suggestionBdcReport
    };
    suggestion_bdc_calculation_report(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const resultSet = res?.data?.data;
          setBdcGenerationReport(resultSet);
          const mappedData = mapData(resultSet,columnMapping);
          exportAllData(mappedData, "Suggestion_Bdc_Report");
          setExportBtn(false);
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };


  // ---------------------Get Suggestion BDC Calculation Report PDF------------ //
  const getSuggestionBdcReportPdf = async () => {
    try {
        setLoader(true);
        const param = {
            comp_code: adminLoginState?.userData?.CompCode,
            financial_year: selectedYear?.value,
            month: parseInt(selectedMonth?.value),
            pa: adminLoginState?.userData?.PersArea,
            // ...(currentReportStatusCodes ? { award_code: currentReportStatusCodes } : {})
        };
        const res = await get_suggestion_bdc_report_pdf(param);
        if (res.status === 200 || res.status === 201) {
            const url = URL.createObjectURL(res?.data);
            const contentDisposition = res.headers['content-disposition'];
            let filename = 'suggestion_bdc_report.pdf'; 

            if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
                const matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, ''); 
                }
            }
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            toast.success("Your document has been downloaded.", {
                position: "bottom-center",
                hideProgressBar: true,
                className: "toastSuccess",
            });
        } else {
            toast.warn(res.data?.data?.message || "Payment advice details not found.", {
                position: "bottom-center",
                hideProgressBar: true,
                className: "toastWarn",
            });
        }
    } catch (errors) {
        toast.warn(errors.message || "An error occurred.", {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
        });
    } finally {
        setLoader(false);
    }
};
// ---------------------Get Final Circular Report PDF------------ //
const getFinalCircularPdfReport = async () => {
  try {
      setLoader(true);
      const param = {
          comp_code: adminLoginState?.userData?.CompCode,
          financial_year: selectedYear?.value,
          month: parseInt(selectedMonth?.value),
          pa: adminLoginState?.userData?.PersArea
      };
      const res = await get_final_circular_pdf_report(param);
      if (res.status === 200 || res.status === 201) {
          const url = URL.createObjectURL(res?.data);
          console.log(res)
          const contentDisposition = res.headers['content-disposition'];
          console.log(contentDisposition)
          let filename = 'final_circular.pdf'; 

          if (contentDisposition ) {
              filename = contentDisposition
          }
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          toast.success("Your document has been downloaded.", {
              position: "bottom-center",
              hideProgressBar: true,
              className: "toastSuccess",
          });
      } else {
          toast.warn(res.data?.data?.message || "Failed to fetch report.", {
              position: "bottom-center",
              hideProgressBar: true,
              className: "toastWarn",
          });
      }
  } catch (errors) {
    console.log(errors)
      toast.warn(errors.message || "An error occurred.", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
      });
  } finally {
      setLoader(false);
  }
};

// ---------------------Get Age Wise Pending Report------------ //
const getAgewisePendingReportAdmin = async()=>{
  try {
    setLoader(true);
    const param = {
        division_code : division?.id
    };
    const res = await get_agewise_pending_report_admin(param);
    if (res?.status == 200 || res?.status == 201) {
      const resultSet = res?.data?.data
      const contentDisposition = res.headers['content-disposition'];
          let filename = 'Age_Wise_Pending_Report.xlsx'; 

          if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
              const matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
              if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, ''); 
              }
          }
      const ws = XLSX.utils.json_to_sheet(resultSet);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Evaluation Data");
      XLSX.writeFile(wb, filename);
      toast.success(res?.data?.message || "Report Generated Successfully", {
        position: "bottom-center",
        hideProgressBar: true,
        className: "toastSuccess",
      });
    } else {
      toast.error(res?.data?.message || "Something went wrong", {
        position: "bottom-center",
        hideProgressBar: true,
        className: "toastError",
      });
    }
} catch (errors) {
    toast.error(errors.message || "An error occurred.", {
        position: "bottom-center",
        hideProgressBar: true,
        className: "toastError",
    });
} finally {
    setLoader(false);
}
}


// ---------------------Get Suggestion BDC Calculation Report History PDF------------ //
const getSuggestionBdcReportHistoryPdf = async () => {
  try {
      setLoader(true);
      const param = {
          comp_code: adminLoginState?.userData?.CompCode,
          financial_year: selectedYear?.value,
          month: parseInt(selectedMonth?.value),
          pa: adminLoginState?.userData?.PersArea
      };
      const res = await get_suggestion_bdc_report_history_pdf(param);
      if (res?.status == 200 || res?.status == 201) {
        const resultSet = res?.data?.data;
        if (Array.isArray(resultSet)) {
          resultSet.forEach(item => {
              // Access the keys (dates)
              Object.keys(item).forEach(date => {
                  const entries = item[date]; // Get the array of entries for this date
      
                  entries.forEach(entry => {
                      // Access the properties of each entry
                       setBdcPdfS3Path(entry.bdc_pdf_s3_path);
                       setBdcPdfCreatedDate(entry.bdc_pdf_created_date);
                       setSugOfficeCode(entry.sug_office);
                  });
                  
              });

          });
      } else {
          toast.warn("Something went wrong", {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
      }
        setBdcHistoryReport(resultSet);
      } else {
        toast.warn(res?.data?.message, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      }
  } catch (errors) {
      toast.warn(errors.message || "An error occurred.", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
      });
  } finally {
      setLoader(false);
  }
};


  // ---------------------Get Suggestion BDC Calculation Report------------ //
  const suggestionBdcCalculationReport = () => {
    setLoader(true);
    const param = {
      comp_code: adminLoginState?.userData?.CompCode,
      financial_year: selectedYear?.value,
      month: parseInt(selectedMonth?.value),
      ...(currentReportStatusCodes ? { award_code: currentReportStatusCodes } : {}),
      pa: adminLoginState?.userData?.PersArea
    };
    suggestion_bdc_calculation_report(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const resultSet = res?.data?.data;
          setBdcGenerationReport(resultSet);
          if(resultSet.length){
            setExportBtn(true);
            setShowBdcTable(true);
          }else{
            toast.warn("No Data found", {
              position: "bottom-center",
              hideProgressBar: true,
              className: "toastWarn",
            });
          }
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // ---------------------Get Suggestion BDC History Report------------ //
  const suggestionBdcHistoryReport = () => {
    setLoader(true);
    const param = {
      comp_code: adminLoginState?.userData?.CompCode,
      financial_year: selectedYear?.value,
      month: parseInt(selectedMonth?.value),
    };
    suggestion_bdc_history_report(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const resultSet = res?.data?.data;
          setBdcHistoryReport(resultSet);
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // ---------------------Get Suggestion Generation Acceptance Report------------ //
  const getSuggestiongenerationandacceptance_report = async () => {
    setLoader(true);
    const param = {
      // division_code : division?.id,
      from_date: fromDate,
      to_date: toDate,
    };
    await get_Suggestion_generation_and_acceptance_report(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const resultSet = res?.data?.data;
          setSuggestionGenerationAndAcceptanceReport(resultSet);
          exportAllData(resultSet, "Sugg_Gen_&_Acceptance_Report");
          setLoader(false);
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // ---------------------Get Suggestion NonParticipant Report------------ //
  const getNonParticipantReport = () => {
    setLoader(true);
    const param = {
      financial_year: selectedYear?.value,
      comp_code: adminLoginState?.userData?.CompCode,
      pa: adminLoginState?.userData?.PersArea
    };
    get_Non_participant_report(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const resultSet = res?.data?.data;
          setSuggestionGenerationAndAcceptanceReport(resultSet);
          exportAllData(resultSet, "Non_Participation_Report");
          setLoader(false);
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // ---------------------Get Suggestion Divison Count Report------------ //
  const getSuggestionSchemeDivisionCount = () => {
    setLoader(true);
    const param = {
      financial_year: selectedYear?.value,
      scheme_name_id: ListScheme?.id ? ListScheme?.id : "",
      division_code: division?.id ? division?.id : "",
    };
    get_Suggestion_scheme_division_count(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const resultSet = res?.data?.data;
          setSuggestionSchemeDivisionCount(resultSet);
          exportAllData(resultSet, "Sugg_Scheme_Division_Count");
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

   // ---------------------Get Division wise performance Report------------ //
   const getSuggestionDivisionPerformanceReport = () => {
    setLoader(true);
    const param = {
      employee_type: selectedEmployeeType?.value,
      comp_code: adminLoginState?.userData?.CompCode,
      financial_year: selectedYear?.value,
      month: parseInt(selectedMonth?.value),
    };
    division_wise_suggestion_performance_report(param)
      .then((res) => {
        if (res?.status == 200 || res?.status == 201) {
          const resultSet = res?.data?.data;
          setSuggestionSchemeDivisionCount(resultSet);
          // exportAllData(resultSet, "Div_wise_sugg_perf_report");
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
      // setLoader(false);
      // toast.success('The report will be sent to your email shortly', {
      //   position: "bottom-center",
      //   hideProgressBar: true,
      //   className: 'toastSuccess'
      // });
  };

  const checkValidity = () => {
    if (
      selectedReportType?.id === "S001" ||
      selectedReportType?.id === "S007" ||
      selectedReportType?.id === "S008" ||
      selectedReportType?.id === "S009" ||
      selectedReportType?.id === "S011" 

    ) {
      if (selectedMonth && selectedYear) {
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "S002") {
      if (toDate && fromDate) {
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "S003") {
      if (toDate && fromDate) {
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "S006") {
      if (selectedYear) {
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "S004") {
      if (selectedYear && ListScheme) {
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "S005") {
      if (selectedMonth && selectedYear && selectedEmployeeType) {
        return false;
      } else {
        return true;
      }
    }
    if (selectedReportType?.id === "S010"){
      if(showDivision && division){
        return false;
      }else{
        return true;
      }
    }

    return true;
  };

  const downloadReport = () => {
    if (selectedReportType?.id === "S002") {
      if (toDate && fromDate) {
        getSuggestiongenerationreport();
      }
    }

    if (selectedReportType?.id === "S001") {
      if (selectedYear && selectedMonth) {
        !isExportBtn
          ? suggestionBdcCalculationReport()
          : getSuggestionBdcReport();
      }
    }
    if (selectedReportType?.id === "S008") {
      if (selectedYear && selectedMonth) {
          getSuggestionBdcReportPdf();
      }
    }
    if (selectedReportType?.id === "S009") {
      if (selectedYear && selectedMonth) {
          getSuggestionBdcReportHistoryPdf();
      }
    }
    if (selectedReportType?.id === "S011") {
      if (selectedYear && selectedMonth) {
        getFinalCircularPdfReport();
      }
    }

    if (selectedReportType?.id === "S007") {
      if (selectedYear && selectedMonth) {
        suggestionBdcHistoryReport();
      }
    }

    if (selectedReportType?.id === "S003") {
      if (toDate && fromDate) {
        getSuggestiongenerationandacceptance_report();
      }
    }

    if (selectedReportType?.id === "S006") {
      if (selectedYear) {
        getNonParticipantReport();
      }
    }

    if (selectedReportType?.id === "S004") {
      if (selectedYear && ListScheme) {
        getSuggestionSchemeDivisionCount();
      }
    }
    if (selectedReportType?.id === "S005") {
      if (selectedYear && selectedMonth && selectedEmployeeType) {
        getSuggestionDivisionPerformanceReport();
      }
    }
    if(selectedReportType?.id === "S010"){
      if(showDivision && division){
        getAgewisePendingReportAdmin()
      }
    }
  };

  const customStyles = {
    container: (provided) => ({
        ...provided,
        width: '300px',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'black',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#653DAF',
        borderRadius: '5px',
        padding: '2px 5px',
        color: 'white'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'white',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        cursor: 'pointer',
        color: 'white',
        ':hover': {
            backgroundColor: 'transparent',
            color: 'white',
        },
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        maxHeight: '200px',
        overflowY: 'auto',
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#653DAF' : state.isFocused ? '#f0f0f0' : 'white',
        color: state.isSelected ? 'white' : provided.color,
        padding: '10px',
        margin: '5px 0',
        cursor: 'pointer',
        ':active': {
            backgroundColor: state.isSelected ? '#653DAF' : state.isFocused ? '#f0f0f0' : 'white',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        maxHeight: '38px',
        overflowY: 'auto',
    }),
  };

  const handleViewFilter =(selectedOptions) => {
    setViewFilter(selectedOptions);
    const selectedValues = selectedOptions.map(option => option.award_code).join(',');
    setCurrentReportStatusCodes(selectedValues);
    setExportBtn(false)
  };

  useEffect(() => {
    getSchemeListDetail();
    getFinancialYearsDetail();
    getReportListDetail();
    getDivision();
    getReportAwardList();
  }, []);

  return (
    <div>
      <LoaderIndicator loading={loader} />
      <div className="ml-10 h-90">
        <div>
          <div className="gap-20">
            <div className="d-flex flex-direction-column">
              <div className="flex-direction-row justify-content-between align-item-center filter">
                <div className="d-flex flex-direction-row mx-2 align-items-center">
                  <div className="flex-direction-column ml-3">
                    <div className="p-sub-heading ml-2">
                      {"Select Report Type"}
                    </div>
                    <DynamicDropdown
                      data={reportType}
                      render="name"
                      value={selectedReportType?.name}
                      selectedValue={(data) => reportTypeSelected(data)}
                      arrow={"true"}
                      height={"NotificationHeight"}
                      width={"w-20rem"}
                    />
                  </div>

                  {selectedReportType &&
                  (selectedReportType?.id === "S001" ||
                    selectedReportType?.id === "S006" ||
                    selectedReportType?.id === "S004" ||
                    selectedReportType?.id === "S005" ||
                    selectedReportType?.id === "S007" ||
                    selectedReportType?.id === "S008" || 
                    selectedReportType?.id === "S009" ||
                    selectedReportType?.id === "S011" ) &&
                  selectedReportType?.id !== "S002" &&
                  selectedReportType?.id !== "S003" &&
                  selectedReportType?.id !== "S010" ? (
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">{"Select Year"}</div>
                      <DynamicDropdown
                        data={years}
                        render="name"
                        value={
                          selectedYear?.name ? selectedYear?.name : "Select..."
                        }
                        selectedValue={(data) => yearSelected(data)}
                        arrow={"true"}
                        height={"NotificationHeight"}
                        width={"w-8rem"}
                      />
                    </div>
                  ) : null}

                  {selectedReportType &&
                  (selectedReportType?.id === "S001" ||
                    selectedReportType?.id === "S007" || selectedReportType?.id === "S005" || selectedReportType?.id === "S008" || selectedReportType?.id === "S009" || selectedReportType?.id === "S011") &&
                  selectedReportType?.id !== "S002" &&
                  selectedReportType?.id !== "S003" &&
                  selectedReportType?.id !== "S010" &&
                  selectedReportType?.id !== "S006" ? (
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">{"Select Month"}</div>
                      <DynamicDropdown
                        data={monthData}
                        render="name"
                        value={selectedMonth?.name}
                        selectedValue={(data) => monthSelected(data)}
                        arrow={"true"}
                        height={"NotificationHeight"}
                        width={"w-8rem"}
                      />
                    </div>
                  ) : null}


                  {selectedReportType &&
                  selectedReportType?.id === "S005" &&
                  selectedReportType?.id !== "S001" &&
                  selectedReportType?.id !== "S002" &&
                  selectedReportType?.id !== "S003" &&
                  selectedReportType?.id !== "S004" &&
                  selectedReportType?.id !== "S010" &&
                  selectedReportType?.id !== "S006" ? (
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">
                        {"Select Employee Type"}
                      </div>
                      <DynamicDropdown
                        data={employeeTypeList}
                        render="name"
                        value={selectedEmployeeType?.name}
                        selectedValue={(data) => handleEmployeType(data)}
                        arrow={"true"}
                        height={"NotificationHeight"}
                        width={"w-20rem"}
                      />
                    </div>
                  ) : null}

                  {selectedReportType &&
                  selectedReportType?.id === "S004" &&
                  selectedReportType?.id !== "S001" &&
                  selectedReportType?.id !== "S002" &&
                  selectedReportType?.id !== "S003" &&
                  selectedReportType?.id !== "S010" &&
                  selectedReportType?.id !== "S006" ? (
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">
                        {"Select Scheme Type"}
                      </div>
                      <LargeDropdown
                        data={schemeList}
                        value={
                          ListScheme.label ? ListScheme.label : "Select..."
                        }
                        selectedValue={(data) => handleListScheme(data)}
                      />
                    </div>
                  ) : null}
                  {
                    showDivision ? 
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">
                        {"Select Division"}
                      </div>
                      <LargeDropdown
                        data={divisionData}
                        value={division?.label ? division.label : "Select..."}
                        selectedValue={(data) => handleDivision(data)}
                      />
                    </div>
                    :
                    null
                  }

                  {selectedReportType &&
                    selectedReportType?.id !== "S001" &&
                    selectedReportType?.id !== "S004" &&
                    selectedReportType?.id !== "S010" &&
                    selectedReportType?.id !== "S006" &&
                    (selectedReportType?.id === "S002" ||
                      selectedReportType?.id === "S003") ? (
                      <div className="flex-direction-column ml-3">
                        <div className="p-sub-heading ml-2">{"From Date"}</div>
                        <DateFilter
                          title=""
                          action={(val) => {setFromDate(val);setToDate('')}}
                          value={fromDate}
                          maxDate={moment(new Date()).format("YYYY-MM-DD")}
                          minDate={moment("2024-08-01").format("YYYY-MM-DD")}
                        />
                      </div>
                  ) : null}   

                  {selectedReportType &&
                  selectedReportType?.id !== "S001" &&
                  selectedReportType?.id !== "S006" &&
                  selectedReportType?.id !== "S004" &&
                  selectedReportType?.id !== "S010" &&
                  (selectedReportType?.id === "S002" ||
                    selectedReportType?.id === "S003") ? (
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">{"To Date"}</div>
                      <DateFilter
                        title=""
                        action={(val) => {setToDate(val)} }
                        value={toDate}
                        maxDate={moment(new Date()).format("YYYY-MM-DD")}
                        minDate={fromDate}
                      />
                    </div>
                  ) : null}

                  {selectedReportType &&
                  (selectedReportType?.id === "S001") ? (
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">{"Report Type"}</div>
                        <Select
                            isMulti
                            options={awardList}
                            value={viewFilter}
                            onChange={handleViewFilter}
                            className="basic-multi-select"
                            placeholder="Select"
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                        />
                    </div>
                  ) : null}

                 
                </div>
                <div>
                  <button
                    className="btn buttonStyle px-20px"
                    onClick={downloadReport}
                    disabled={checkValidity()}
                  >
                    {showBdcHistoryTable
                      ? "Generate History"
                      : !isExportBtn
                      ? "Generate Report"
                      : "Export to excel"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showBdcTable && bdcGenerationReport?.length > 0 ? (
        <div className="w-100 m-8px">
          <Table
            isSrNoShow={true}
            isActionShow={false}
            data={bdcGenerationReport}
            columns={bdcreportfield}
            multiSelect={false}
            className={"w-95vw ml-15px max-height-50vh"}
            // minHeight={200}
          />
        </div>
      ) : null}

      {showBdcHistoryTable && bdcHistoryReport?.length > 0 ? (
        <div className="sm-scroll-table p-2 w-99-percent ml-15px">
          <table className="table">
            <thead className="">
              <tr>
                <th scope="col">
                  <div className="thead pr-0" style={{ width: "45px" }}>
                    Sr. No
                  </div>
                </th>
                <th scope="col">
                  <div className="thead">Date</div>
                </th>
                <th scope="col">
                  <div className="thead">Action</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {bdcHistoryReport.map((listValue, index) => {
                let dateKey = Object.keys(listValue)[0]; 
                const [year, month, day] = dateKey.split("-");
                let dateKeyFormatted = `${day}-${month}-${year}`
                const entries = listValue[dateKey];
                return (
                  <tr key={index} className="">
                    <td>
                      <div className="td" style={{ width: 20 }}>
                        {index + 1}
                      </div>
                    </td>
                    <td>
                      <div className="td" style={{ width: "68%" }}>
                        {dateKeyFormatted}
                      </div>
                    </td>
                    <td className="d-flex">
                      <div
                        onClick={() => { sugReportType === "pdf" ? exportPDFData(entries[0].bdc_pdf_s3_path,entries[0].bdc_pdf_created_date,entries[0].sug_office) :
                          exportAllData(
                            Object.values(listValue)[0],
                            "Bdc_History_" + Object.keys(listValue)[0]
                          );
                        }}
                      >
                        <label className="tata-policy-link mr-4" type="button">
                          {sugReportType === "pdf" ? "Click to download in pdf" : "Click to download in excel"}
                        </label>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
}

export default SuggestionReport;
