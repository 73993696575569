import React, { useEffect, useState } from 'react'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import SuggestionOfficeHome from '../../adminScreens/suggestionScheme/suggestionOffice/SuggestionOfficeHome'
import SuperAdminHome from './superAdmin/SuperAdminHome'
import SuggestionReport from './report/SuggestionReport'
import SuggestionMasterScreen from './suggestionMasterScreen/SuggestionMasterScreen'
import { manualReferenceLink } from '../../employeeScreens/suggestionScheme/constants'

import rightArrow from '../../../../../src/assets/drishti_images/header/right-arrow-gif-unscreen.gif';
import { isApplicableForSuggestion, isApplicableForSuggestionAdmin } from '../../../../constants/api.service'
import { toaster } from '../../../../utils/Utils'
import { useSelector } from 'react-redux'
import DataNotFound from '../../../shared/notFound/DataNotFound'

function SuggestionSchemeHomeAdmin() {
  const [loading, setLoading] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [selectedCardStatus, setselectedCardStatus] = useState()
  const [isApplicableList, setIsApplicableList] = useState([])
  const AdminLoginData = useSelector(state => state.adminLoginState);

  const [ filteredTabs, setFilteredTabs ] = useState(null);

  const RoleWiseTabs = [
    { name: "Super Admin", component: <SuperAdminHome status={selectedCardStatus} />, type: 'is_sug_super_admin_applicable' },
    { name: "Suggestion Office", component: <SuggestionOfficeHome status={selectedCardStatus} />, type: 'is_sug_office_applicable' },
    { name: "Reports", component: <SuggestionReport />, type: 'is_sug_office_applicable' },
    { name: "Master Screen",component: <SuggestionMasterScreen/>, type: 'is_sug_office_applicable'}
    // { name: "Evaluator's Approval", component: <SuggestionOfficeHome /> },
    // { name: "Division Co-ordinator", component: <DivisionalApproval /> },

  ]


  const isApplicableForSuggestionCall = (page) => {
    setLoading(true)
    let params = {
      comp_code: AdminLoginData?.company?.company_code,
      pa: AdminLoginData?.userData?.PersArea
    }
    isApplicableForSuggestionAdmin(params)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          
          const responseData = res?.data?.data;
          
          setIsApplicableList(responseData)

          const filterList = RoleWiseTabs.filter( tab => responseData[tab.type] )

          setFilteredTabs(filterList);

        }
        else {
          setIsApplicableList([])
          toaster("warning", res?.data?.message)
        }
      })
      .catch((error) => {
        setIsApplicableList([])
        toaster("warning", error?.data?.message)
      })
      .finally(() => { setLoading(false) })
  }

  useEffect(() => {
    isApplicableForSuggestionCall();
  }, [])

  const toggleLocalTabs = (index) => {
    setActiveTabIndex(index)
  }
  const handleCardClick = (status) => {
    setselectedCardStatus(status)
    setActiveTabIndex(1)
  }


  const AllTabs = [
    // { name: "Super Admin", component: <SuperAdminHome status={selectedCardStatus} />,type: 'is_sug_super_admin_applicable' },
    // { name: "Suggestion Office", component: <SuggestionOfficeHome status={selectedCardStatus} />,type: 'is_sug_office_applicable' },
    // { name: "Report", component: <SuggestionReport status={selectedCardStatus} /> },
  ]

 
  return (
    <div className="row pb-0 pt-12px px-12px">
      <LoaderIndicator loading={loading} />
      <div className='col-md-12 justify-content-center align-item-center px-0'>
        <div className="col-md-12 card-new m-rem h-83vh">
          {filteredTabs?.length > 0 ?
            <>
              <div className='row justify-content-between'>
                <div className='row mt-3 ss'>
                  {filteredTabs?.map((tab, index) => {
                    return (
                      <div className={index == activeTabIndex ? "tab-active" : "tab-inactive"} onClick={() => toggleLocalTabs(index)}>
                        {tab.name}
                      </div>
                    )
                  })}
                </div>
                <div className='d-flex row align-item-center'>
                  <img
                    src={rightArrow}
                    alt="rightArrow"
                    className="right-arrow-gif"
                  />
                  <a href={manualReferenceLink[filteredTabs[activeTabIndex]?.name]} target="_blank"><label className='tata-policy-link mr-4' type='button'>{'User Manual'}</label></a>
                </div>
              </div>
              <div className='mt-3px'><hr /></div>
              <div className='w-100 h-90 scroll-y'>
                {filteredTabs?.[activeTabIndex]?.component}
              </div>
            </>
            : <><DataNotFound /></>}
        </div>
      </div>
    </div>
  )
}

export default SuggestionSchemeHomeAdmin