import React, { useState, useEffect, } from 'react'
import EvaluatorApprovals from '../../managerScreens/suggestionScheme/evaluator/EvaluatorApprovals'
import DivisionalApproval from '../../managerScreens/suggestionScheme/divisionalCoordinator/divisionalApproval'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import SuggestionLandingScreen from './suggester/SuggestionLandingScreen'
import SuggestionDashboard from './suggester/SuggestionDashboard'
import { isApplicableForSuggestion } from '../../../../constants/api.service'
import { toaster } from '../../../../utils/Utils'
import { useSelector } from 'react-redux'
import ServiceUnavailable from '../../../shared/ServiceUnavailable'
import rightArrow from '../../../../../src/assets/drishti_images/header/right-arrow-gif-unscreen.gif';
import { manualReferenceLink } from './constants'
import SuggestionSchemeReport from '../../managerScreens/suggestionScheme/Reports/SuggestionSchemeReport'



function SuggestionSchemeHome() {
  const [loading, setLoading] = useState(false)

  const [isServiceUnavailable, setServiceUnavailable] = useState(false)
  const [isServiceUnavailableReason, setServiceUnavailableReason] = useState('')

  const [isSuggestorScreen, setSuggestorScreen] = useState(false)
  const [isSuggestorScreenReason, setSuggestorScreenReason] = useState('')

  const [isSuggestorAddForm, setSuggestorAddForm] = useState(false)

  const [isEvaluatorScreen, setEvaluatorScreen] = useState(false)
  const [isEvaluatorScreenReason, setEvaluatorScreenReason] = useState('')

  const [isDivisionalScreen, setDivisionalScreen] = useState(false)
  const [iDivisionalScreenReason, setDivisionalScreenReason] = useState('')

  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [selectedCardStatus, setselectedCardStatus] = useState(0)
  const [isApplicableList, setIsApplicableList] = useState([])

  const [ filteredTabs, setFilteredTabs ] = useState(null);

  const loginData = useSelector(state => state.loginState);
  const serviceData = useSelector(state => state.activePopupState);
  
  useEffect(() => {
    checkService()
    isApplicableForSuggestionCall()
  }, [])

  const checkService = () => {
    serviceData.availableServiceData.forEach((item, i) => {
      if (item.service_type == "suggestion_scheme" && item.is_active == false) {
        setServiceUnavailable(true);
        setServiceUnavailableReason(item?.reason);
      }
      if (item.service_type == "suggestor_screen" && item.is_active == false) {
        setSuggestorScreen(true);
        setSuggestorScreenReason(item?.reason);
      }
      if (item.service_type == "suggestor_add_request" && item.is_active == false) {
        setSuggestorAddForm(true);
      }
      if (item.service_type == "evaluator_screen" && item.is_active == false) {
        setEvaluatorScreen(true);
        setEvaluatorScreenReason(item?.reason);
      }
      if (item.service_type == "division_coordinator_screen" && item.is_active == false) {
        setDivisionalScreen(true);
        setDivisionalScreenReason(item?.reason);
      }
    });
  };
  

  const isApplicableForSuggestionCall = (page) => {
    setLoading(true)
    let params = {
      comp_code: loginData.userData.CompCode,
      pa: loginData.userData.PersArea
    }
    isApplicableForSuggestion(params)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsApplicableList(res?.data?.data)
          const filterList = RoleWiseTabs.filter( tab => res?.data?.data[tab.type] )
          setFilteredTabs(filterList);
        }
        else {
          setIsApplicableList([])
          toaster("warning", res?.data?.message)
        }
      })
      .catch((error) => {
        setIsApplicableList([])
        toaster("warning", error?.data?.message)
      })
      .finally(() => { setLoading(false) })
  }

  const toggleLocalTabs = (index) => {
    setselectedCardStatus(0)
    setActiveTabIndex(index)
  }
  const handleCardClick = (status) => {
    const statusIds = status;
    setselectedCardStatus(statusIds.id);
    setActiveTabIndex(1);
  }

  const setToggileTab = (index) => {
    setActiveTabIndex(1)
  }

  const AllTabs = [
    { name: "Employee Suggestion", component: <SuggestionLandingScreen setTab={setToggileTab} onCardClick={handleCardClick} isSuggestorScreen={isSuggestorScreen} isSuggestorScreenReason={isSuggestorScreenReason} isSuggestorAddForm={isSuggestorAddForm} /> },
    { name: "Suggestor Dashboard", component: <SuggestionDashboard status={selectedCardStatus} setselectedCardStatus={setselectedCardStatus} isSuggestorAddForm={isSuggestorAddForm} /> },
  ]

  const RoleWiseTabs = [
    { name: "Evaluator Dashboard", component: <EvaluatorApprovals isEvaluatorScreen={isEvaluatorScreen} isEvaluatorScreenReason={isEvaluatorScreenReason} />,type: 'is_sug_eval_applicable' },
    { name: "Divisional Co-ordinator Dashboard", component: <DivisionalApproval isDivisionalScreen={isDivisionalScreen} iDivisionalScreenReason={iDivisionalScreenReason}/>, type: 'is_sug_div_applicable' },
    { name: "Reports", component: <SuggestionSchemeReport />, type: 'is_sug_div_applicable' },
  ]

  return (
    <div>
      {isServiceUnavailable ?
        <div className="col-md-12 h-100vh justify-content-center align-item-center">
          <ServiceUnavailable reason={isServiceUnavailableReason} width={true} />
        </div>
        :
        <div className="row pb-0 pt-12px pr-3">
          <LoaderIndicator loading={loading} />
          <div className='col-md-12 justify-content-center align-item-center px-0'>
            <div className="col-md-12 card-new m-rem h-83vh">
              <div className='row justify-content-between'>
                <div className='row mt-3 ss'>
                  {AllTabs.concat(RoleWiseTabs.filter((screen) => isApplicableList[screen.type] == true)).map((tab, index) => {
                    return (
                      <div className={index == activeTabIndex ? "tab-active" : "tab-inactive"} onClick={() => toggleLocalTabs(index)}>
                        {tab.name}
                      </div>
                    )
                  })}
                </div>
                <div className='d-flex row align-item-center'>
                  <img
                    src={rightArrow}
                    alt="rightArrow"
                    className="right-arrow-gif"
                  />
                  <a href={manualReferenceLink[AllTabs.concat(RoleWiseTabs)?.[activeTabIndex]?.name]} target="_blank"><label className='tata-policy-link mr-4' type='button'>{'User Manual'}</label></a>
                </div>
              </div>
              <div className='mt-3px'><hr /></div>
              <div className='w-100 h-90'>
                {AllTabs.concat(filteredTabs)?.[activeTabIndex]?.component}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default SuggestionSchemeHome