import React, { useEffect, useState } from "react";
import DynamicDropdown from "../../../../shared/components/DynamicDropdown";
import { exportToExcel, toaster } from "../../../../../utils/Utils";
import { suggestionReportFieldVisiblity } from "./constant";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
// import moment from "moment";
import LoaderIndicator from "../../../../shared/loader/LoaderIndicator";
import {
    getMangerReportList,
    getDivisionalAgencyDashboard,
    get_agewise_pending_report
} from "../../../../../constants/api.service";
import LargeDropdown from "../../../../shared/components/LargeDropdown";
import * as XLSX from 'xlsx';

function SuggestionSchemeReport() {
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [loader, setLoader] = useState(false);
  const [reportType, setReportType] = useState([]);
  const [showDivison,setShowDivison] = useState(false);
  const [showToDate,setshowToDate] = useState(false);
  const [showFromDate,setShowFromDate] = useState(false);
  const [division,setDivision] = useState(null);
  const [toDate,setToDate] = useState(null);
  const [fromDate,setFromDate] = useState(null);
  const [divisionData,setDivisionData] = useState([]);
  const loginData = useSelector(state => state.loginState);
  const today = new Date().toISOString().split("T")[0];

  const exportAllData = (exportData, name) => {
    if (exportData) {
      let BdcRecords = exportData;
      let colNames = [
        ...new Set(BdcRecords.flatMap((obj) => Object.keys(obj))),
      ];
      colNames = [colNames];
      if (BdcRecords.length == 0) {
        toast.warn("No Data found", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      } else {
        exportToExcel(name, ".xlsx", BdcRecords, colNames);
        toaster("success", "Report downloaded successfully");
      }
    }
  };

  const reportTypeSelected = (data) => {
    setSelectedReportType(data);
    const filterData = suggestionReportFieldVisiblity[data.id]
    setShowDivison(filterData.showDivision);
    setshowToDate(filterData.showToDate);
    setShowFromDate(filterData.showFromDate);
  };

  const handleDivision = (data) => {
    setDivision(data);
  };
  
  const handleStartDate = (date) =>{
    setToDate(date);
    setFromDate(null);

  }

  const handleEndDate = (date) =>{
    setFromDate(date);
  }

  // ---------------------Get Report List Details------------ //
  const getReportListDetail = () => {
    setLoader(true);
    const param = {
      sug_role: "Division_Cooardinator",
    };

    getMangerReportList(param)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const reportList = res?.data?.data?.map((item) => ({
            name: item.report_name,
            id: item.report_code,
          }));
          setReportType(reportList);
        } else {
          setReportType([]);
          res?.status !== 404 && toaster("warning", res?.data?.message ?? "someThingWentWrong")
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // ---------------------Get Division Details------------ //
  const getDivision = () => {
    setLoader(true);
    const param = {
      comp_code: loginData?.userData?.CompCode,
      pa: loginData?.userData?.PersArea,
    };
    getDivisionalAgencyDashboard(param)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const division = res?.data?.data?.map((division) => ({
            id: division?.division_code,
            label: division?.division_name,
          }));
          setDivisionData(division);
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
          setDivisionData([]);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getReportListDetail();
    getDivision();
  }, []);

  // ---------------------Get Age Wise Pending Report------------ //
  const getAgewisePendingReport = async()=>{
    try {
      setLoader(true);
      const param = {
          division_code : division?.id
      };
      const res = await get_agewise_pending_report(param);
      if (res?.status == 200 || res?.status == 201) {
        const resultSet = res?.data?.data
        const contentDisposition = res.headers['content-disposition'];
            let filename = 'Age_Wise_Pending_Report.xlsx'; 

            if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
                const matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, ''); 
                }
            }
        const ws = XLSX.utils.json_to_sheet(resultSet);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Evaluation Data");
        XLSX.writeFile(wb, filename);
        toast.success(res?.data?.message || "Report Generated Successfully", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastSuccess",
        });
      } else {
        toast.warn(res?.data?.message || "Something went wrong", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      }
  } catch (errors) {
      toast.warn(errors.message || "An error occurred.", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
      });
  } finally {
      setLoader(false);
  }
  }
  
  const downloadReport = () => {
    if (selectedReportType?.id === "D001") {
      if(division){
        getAgewisePendingReport()
      }
      
    }
  }



  return (
    <div>
      <LoaderIndicator loading={loader} />
      <div className="ml-10 h-90">
        <div>
          <div className="gap-20">
            <div className="d-flex flex-direction-column">
              <div className="flex-direction-row justify-content-between align-item-center filter">
                <div className="d-flex flex-direction-row mx-2 align-items-center">
                  <div className="flex-direction-column ml-3">
                    <div className="p-sub-heading ml-2">
                      {"Select Report Type"}
                    </div>
                    <DynamicDropdown
                      data={reportType}
                      render="name"
                      value={selectedReportType?.name}
                      selectedValue={(data) => reportTypeSelected(data)}
                      arrow={"true"}
                      height={"NotificationHeight"}
                      width={"w-20rem"}
                    />
                  </div>

                  {
                    showDivison ? 
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">
                        {"Select Division"}
                      </div>
                      <LargeDropdown
                        data={divisionData}
                        value={division?.label ? division.label : "Select..."}
                        selectedValue={(data) => handleDivision(data)}
                      />
                    </div>
                    :
                    null
                  }

                  {
                    showToDate ?
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">
                        {"To Date"}
                      </div>
                      <input
                        className="form-control p-placeholder"
                        type="date"
                        value={toDate || ""}
                        style={{ height: "38px" }}
                        onChange={(e) =>
                          handleStartDate(e.target.value)
                        }
                        min={today}
                      />
                    </div>
                    :
                    null
                  }

                  {
                    showFromDate ?
                    <div className="flex-direction-column ml-3">
                      <div className="p-sub-heading ml-2">
                        {"To Date"}
                      </div>
                      <input
                        className="form-control p-placeholder"
                        type="date"
                        value={fromDate || ""}
                        style={{ height: "38px" }}
                        onChange={(e) =>
                          handleEndDate(e.target.value)
                        }
                        min={today}
                      />
                    </div>
                    :
                    null

                  }


                 
                </div>
                <div>
                  <button
                    className="btn buttonStyle px-20px"
                    onClick={downloadReport}
                    // disabled={checkValidity()}
                  >
                    Generate Report
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuggestionSchemeReport;
