export const masterScreenList = [
   { key: 'SchemeList', value: 'Special Scheme', endPoint: '' },
]
 
export const suggestionMasterScreen = {
    Sr: 'Sr No.',
    SchemeName: 'Scheme Name',
    StartDate: 'Start Date',
    EndDate: 'End Date',
    // keys
    SchemeList: 'SchemeList'
}

export const minImageHeight = 160;
export const maxImageHeight = 250;
export const minImageWidth = 360;
export const maxImageWidth = 450;
