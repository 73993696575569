import React, { useEffect, useRef, useState } from 'react'
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { historyDataFields, memberDataFields, suggestionDataFields, suggestionDataFieldsConditinal, SuggestionOfficeActions, schemeCode } from '../../../employeeScreens/suggestionScheme/constants';
import { DDMMYYYY, toaster } from '../../../../../utils/Utils';
import Table from './Table';
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';
import DataNotFound from '../../../../shared/notFound/DataNotFound';
import { suggetionOfficerSuggestionById, get_division, getMasterAwardAdmin, getMasterAwardCategoryAdmin, getMasterNatureOfSavingAdmin, getMasterSavingCategoryAdmin, suggestionDocAttachmentAdmin, updateSuggestionOfficeAction, deleteS3DocumentAdmin, approveBySuggestionOffice, deleteSavingDocumentsAdmin, deleteSavingDetailsAdmin } from '../../../../../constants/api.service';
import { useSelector } from 'react-redux';
import CommonPreviewComponent from '../../../employeeScreens/suggestionScheme/commonComponent/CommonPreviewComponent';
import moment from 'moment';
import { SuggestionOfficeAttachmentInput } from './SuggestionOfficeAttachmentInput';
import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg'

function SuggestionOfficeDetailPopup(props) {
  const { title, backAction, selectedRecordId, from_agency, suggestionStatus, data } = props
  const [loading, setLoading] = useState(false)
  const [selectedAction, setSelectedAction] = useState(null)
  const [selectedAwardType, setSelectedAwardType] = useState(null)
  const [actionError, setActionError] = useState(null)
  const [rejectionReason, setRejectionReason] = useState(null)
  const [clarificationRemark, setClarificationRemark] = useState(null)
  const [suggOfficeComments, setsuggOfficeComments] = useState(null)
  const [isSuggestionImplementable, setIsSuggestionImplementable] = useState(null)
  const [isMonetary, setIsMonetary] = useState(null)
  const [awardAmt, setAwardAmt] = useState(null)
  const [ideaAwardAmt, setIdeaAwardAmt] = useState(null)
  const [benefitsInBrief, setBenefitsInBrief] = useState(null)
  const [implementationInBrief, setImplementationInBrief] = useState(null)
  const [implementationDate, setImplementationDate] = useState(null)
  const [attachments, setAttachments] = useState([])
  const [attachmentsSubmit, setAttachmentsSubmit] = useState([])
  const [suggestionSelectedData, setSuggestionSelectedData] = useState(null)
  const [addSavingDetails, setAddSavingDetails] = useState(false)
  const [awardList, setAwardList] = useState([])
  const [awardCategoryList, setAwardCategoryList] = useState([])
  const [savingCategoryList, setSavingCategoryList] = useState([]);
  const [natureOfSavingList, setNatureOfSavingList] = useState([]);
  const [selectedSavingCategory, setSelectedSavingCategory] = useState(null);
  const [selectedSavingType, setSelectedSavingType] = useState(null);
  const [enteredSavingAmount, setEnteredSavingAmount] = useState(null);
  const [expenseDetails, setExpenseDetails] = useState([]);
  const [error, setError] = useState(null);
  const [divisionDetails, setDivisionDetails] = useState({
    division: null,
    division_id: null
  })
  const [agencyDetails, setAgencyDetails] = useState({
    agency: null,
    agency_id: null
  })
  const [divisionData, setDivisionData] = useState([])
  const [checkBoxSelect, setcheckBoxSelect] = useState(false)
  const [ideaImplementation, setIdeaImplementation] = useState(null)
  const [filteredActions, setFilteredActions] = useState([])
  const loginData = useSelector(state => state.loginState);
  const actionRef = useRef()
  const awardRef = useRef()
  const finalAwardRef = useRef()
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    getSuggestionByIdData(selectedRecordId)
  }, [])

  useEffect(() => {
    if (selectedSavingCategory && selectedSavingType && enteredSavingAmount) {
      setIsButtonEnabled(true);
    } else { setIsButtonEnabled(false); }
  }, [selectedSavingCategory, selectedSavingType, enteredSavingAmount]);

  useEffect(() => {
    if (suggestionSelectedData?.suggestion_details?.is_suggestion_implementable !== undefined) {
      setIsSuggestionImplementable(suggestionSelectedData?.suggestion_details?.is_suggestion_implementable === true ? "yes" : suggestionSelectedData?.suggestion_details?.is_suggestion_implementable === false ? "no" : null);
    }
  }, [suggestionSelectedData]);


  const handleFilterDropdown = (sendBack) => {
    const value = SuggestionOfficeActions?.filter((ele) => sendBack ? ele.value !== 'Send_back' : ele)
    setFilteredActions(value)
  }
  const handleAwardAmtChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    value ? setAwardAmt(value) : setAwardAmt('')
    setError(
      value === '' ? '' : value < 100 || value > 25000 ? 'Award amount must be between 100 and 25,000.' : '');
  };

  const handleActionFilter = (data) => {
    setActionError(null)
    setSelectedAction(data.name)
    getMasterAwardCall()
    getMasterAwardCategoryCall()
    getMasterSavingCategoryCall()
    getMasterNatureOfSavingCall()
    setIsMonetary(null)
    setRejectionReason(null);
    setClarificationRemark(null)
    setAgencyDetails({
      agency: null,
      agency_id: null
    })
    setDivisionDetails({
      division: null,
      division_id: null
    })
    setcheckBoxSelect(true ? false : false)
    if (data.name === 'Send Suggestion to Other Evaluator' && divisionData.length < 1) {
      handleDivisionDropdown()
      setRejectionReason(null);
      setsuggOfficeComments(null);
      setClarificationRemark(null);
      setDivisionDetails({
        division: null,
        division_id: null
      })
    } else if (data.name === 'Reject') {
      setsuggOfficeComments(null);
      setClarificationRemark(null);
      setDivisionDetails({
        division: null,
        division_id: null
      })
    } else if (data.name === 'Send Back') {
      setsuggOfficeComments(null);
      setDivisionDetails({
        division: null,
        division_id: null
      })
    }
  }

  const getSuggestionByIdData = (id) => {
    setLoading(true)
    suggetionOfficerSuggestionById(id)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setSuggestionSelectedData(data?.data ? data?.data : null);
          setAttachments(data?.data?.document_details.filter((e) => e.document_category === 'saving_details'))
          setExpenseDetails(data?.data?.saving_details);
          handleFilterDropdown(data?.data?.suggestion_details?.is_suggestion_office_send_back);
          setSelectedAction('Approve')
          getMasterAwardCall()
          getMasterSavingCategoryCall()
          getMasterAwardCategoryCall()
          getMasterNatureOfSavingCall()
          if (data?.data?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === "finalaward") {
            setImplementationInBrief(data?.data?.eval_details[0]?.implementation_brief ?? null)
            setBenefitsInBrief(data?.data?.eval_details[0]?.benefits ?? null)
            setSelectedAwardType({
              award_name: data?.data?.eval_details[0].award_type_name ?? null
            })
            setIsMonetary({ award_category: data?.data?.eval_details[0]?.award_category_name, id: data?.data?.eval_details[0]?.award_category })
            setAwardAmt(data?.data?.eval_details[0]?.amount ?? null)
            setImplementationDate(data?.data?.eval_details[0]?.implemented_date)
          } else if (data?.data?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === "ideaaward") {
            setIdeaImplementation(data?.data?.eval_details[0]?.implementation_brief ?? null)
            setIsMonetary({ award_category: data?.data?.eval_details[0]?.award_category_name, id: data?.data?.eval_details[0]?.award_category })
            setBenefitsInBrief(data?.data?.eval_details[0]?.benefits ?? null)
            setSelectedAwardType(data?.data?.eval_details[0].award_type_name ?? null)
            setIdeaAwardAmt(data?.data?.eval_details[0]?.amount ?? null)
            setImplementationDate(data?.data?.eval_details[0]?.implemented_date)
          }
          if (data?.data?.saving_details.length) {
            setAddSavingDetails(true)
          }
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => { setLoading(false) })
  }

  const handleAwardType = (data) => {
    if (data?.award_name?.toLowerCase().replace(/\s/g, '') === 'ideaaward') {
      setIsSuggestionImplementable(null)
      setIdeaImplementation(suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === 'ideaaward' ? suggestionSelectedData.eval_details[0].implementation_brief : '')
      setIdeaAwardAmt(suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === 'ideaaward' ? suggestionSelectedData.eval_details[0].amount : '')
      setImplementationDate(suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === 'ideaaward' ? data?.data?.eval_details[0]?.implemented_date : '')
      setSelectedSavingCategory(null);
      setEnteredSavingAmount(null);
      setSelectedSavingType(null);
      setExpenseDetails([])
      setAttachments([])
      setBenefitsInBrief(suggestionSelectedData.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === 'ideaaward' ? suggestionSelectedData.eval_details[0].benefits : null);
    } else if (data?.award_name?.toLowerCase().replace(/\s/g, '') === 'finalaward') {
      setImplementationInBrief(suggestionSelectedData?.eval_details[0]?.award_type_name.toLowerCase().replace(/\s/g, '') === 'finalaward' ? suggestionSelectedData?.eval_details[0]?.implementation_brief : '')
      setAwardAmt(suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === 'finalaward' ? suggestionSelectedData?.eval_details[0]?.amount : '')
      setImplementationDate(suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === 'finalaward' ? data?.data?.eval_details[0]?.implemented_date : '')
      setBenefitsInBrief(suggestionSelectedData?.eval_details[0]?.award_type_name.toLowerCase().replace(/\s/g, '') === 'finalaward' ? suggestionSelectedData?.eval_details[0]?.benefits : null);
    }
    suggestionSelectedData.saving_details.length ? setAddSavingDetails(true) : setAddSavingDetails(false)
    setActionError(null)
    setSelectedAwardType(data)
  }
  const handleMonetaryOption = (data) => {
    setActionError(null)
    setAwardAmt(null)
    setIdeaAwardAmt(null)
    setIsMonetary(data)
  }
  const handleOption = (e) => { setIsSuggestionImplementable(e.target.value) }

  const addAttachment = (data) => {
    setLoading(true)
    let req_body = {
      emp_id: suggestionSelectedData?.suggestion_details?.entered_by ?? '',
      attachment: data?.attachment_presigned_url,
      attachment_name: data?.name,
      attachment_type: data?.extension,
      created_datetime: new Date().toISOString(),
    }
    suggestionDocAttachmentAdmin(req_body, "POST", props, "suggestion_document")
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setAttachments([...attachments,
          {
            attachment_presigned_url: res?.data?.data?.attachment_presigned_url,
            name: res?.data?.data?.attachment_name,
            type: null,
            document_category: "saving_details",
            extension: res?.data?.data?.attachment_extension,
            path: res?.data?.data?.attachment_path,
            role: "Suggestion_office",
            created_datetime: new Date().toISOString(),
          }
          ])
          setAttachmentsSubmit([...attachmentsSubmit,
          {
            // id: data.id,
            name: res?.data?.data?.attachment_name,
            type: null,
            document_category: "saving_details",
            extension: res?.data?.data?.attachment_extension,
            path: res?.data?.data?.attachment_path,
            role: "Suggestion_office",
            created_datetime: new Date().toISOString(),
          }])
          toaster("success", res.data.message)
        }
        else { toaster("warning", res?.data?.message ?? "something went wrong!") }
      })
      .catch((error) => { toaster("error", error?.data?.message ?? "SomeThing Went Wrong") })
      .finally(() => { setLoading(false) })
  }

  const deleteAttachment = (data, index) => {
    // setLoading(true)
    const updatedDocuments = [...attachments];
    const deletedDocument = updatedDocuments[index];
    if (deletedDocument.id) {
      deleteDocumentExisting(deletedDocument.id, updatedDocuments, index)
      setLoading(false)
    } else {
      deleteAttachments(deletedDocument, updatedDocuments, index)
    }
  }

  const deleteAttachments = (selectedDocuments, documents, index) => {
    setLoading(true)
    deleteS3DocumentAdmin(selectedDocuments).then((res) => {
      if (res.status === 200 || res.status === 201) {
        documents.splice(index, 1);
        setAttachments(documents);
        toaster("success", res?.data?.message ? res?.data?.message : "")
      } else {
        res?.status !== 404 && toaster("warning", res?.message ? res?.message : "")
      }
    }).catch((error) => {
      error?.status !== 404 && toaster("error", error?.data?.message ? error?.data?.message : "")
    }).finally(() => { setLoading(false) })
  }

  const deleteDocumentExisting = (id, documents, index) => {
    setLoading(true)
    deleteSavingDocumentsAdmin(id).then((res) => {
      if (res.status === 200 || res.status === 201) {
        documents.splice(index, 1);
        setAttachments(documents);
        toaster("success", res.data.message ? res.data.message : "")
      } else {
        res?.status !== 404 && toaster("warning", res.message ? res.message : "")
      }
    }).catch((error) => {
      error?.status !== 404 && toaster("error", error?.data?.message ? error.data.message : "")
    }).finally(() => { setLoading(false) })
  }

  const handleDivisionDropdown = () => {
    setLoading(true)
    let param = {
      comp_code: loginData.userData.CompCode,
      pa: loginData.userData.PersArea
    };
    get_division(param).then((res) => {
      if (res.status === 200) {
        setDivisionData(res?.data?.data?.division_list)
      } else {
        toaster("warning", res.data.message)
      }
    }).catch((err) => {
      console.log("error", err?.data?.message ?? "Something Went Wrong")
    }).finally(() => { setLoading(false) })
  }

  const handleBtnAction = () => {
    setLoading(true)
    let payload = {
      action: selectedAction === 'Reject' ? 'Reject' : 'Send_back',
      sug_ids: [selectedRecordId],
      from_agency_name: suggestionSelectedData?.suggestion_details?.sug_office_name,
      to_agency_name: selectedAction === 'Reject' ? null : selectedAction == 'Send Back' ? suggestionSelectedData?.suggestion_details?.division_name : null,
      comment: selectedAction === 'Reject' ? rejectionReason : clarificationRemark,
      from_status: selectedAction == 'Send Back' || selectedAction == 'Reject' ? 2 : null,
      from_role: "Suggestion_office",
      to_status: selectedAction == 'Send Back' ? 1 : selectedAction == 'Reject' ? 9 : null,
      to_role: selectedAction == 'Send Back' ? 'Divisional_Coordinator' : null,
    }

    updateSuggestionOfficeAction(payload).then((res) => {
      if (res) {
        if (res.status === 200) {
          toaster("success", res?.data?.message);
          backAction();
        } else {
          toaster("warning", res?.data?.message)
        }
      }
    }).catch((err) => {
      toaster("error", err?.data?.message ?? "Something Went Wrong")
    }).finally(() => { setLoading(false) })
  }

  const getMasterAwardCall = () => {
    setLoading(true)

    if (suggestionSelectedData) {
      let params = {
        suggestion_scheme_type: suggestionSelectedData?.suggestion_details?.scheme_type ? suggestionSelectedData?.suggestion_details?.scheme_type : null
      }
      getMasterAwardAdmin(params)
        .then((res) => {
          const { status, data } = res
          if (status === 200 || status === 201) {
            setAwardList(data?.data ? data?.data?.award_list : [])
            setSelectedAwardType(data?.data?.award_list?.filter((e) => e.award_name === suggestionSelectedData?.eval_details[0]?.award_type_name)[0] ?? null)
          }
          else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
        })
        .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
        .finally(() => {
          setLoading(false)
        })
    }

  }

  useEffect(() => {
    getMasterAwardCall();
  }, [suggestionSelectedData?.suggestion_details?.scheme_type])

  const getMasterAwardCategoryCall = () => {
    setLoading(true)
    getMasterAwardCategoryAdmin()
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setAwardCategoryList(data?.data ? data?.data?.award_category_list : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const getMasterSavingCategoryCall = () => {
    setLoading(true)
    getMasterSavingCategoryAdmin()
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setSavingCategoryList(data?.data ? data?.data?.saving_category_list : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const getMasterNatureOfSavingCall = () => {
    setLoading(true)
    getMasterNatureOfSavingAdmin()
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setNatureOfSavingList(data?.data ? data?.data?.nature_of_saving_list : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const addSuggestion = async () => {
    setLoading(true)

    let body = {
      current_role: "Suggestion_office",
      from_role: "Suggestion_office",
      to_role: "Super_Admin",
      action: "Approved",
      to_status: 3,
      from_status: 2,
      from_agency_name: suggestionSelectedData?.suggestion_details?.sug_office_name,
      to_agency_name: suggestionSelectedData?.suggestion_details?.sug_office_name,
      role: "Suggestion_office",
      comment: suggOfficeComments ? suggOfficeComments : null,
      existing: {
        suggestion_details: {
          id: selectedRecordId,
          is_suggestion_implementable: isSuggestionImplementable == "yes" ? true : isSuggestionImplementable == "no" ? false : null,
        },
        eval_details: [
          {
            award_type_name: selectedAwardType?.award_name,
            award_category_name: isMonetary?.award_category,
            amount: awardAmt ? awardAmt : ideaAwardAmt ? ideaAwardAmt : null,
            // from_agncy_name:suggestionSelectedData?.suggestion_details?.sug_office_name,
            // to_agency_name:suggestionSelectedData?.suggestion_details?.sug_office_name,
            implemented_date: (suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Normal || suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Special) && selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') === 'finalaward' ? moment(implementationDate).format('YYYY-MM-DD') :
              (suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode['Special Implemented'] || suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Implemented) &&
                selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') === 'finalaward' ? suggestionSelectedData?.suggestion_details?.implemented_date : null,
            implementation_brief: selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') !== "ideaaward" ? implementationInBrief : ideaImplementation,
            benefits: benefitsInBrief,
            role: "Suggestion_office",
            award_type: selectedAwardType?.id,
            award_category: isMonetary?.id,
          }
        ],
        saving_details: expenseDetails,
        document_details: attachments
      }
    }
    await approveBySuggestionOffice(body, suggestionSelectedData?.suggestion_details.id)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toaster("success", res?.data?.message ?? "Update Details Successfully");
          backAction();
        }
        else {
          res?.status !== 404 && toaster("warning", res?.data?.message ? res.data.message : "")
        }
      })
      .catch((error) => {
        console.log("error in get Edit request API", error)
        error?.status !== 404 && toaster("error", error?.data?.message ? error.data.message : "")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onAddSavingDetails = () => {
    const newRecord = {
      saving_category_name: selectedSavingCategory.saving_category_name,
      nature_of_saving_name: selectedSavingType.nature_of_saving_name,
      saving_category: selectedSavingCategory.id,
      nature_of_saving: selectedSavingType.id,
      eval_amount: enteredSavingAmount,
      role: "Suggestion_office",
      created_datetime: new Date().toISOString(),
    }

    setExpenseDetails([...expenseDetails, newRecord]);
    // setExpenseDetailsId([...expenseDetailsId, newRecordId]);
    setSelectedSavingCategory(null);
    setSelectedSavingType(null);
    setEnteredSavingAmount('');
  };

  const deleteSavingDetailsCall = (id, index) => {
    setLoading(true)
    const param = {
      sug_id: suggestionSelectedData?.suggestion_details?.id
    }
    deleteSavingDetailsAdmin(id, param)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          const updatedExpenseDetails = expenseDetails.filter((_, i) => i !== index);
          setExpenseDetails(updatedExpenseDetails);
          toaster("success", res?.data?.message ?? "Deleted Record Successfully")
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleDelete = (index) => {
    const updatedExpenseDetail = expenseDetails[index];

    if (updatedExpenseDetail?.id != undefined) {
      deleteSavingDetailsCall(updatedExpenseDetail?.id, index)
    } else {
      const updatedExpenseDetails = expenseDetails.filter((_, i) => i !== index);
      setExpenseDetails(updatedExpenseDetails);
    }
  };

  const keys = ['id', 'title', 'benefits', 'annual_saving', 'scheme_type_name', 'comment', 'applicable_areas', 'clarification_to_divc', 'applicable_areas', 'entered_on','implemented_date', 'scheme_name']

  if ((suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Implemented || suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode['Special Implemented'])) {
    keys.push('earlier_status', 'changes_implemented', 'idea_bank_reference')
  }
  else {
    keys.push('present_status', 'proposed_changes')
  }

  const renderFields = (fields) => (
    fields.length > 0 ? (
      fields.map((field) => {
        const { name, key, type } = field;

        if (key === 'scheme_name') {
          return (suggestionSelectedData?.suggestion_details['scheme_type_code'] === schemeCode.Special || suggestionSelectedData?.suggestion_details['scheme_type_code'] === schemeCode['Special Implemented']) && (
            <>
              {keys.includes(key) &&
                <div className="col-4" key={key}>
                  <div className="text14_bold">{name}</div>
                  <div className={keys.includes(key) ? " text14_light mt-1 popup-overflow " : ' text14_light mt-1'}>
                    {type === 'date' ? DDMMYYYY(suggestionStatus === 'Pending at Suggestion Office' ? suggestionSelectedData?.suggestion_details[key] : suggestionSelectedData?.suggestion_details[key]) ?? '--' : suggestionSelectedData?.suggestion_details[key] ?? '--'}
                  </div>
                  <div className='mt-10px mb-10px'><hr /></div>
                </div>
              }
            </>
          );
        }
        else {
          return (
            <>
              {keys.includes(key) &&
                <div className="col-4" key={key}>
                  <div className="text14_bold">{name}</div>
                  <div className={keys.includes(key) ? " text14_light mt-1 popup-overflow " : ' text14_light mt-1'}>
                    {type === 'date' ? DDMMYYYY(suggestionStatus === 'Pending at Suggestion Office' ? suggestionSelectedData?.suggestion_details[key] : suggestionSelectedData?.suggestion_details[key]) ?? '--' : suggestionSelectedData?.suggestion_details[key] ?? '--'}
                  </div>
                  <div className='mt-10px mb-10px'><hr /></div>
                </div>
              }
            </>
          );
        }

      })
    ) : (
      <DataNotFound />
    )
  );

  const handleChange = (e) => {
    const value = e.target.value;
    setIdeaImplementation('');
    if (value === '') {
      setImplementationInBrief(null);
    } else {
      setImplementationInBrief(value);
    }
  };

  const handleIdeaChange = (e) => {
    const value = e.target.value;
    setBenefitsInBrief(null);
    if (value === '') {
      setIdeaImplementation(null);
    } else {
      setIdeaImplementation(value);
    }
  };

  const handleChangeBenifit = (e) => {
    const value = e.target.value;
    setBenefitsInBrief(suggestionSelectedData?.eval_details[0]?.implementation_brief);
    if (value === '') {
      setBenefitsInBrief(null);
    } else {
      setBenefitsInBrief(value);
    }
  };

  return (
    <div className={"modal popup-box "}>
      <LoaderIndicator loading={loading} />
      <div
        className={
          "modal-dialog modal-dialog-centered modal-dialog-scrollable w-60rem"
        }
      >
        <div
          className={
            `modal-content bg-white border_radius_12px max-height-100 ` +
            `w-85vw`
          }
        >
          <div className="modal-header mx-2">
            <div className="row">
              <h4 className="popup-title mt-2">{title}</h4>
            </div>
            <button
              type="button"
              className="close pt-4"
              data-dismiss="modal"
              onClick={backAction}
            >
              &times;
            </button>
            <div className="solid-border"></div>
          </div>
          <div className="column mt-3 scroll-y pb-20px m-2">
            <div className="d-flex flex-column">
              <div className="row w-100 mb-3 row-gap-10px">
                {suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Special ||
                  suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Normal
                  ? renderFields(suggestionDataFields) 
                  : renderFields(suggestionDataFieldsConditinal)}
              </div>
            </div>
            {
              suggestionSelectedData?.document_details?.filter((e) => e.document_category === 'suggestion_details').length > 0 && (
                <>
                  <div className="mb-10px">
                    <hr />
                  </div>
                  <div className="toggle-card-heading ml-10 mb-10">
                    {"Suggestor Documents"}
                  </div>
                  <CommonPreviewComponent
                    data={suggestionSelectedData.document_details.filter((e) => e.document_category === 'suggestion_details')}
                    download={true}
                    from={'suggestionOffice'}
                  />
                </>
              )}
            <div className="mb-10px">
              <hr />
            </div>
            <div className="toggle-card-heading ml-10 mb-10">
              {"History Details"}
            </div>
            {suggestionSelectedData?.history_details.length > 0 ? (
              <>
                <div className="d-flex flex-column ml-4">
                  <div className="row w-100 mb-3 row-gap-10px justify-content-center">
                    <Table
                      isSrNoShow={true}
                      isActionShow={false}
                      details={suggestionSelectedData}
                      data={suggestionSelectedData.history_details}
                      columns={historyDataFields}
                      multiSelect={false}
                      className={"w-80vw max-height-33vh"}
                      from={'historyTable'}
                    />
                  </div>
                </div>
                <div className="mt-10px mb-10px">
                  <hr />
                </div>
              </>
            ) : (
              <DataNotFound />
            )}
            <div className="toggle-card-heading ml-10 mb-10">
              {"Member Details"}
            </div>
            {suggestionSelectedData?.member_details.length > 0 ? (
              <>
                <div className="d-flex flex-column">
                  <div className="row w-100 mb-3 row-gap-10px justify-content-center">
                    <Table
                      isActionShow={false}
                      isSrNoShow={true}
                      data={suggestionSelectedData.member_details}
                      columns={memberDataFields}
                      multiSelect={false}
                      className={"w-75vw max-height-33vh"}
                      from={'memberTable'}
                      isCertificateEnable = {true}
                      sugDetailsList={suggestionSelectedData?.suggestion_details}

                    />
                  </div>
                </div>
                <div className="mt-10px mb-10px">
                  <hr />
                </div>
              </>
            ) : (
              <DataNotFound />
            )}
            <>
              <div className="toggle-card-heading ml-10 mb-10 required">
                {"Select Action"}
              </div>
              <div className="d-flex flex-column ml-10">
                <div className="row w-100 row-gap-10px">
                  <DynamicDropdown
                    data={filteredActions}
                    render="name"
                    value={selectedAction}
                    selectedValue={(data) => handleActionFilter(data)}
                    arrow={"true"}
                    height={"dependOnContent"}
                    width={"w-16rem"}
                    refs={actionRef}
                  />
                </div>
                {actionError?.id === "1" && (
                  <span className="p-sub-heading mt-2 errorBlock">
                    {actionError.message}
                  </span>
                )}
              </div>
            </>
            <div className="mt-10px mb-10px">
              <hr />
            </div>
            {selectedAction === "Approve" && (
              <>
                <div className="toggle-card-heading ml-10 mb-10 required">
                  {"Award Type Selection"}
                </div>
                <div className="d-flex flex-row ml-10">
                  <div className="row w-20 row-gap-10px">
                    <DynamicDropdown
                      placeholder={"Select Award type"}
                      data={awardList}
                      render="award_name"
                      value={selectedAwardType?.award_name}
                      selectedValue={(data) => handleAwardType(data)}
                      arrow={"true"}
                      height={"NotificationHeight"}
                      width={"w-16rem"}
                      refs={awardRef}
                    />
                  </div>
                  <div className="row w-20 row-gap-10px">
                    <DynamicDropdown
                      placeholder={"Select Award Category"}
                      data={awardCategoryList}
                      render="award_category"
                      value={isMonetary?.award_category}
                      selectedValue={(data) => handleMonetaryOption(data)}
                      arrow={"true"}
                      height={"NotificationHeight"}
                      width={"w-16rem"}
                      refs={finalAwardRef}
                    />
                  </div>
                  {selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') === "ideaaward" &&
                    !(
                      suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode['Special Implemented'] ||
                      suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Implemented
                    ) && (
                      <div className="row align-item-center ml-2 pt-10px pb-10px pl-10px">
                        <span className="bold-font mr-10px">
                          Is Suggestion Implementable:{" "}
                        </span>
                        <input
                          type="radio"
                          id="yes"
                          value="yes"
                          checked={isSuggestionImplementable === "yes"}
                          onChange={handleOption}
                          className="purple-radio mr-10px"
                        />
                        <label
                          className="td-choice-pay mr-15px"
                          htmlFor="yes"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="no"
                          value="no"
                          checked={isSuggestionImplementable === "no"}
                          onChange={handleOption}
                          className="purple-radio mr-10px"
                        />
                        <label className="td-choice-pay mr-15px" htmlFor="no">
                          No
                        </label>
                      </div>
                    )}
                  {actionError?.id === "2" && (
                    <span className="p-sub-heading mt-2 errorBlock">
                      {actionError.message}
                    </span>
                  )}
                </div>
                {selectedAwardType && (
                  <>
                    <div className="mt-10px mb-10px">
                      <hr />
                    </div>
                    {selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') === "finalaward" ? (
                      <div className="row gap-10">
                        <div className="d-flex flex-column ml-10 w-47">
                          <div className="text14_bold required">
                            Implementation details in brief
                          </div>
                          <div className="row w-100 row-gap-10px">
                            <textarea
                              className="form-control esep-placeholder-editable px-3"
                              type="textarea"
                              onChange={handleChange}
                              value={implementationInBrief && implementationInBrief}
                              placeholder={"Type here..."}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column ml-10 w-47">
                          <div className="text14_bold required">
                            Benefits in brief
                          </div>
                          <div className="row w-100 row-gap-10px">
                            <textarea
                              className="form-control esep-placeholder-editable px-3"
                              type="textarea"
                              onChange={handleChangeBenifit}
                              value={benefitsInBrief}
                              placeholder={"Type here..."}
                            />
                          </div>
                        </div>
                        {isMonetary?.award_category === "Monetary" && (
                          <div className="d-flex flex-column ml-10 w-47">
                            <div className="text14_bold required">
                              {"Award Amount in ₹"}
                            </div>
                            <div className="esep-placeholder-editable">
                              <input
                                className="form-control esep-placeholder-editable px-3"
                                type="number"
                                onChange={(e) => handleAwardAmtChange(e)}
                                value={awardAmt}
                                placeholder={"Enter Award Amount... "}
                                onWheel={(e) => e.target.blur()}
                                onKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                min={1}
                                inputMode="decimal"
                                pattern="\d*"
                              />
                            </div>
                            {awardAmt < 100 && awardAmt !== null && awardAmt !== '' && (
                              <div className="p-sub-heading ml-3 errorBlock">
                                Minimum Award value is Rs. 100
                              </div>
                            )}
                            {awardAmt > 25000 && awardAmt !== null && awardAmt !== '' && (
                              <div className="p-sub-heading ml-3 errorBlock">
                                Maximum Award value can be Rs. 25000
                              </div>
                            )}
                          </div>
                        )}
                        {
                          (suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Special ||
                            suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Normal) && (
                            <div className='col-12 col-md-3 mb-3'>
                              <div className='text14_bold required'>Implementation Date</div>
                              <div className='p-placeholder-text'>
                                <input
                                  className="form-control p-placeholder"
                                  type="date"
                                  style={{ height: '38px' }}
                                  max={today}
                                  value={implementationDate}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setImplementationDate(value);
                                  }} />
                              </div>
                            </div>
                          )
                        }
                      </div>
                    ) : (
                      <div className="row gap-10">
                        <div className="d-flex flex-column ml-10 w-47">
                          <div className="text14_bold required">
                            Idea Accepted in brief
                          </div>
                          <div className="row w-100 row-gap-10px">
                            <textarea
                              className="form-control esep-placeholder-editable px-3"
                              type="textarea"
                              onChange={handleIdeaChange}
                              value={ideaImplementation && ideaImplementation}
                              placeholder={"Type here..."}
                            />
                          </div>
                        </div>
                        {isMonetary?.award_category === "Monetary" && (
                          <div className="d-flex flex-column ml-10 w-47">
                            <div className="text14_bold required">
                              {"Award Amount in ₹"}
                            </div>
                            <div className="esep-placeholder-editable">
                              <input
                                className="form-control esep-placeholder-editable px-3"
                                type="text"
                                onChange={(e) => {
                                  const value = e.target.value.replace(/\D/g, '');
                                  value ? setIdeaAwardAmt(e.target.value) : setIdeaAwardAmt('');
                                }}
                                value={ideaAwardAmt}
                                onKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder={"Enter Award Amount... "}
                                onWheel={(e) => e.target.blur()}
                                min={1}
                                inputMode="decimal"
                                pattern="\d*"
                              />
                              {ideaAwardAmt < 100 &&
                                ideaAwardAmt !== "" &&
                                ideaAwardAmt !== null && (
                                  <div className="p-sub-heading ml-3 errorBlock">
                                    Minimum Award value is Rs. 100
                                  </div>
                                )}
                              {ideaAwardAmt > 25000 &&
                                ideaAwardAmt !== "" &&
                                ideaAwardAmt !== null && (
                                  <div className="p-sub-heading ml-3 errorBlock">
                                    Maximum Award value can be Rs. 25000
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
                {selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') === "finalaward" && (
                  <>
                    <div className="mt-10px mb-10px">
                      <hr />
                    </div>
                    <div className="row justify-content-between w-100">
                      <div className="d-flex row justify-content-between w-100">
                        <div className="toggle-card-heading ml-10 mb-10">
                          {"Saving Details (Optional)"}
                        </div>
                        <div
                          div
                          className="pointer row add-btn-text flex-row-reverse p-11px"
                          onClick={() => {
                            setAddSavingDetails(!addSavingDetails);
                          }}
                        >
                          {addSavingDetails
                            ? "- Remove Saving Details"
                            : "+ Add Saving Details"}
                        </div>
                      </div>
                      {addSavingDetails ? (
                        <>
                          <div className="d-flex flex-column w-100">
                            <div className="toggle-card-heading ml-3 mb-10">
                              {"Add Saving Details"}
                            </div>
                            <div className="row w-100 gap-10 gap-7px">
                              <div className="w-12rem ml-3">
                                <DynamicDropdown
                                  data={savingCategoryList}
                                  render="saving_category_name"
                                  placeholder={"Select Saving Category"}
                                  value={
                                    selectedSavingCategory?.saving_category_name
                                  }
                                  selectedValue={(data) => {
                                    setSelectedSavingCategory(data);
                                  }}
                                  arrow={"true"}
                                  height={"dependOnContent"}
                                  width={"w-16rem"}
                                // refs={awardRef}
                                />
                              </div>
                              <div className="ml-3">
                                <DynamicDropdown
                                  data={natureOfSavingList}
                                  render="nature_of_saving_name"
                                  placeholder={"Select Saving Type"}
                                  value={
                                    selectedSavingType?.nature_of_saving_name
                                  }
                                  selectedValue={(data) => {
                                    setSelectedSavingType(data);
                                  }}
                                  arrow={"true"}
                                  height={"dependOnContent"}
                                  width={"w-16rem"}
                                // refs={awardRef}
                                />
                              </div>
                              <div className="w-17rem">
                                <input
                                  className="form-control p-placeholder-evaluator"
                                  type="text"
                                  name="PG_Name"
                                  maxLength={10}
                                  readOnly={false}
                                  onWheel={(e) => e.target.blur()}
                                  min={0}
                                  inputmode="decimal"
                                  pattern="\d*"
                                  placeholder={"Enter Saving Amount in ₹..."}
                                  onChange={(e) => {
                                    setEnteredSavingAmount(e.target.value);
                                  }}
                                  value={enteredSavingAmount}
                                  onKeyPress={(e) => {
                                    if (!/[0-9.]$/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                              <div className="">
                                <input
                                  type="button"
                                  value="ADD"
                                  className={`${isButtonEnabled ? 'validateBtn' : 'validateBtnDisabled'}`}
                                  onClick={onAddSavingDetails}
                                  disabled={!isButtonEnabled}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-column p-20px w-100">
                            <div className="row w-100 mb-3 row-gap-10px justify-content-center">
                              <table className="table gemsAdmin">
                                <thead
                                  className="w-100"
                                  style={{ zIndex: "1" }}
                                >
                                  <tr className="w-100">
                                    <th scope="col">
                                      <div className="thead pr-0 w-35px">
                                        {"Sr No"}
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="thead">
                                        {"Saving Category"}
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="thead">
                                        {"Saving Type"}
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="thead">
                                        {"Entered By"}
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="thead">
                                        {"Entered Date"}
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="thead">
                                        {"Saving Amount"}
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="thead ml-06rem">
                                        {"Actions"}
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="adminScreentbody w-100">
                                  {expenseDetails.length > 0 ? (
                                    expenseDetails.map((listValue, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>
                                            <div className="td w-20px">
                                              {index + 1}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="td d-flex">
                                              {listValue.saving_category_name ?? '--'}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="td d-flex">
                                              {listValue.nature_of_saving_name ?? '--'}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="td d-flex">
                                              {listValue.role ?? '--'}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="td d-flex">
                                              {DDMMYYYY(listValue.created_datetime) ?? '--'}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="td d-flex">
                                              {listValue.eval_amount ?? '--'}
                                            </div>
                                          </td>
                                          <td className="d-flex">
                                            <div
                                              onClick={() =>
                                                handleDelete(index)
                                              }
                                            >
                                              <img
                                                alt="view"
                                                src={delete_action}
                                                className="pointer mx-2"
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="5">
                                        <DataNotFound />
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="d-flex flex-column w-100">
                            <div className="mt-10px mb-10px">
                              <hr />
                            </div>
                            <div className="toggle-card-heading ml-3 mt-10px mb-10">
                              {"Add Saving Documents"}
                            </div>
                            <SuggestionOfficeAttachmentInput
                              attachment={attachments}
                              onChange={(attachment) => {
                                addAttachment(attachment);
                              }}
                              isMandatory={
                                selectedAction === "Approve" ? false : true
                              }
                              // disabled={false}
                              length={5}
                              maxSize={2}
                              from={'divCordinator'}
                              uploadNote={
                                "You can upload attachment up to 2MB size only (pdf/jpeg/png)"
                              }
                              onDelete={(attachment, index) => {
                                deleteAttachment(attachment, index);
                              }}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </>
                )}

                <div className="mt-10px mb-10px">
                  <hr />
                </div>
                <div className="toggle-card-heading required ml-10 mb-10">
                  {` ${(isMonetary?.id === 1 && (selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') === 'finalaward' ? awardAmt >= 1000 : ideaAwardAmt >= 1000)) ? 'Comment to Super Admin' : 'Final Comment by Suggestion Office'} `}
                </div>
                <div className="d-flex flex-column ml-10 w-50">
                  <div className="row w-100 flex-direction-column row-gap-10px">
                    <textarea
                      className="form-control esep-placeholder-editable px-3"
                      type="textarea"
                      onChange={(e) => {
                        setsuggOfficeComments(e.target.value);
                      }}
                      value={suggOfficeComments}
                      placeholder={`${(isMonetary?.id === 1 && awardAmt >= 1000) ? 'Enter Comment to Super Admin' : 'Enter Final Comment by Suggestion Office'} `}
                      maxLength={350}
                    />
                    {/* <div className="justify-content-end time-text">
                        {lengthCalculate(suggOfficeComments)}/350
                      </div> */}
                    {((suggOfficeComments !== null &&
                      suggOfficeComments === "") ||
                      !/[a-zA-Z0-9]/.test(suggOfficeComments) ||
                      /\s{2,}/.test(suggOfficeComments)) && (
                        <div className="text-left p-sub-heading errorBlock ml-2">
                          Comments cannot be blank, can only include alphanumeric
                          characters and cannot includes more than 1 spaces.
                        </div>
                      )}
                  </div>
                </div>
              </>
            )}
            {selectedAction === "Reject" && (
              <>
                <div className="toggle-card-heading ml-10 mb-10 required">
                  {"Reason for Rejection"}
                </div>
                <div className="d-flex flex-column ml-10 w-50">
                  <div className="row w-100 row-gap-10px">
                    <textarea
                      className="form-control required esep-placeholder-editable px-3"
                      type="textarea"
                      onChange={(e) => {
                        setRejectionReason(e.target.value);
                      }}
                      value={rejectionReason}
                      placeholder={"Enter reason for rejection"}
                      maxLength={350}
                    />
                  </div>
                  {/* <div className="justify-content-end time-text">
                        {lengthCalculate(rejectionReason)}/350
                    </div> */}
                  {((rejectionReason !== null && rejectionReason === "") ||
                    !/[a-zA-Z0-9]/.test(rejectionReason) ||
                    /\s{2,}/.test(rejectionReason)) && (
                      <div className="text-left p-sub-heading errorBlock ml-2">
                        Rejection reason cannot be blank, can only include
                        alphanumeric characters and cannot includes more than 1
                        spaces.
                      </div>
                    )}
                  <div className="flex-direction-row mb-2 mt-2">
                    <input
                      className="thead"
                      type="checkbox"
                      onChange={(e) => {
                        setcheckBoxSelect(e.target.checked);
                      }}
                    />
                    <div className="ml-2">
                      {"I agree to proceed with the above action."}
                    </div>
                  </div>
                </div>
              </>
            )}
            {selectedAction === "Send Back" && (
              <>
                <div className="toggle-card-heading ml-10 mb-10 required">
                  {"Remarks for clarification"}
                </div>
                <div className="d-flex flex-column ml-10 w-50">
                  <div className="row w-100 row-gap-10px">
                    <div className="flex-direction-column">
                      <textarea
                        className="form-control required esep-placeholder-editable px-3"
                        type="text"
                        onChange={(e) => {
                          setClarificationRemark(e.target.value);
                        }}
                        value={clarificationRemark}
                        placeholder={"Enter remarks for clarification"}
                        maxLength={350}
                      />
                      {/* <div className="justify-content-end time-text">
                          {lengthCalculate(clarificationRemark)}/350
                        </div> */}
                      {((clarificationRemark !== null &&
                        clarificationRemark === "") ||
                        !/[a-zA-Z0-9]/.test(clarificationRemark) ||
                        /\s{2,}/.test(clarificationRemark)) && (
                          <div className="text-left p-sub-heading errorBlock ml-2 mt-1">
                            Comments cannot be blank, can only include alphanumeric
                            characters and cannot includes more than 1 spaces.
                          </div>
                        )}
                      <div className="flex-direction-row mb-2">
                        <input
                          className="thead"
                          type="checkbox"
                          onChange={(e) => {
                            setcheckBoxSelect(e.target.checked);
                          }}
                        />
                        <div className="ml-2">
                          {"I agree to proceed with the above action."}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="popup-button-background justify-content-end align-item-center pr-3 p-10px">
            {
              selectedAction === "Approve" ? (
                <input
                  type="button"
                  value="APPROVE"
                  className={
                    (selectedAwardType?.award_name != null &&
                      isMonetary?.award_category != null &&
                      ((isMonetary?.award_category === "Monetary" && awardAmt !== null) || isMonetary?.award_category === "Non-Monetary") &&
                      benefitsInBrief != null &&
                      implementationInBrief != null &&
                      ((suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Normal ||
                        suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Special) &&
                        selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') === 'finalaward' ? implementationDate !== null : true) &&
                      suggOfficeComments !== '' &&
                      suggOfficeComments !== null &&
                      (error === "" || error === null)) ||
                      (ideaImplementation !== null && isMonetary?.award_category != null &&
                        ideaImplementation !== "" &&
                        !(
                          suggOfficeComments === "" ||
                          !/[a-zA-Z0-9]/.test(suggOfficeComments) ||
                          suggOfficeComments === null ||
                          /\s{2,}/.test(suggOfficeComments)
                        ) &&
                        !(
                          (ideaAwardAmt < 100 || ideaAwardAmt > 25000) &&
                          ideaAwardAmt !== "" &&
                          ideaAwardAmt !== null
                        ))
                      ? "ml-2 validateBtn"
                      : "ml-2 validateBtnDisabled"
                  }
                  disabled={
                    (selectedAwardType?.award_name != null &&
                      isMonetary?.award_category != null &&
                      ((isMonetary?.award_category === "Monetary" && awardAmt !== null) || isMonetary?.award_category === "Non-Monetary") &&
                      benefitsInBrief != null &&
                      implementationInBrief != null &&
                      // implementationDate!=null &&
                      ((suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Normal ||
                        suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Special) &&
                        selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') === 'finalaward' ? implementationDate !== null : true) &&
                      benefitsInBrief !== "" &&
                      implementationInBrief !== "" &&
                      (suggOfficeComments !== '' ||
                        rejectionReason !== null ||
                        clarificationRemark !== null) &&
                      (error === "" || error === null)) ||
                      (ideaImplementation !== null && isMonetary?.award_category != null &&
                        ideaImplementation !== "" &&
                        !(
                          suggOfficeComments === "" ||
                          !/[a-zA-Z0-9]/.test(suggOfficeComments) ||
                          suggOfficeComments === null ||
                          /\s{2,}/.test(suggOfficeComments)
                        ) &&
                        !(
                          (ideaAwardAmt < 100 || ideaAwardAmt > 25000) &&
                          ideaAwardAmt !== "" &&
                          ideaAwardAmt !== null
                        ))
                      ? false
                      : true
                  }
                  onClick={() => addSuggestion()}
                />
              ) : (
                <input
                  type="button"
                  value={
                    selectedAction === "Reject"
                      ? "REJECT"
                      : selectedAction === "Send Back"
                        ? "SEND BACK"
                        : selectedAction === "Approve"
                          ? "APPROVE"
                          : "CLOSE"
                  }
                  className={
                    selectedAction === null ? 'ml-2 validateBtn' : (!checkBoxSelect || (selectedAction === "Send Back" && clarificationRemark === "")) ? 'ml-2 validateBtnDisabled' :
                      ((!checkBoxSelect ||
                        divisionDetails.division === null ||
                        divisionDetails.division_id === null ||
                        // 198
                        agencyDetails.agency === null ||
                        agencyDetails.agency_id === null) &&
                        suggestionStatus === "Pending at Suggestion Office" &&
                        (!checkBoxSelect ||
                          rejectionReason === "" ||
                          !/[a-zA-Z0-9]/.test(rejectionReason) ||
                          rejectionReason === null ||
                          /\s{2,}/.test(rejectionReason)) &&
                        (!checkBoxSelect ||
                          clarificationRemark === "" ||
                          !/[a-zA-Z0-9]/.test(clarificationRemark) ||
                          clarificationRemark === null ||
                          /\s{2,}/.test(clarificationRemark)) &&
                        (!checkBoxSelect ||
                          suggOfficeComments === "" ||
                          !/[a-zA-Z0-9]/.test(suggOfficeComments) ||
                          suggOfficeComments === null ||
                          /\s{2,}/.test(suggOfficeComments)))
                        ? "ml-2 validateBtnDisabled"
                        : "ml-2 validateBtn"
                  }
                  disabled={
                    selectedAction === null ? false : (!checkBoxSelect || (selectedAction === "Send Back" && clarificationRemark === "")) ? true : ((!checkBoxSelect ||
                      divisionDetails.division === null ||
                      divisionDetails.division_id === null) &&
                      suggestionStatus === "Pending at Suggestion Office" &&
                      (!checkBoxSelect ||
                        rejectionReason === "" ||
                        !/[a-zA-Z0-9]/.test(rejectionReason) ||
                        rejectionReason === null ||
                        /\s{2,}/.test(rejectionReason)) &&
                      (!checkBoxSelect ||
                        clarificationRemark === "" ||
                        !/[a-zA-Z0-9]/.test(clarificationRemark) ||
                        clarificationRemark === null ||
                        /\s{2,}/.test(clarificationRemark)) &&
                      (!checkBoxSelect ||
                        suggOfficeComments === "" ||
                        !/[a-zA-Z0-9]/.test(suggOfficeComments) ||
                        suggOfficeComments === null ||
                        /\s{2,}/.test(suggOfficeComments)))
                  }
                  onClick={() => {
                    selectedAction === null
                      ? backAction()
                      : handleBtnAction();
                  }}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuggestionOfficeDetailPopup